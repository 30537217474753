import React, {useState, useRef, useEffect} from "react";
import * as Yup from "yup";
import {authenticationService} from "../../services/AuthinticationService";
import {useLocation, useNavigate} from "react-router-dom";
import {TextInput} from "../../components/input/TextInput";
import logo from "../../assets/verticaPlus.png"
import {Error} from "../../components/forms/Error";
import ReactCodeInput from "react-code-input";
import SVGraphics from "../../assets/SVGraphics";


export function VerificationCode() {
    const [VerificationCodeInputs, setVerificationCodeInputs] = useState();

    const navigate = useNavigate();
    const [BackendErrors, setBackendErrors] = useState(null);
    const [title, setTitle] = useState(null);
    const { state } = useLocation();

    const inputClassName = "bg-white rounded-lg  h-[3rem] w-[23rem] bg-transparent font-Poppins text-lg  "

    let recaptchaRef = useRef(null)
    useEffect(() => {
      if(state.type==="email"){
          setTitle("email")
      }else{
          setTitle("phone")
      }
    }, [])
    const SubmitFunc = async (reCaptchaToken) => {
        let userID = sessionStorage.getItem("userID")
        let response
        if(state.type==="email") {
            response = await authenticationService.submitforgotPassVerfication(VerificationCodeInputs);
        }
        else  {response = await authenticationService.submit2fa(VerificationCodeInputs);}
        if (response && response.status < 400) {
            let data = response.data
            if (data && data.hasOwnProperty('status') && String(data['status'])=== 'forgetPass') {
                navigate('/ResetPassword')
            }
            else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                if(data.profile.PassNeedsToChange ){
                    navigate('/ResetPassword')
                }else{
                    navigate('/ctManager/clinicalTrials');
                }
            }
        }
        else {
            setBackendErrors('Verification code invalid. Please try again')
        }
    }
    const   onCodeChange  = async(e) => {
        setBackendErrors(null)
        setVerificationCodeInputs(e)
    }
    const   sendAgain  = async(e) => {
        await authenticationService.login(sessionStorage.getItem('Email'),sessionStorage.getItem('Password'));
    }

    const getReactCodeInputProps = (valid) => {
        return  {
            fontFamily: 'monospace', margin:  '4px',
            MozAppearance: 'textfield', width: '50px',
            borderRadius: '10px', fontSize: '20px',
            height: '54px', backgroundColor: '#FFFFFF',
            color: valid ?  'lightskyblue' : 'red', textAlign: 'center',
            border: `1px solid ${valid ? '#D0D5DD' : 'red'}`,
        }
    }

    return (
        <div
            className={"flex-col font-Poppins w-login h-login rounded-3xl flex p-[3rem] mt-[13.781rem] mr-[15.75rem] inset-0"}>
            <SVGraphics className={'flex w-[23rem] h-[3.75rem] self-center'}
                        svgname={'verticaPlus'} fill={'none'}/>

            <div>
                <div className={"mt-[2.813rem]  flex-col flex"}>
                    <TextInput text={'Verification Code'} className={"text-[#000] font-Poppins text-3xl font-bold leading-tight"} />
                    <TextInput text={'Please enter the 6 digits code that we sent through your phone number'}
                               className={"w-[20rem] mt-[2.25rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"} />
                </div>


                <div className={"w-[23rem] h-[1px] bg-[#EFEFEF] mt-[0.47rem]"}/>
                <div className={"mt-[2.25rem]"}>
                    <ReactCodeInput type='number' fields={6}
                                    isValid={!BackendErrors}
                                    className={'code-verification-password'}
                                    inputStyle={getReactCodeInputProps(true)}
                                    inputStyleInvalid={getReactCodeInputProps(false)}
                                    onChange={(e)=>onCodeChange(e)}/>
                </div>

                <div className={"w-full text-left mt-2 text-lg"} >
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>
            </div>


            <div className={'mt-[3.25rem]'} >
                <button type="button"
                        onClick={() => SubmitFunc()}
                        className="rounded-full bg-[#52007E] shadow-md flex w-[23rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white
                            font-Poppins text-lg font-bold leading-7 capitalize">
                    Verify
                </button>
            </div>
            <div className={"mt-[0.75rem] text-[#4C488F] leading-trim text-edge-cap font-Poppins text-base leading-55 underline capitalize "}
                 onClick={()=>navigate('/login')}>
                Back to login</div>

        </div>



    )
}

