import React from "react";
import Files from "react-files";
import SVGraphics from "../../assets/SVGraphics";


const BodyHeader = (props) => {


    return (
        <div className={'flex'}>

            <div className={"h-[2.75rem] text-[#06132B] text-cap font-Poppins text-[2.5rem] leading[3.75rem] font-bold leading-60"}>
                {props.title}
            </div>

            {props.includeTotal &&
                <label className={'pt-[1rem] ml-auto text-black font-Poppins text-lg font-medium leading-7'}>{props.totalText}</label>
            }

            {props.includeButton && !props.readOnly &&
                <div
                onClick={()=>props.OnButtonClick()}
                className={"flex justify-center cursor-pointer ml-auto w-[14rem] h-[2.5rem] py-[0.5rem] px-[1.5rem] text-center text-lg font-bold font-Poppins flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                    "text-[#FFF]"}>
                    {props.includePlus &&
                        <SVGraphics className={'mt-[0.3rem] w-[1rem] h-[1rem] cursor-pointer flex-shrink-0'} fill="#FEFEFE"
                                    svgname={'plusIcon'}/>
                        }
                <label className={"ml-[12px] cursor-pointer text-center text-lg font-bold font-Poppins" +
                    "text-[#FFF]"}>{props.buttonText}</label>
            </div>}
            {
                props.hasDownload &&
                <div className={'flex ml-auto'}>
                    <div className={`ml-auto ${props['hasAdvanceFilter'] ? ' mt-[1rem]' : ''}`}
                         onClick={() => props.onDownloadClick()}>
                        <SVGraphics className={'ml-[0.5rem] w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                    svgname={'downloadSelected'} fill={'none'}/>
                        <label
                            className={"w-[5.563rem] text-[#52007E] leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                            {props.downloadText?props.downloadText:"Download"}</label>
                    </div>
                </div>
            }

            {props.includeUpload &&

                <div className={"ml-auto"}>
                <Files
                    maxFiles={1}
                    maxFileSize={1000000000}
                    minFileSize={0}
                    accepts={['.xlsx']}
                    onChange={(file) => props.OnButtonClick(file)}
                    clickable>
                    <div
                        className={"cursor-pointer ml-auto w-[14rem] h-[2.5rem] py-[0.5rem] px-[1.5rem] text-center text-lg font-bold font-Poppins  gap-3 flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                            "text-[#FFF]"}>
                        {props.buttonText}
                    </div>

                </Files>
                </div>
            }
        </div>
    )
}

export default BodyHeader;
