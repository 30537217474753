import React from "react";
import {TextInput} from "./TextInput";

export function TextAreaComp(props) {
    const borderColor= props.value && props.value!==""?'border-[#00A4BD]':'border-[#E9E9EB]'
    const hasLabel = props.labelComp?props.labelComp:<div>
        <span className={'font-Poppins text-base '}>{props.label}</span> </div>

    const value=props.value?props.value:''
    return (
        <div className={props.containerClassName}>
            {hasLabel}
            <textarea disabled={props.disabled} name={props.name}
                      maxLength={props.maxLength}
                      style={props.style}
                      className={props.inputClassName + ` border border-solid ${borderColor}  pl-[0.75rem] pt-[1.5rem] rounded-lg h-[14.188rem] w-full` }
                      placeholder={props.placeholder} onChange={props.onChange} value={value}/>
            <div
                className={"mr-[0.5rem] mt-[0.563rem] text-[#AAA] text-right leading-trim tracking-caps lining-nums proportional-nums sub font-Poppins font-light text-sm"}
            >{50- value.length}/50
            </div>
        </div>
    )
}
