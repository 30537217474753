import React, {useState, useRef, useEffect} from "react";
import {authenticationService} from "../../services/AuthinticationService";
import formData from "../../components/forms/formsData.json";
import {useNavigate} from "react-router-dom";
import utils from "../../utils/utils";
import {Error} from "../../components/forms/Error";
import {FormBuilder} from "../../components/forms/FormBuilder";
import {TextInput} from "../../components/input/TextInput";
import AlertConfirm from "../../components/modals/Confirm";
import SVGraphics from "../../assets/SVGraphics";
import ReCAPTCHA from "react-google-recaptcha";

export function Login() {
    const [LoginInputs, setLoginInputs] = useState({
        Email: "",
        Password: ""
    });
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formType, setFormType] = useState(formData['login'])
    const [BackendErrors, setBackendErrors] = useState(null);
    const [FormErrors, setFormErrors] = useState({});

    const inputClassName = "bg-white rounded-lg h-[3rem] w-[23rem] bg-transparent font-Poppins text-lg shadow-lg "
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    useEffect(() => {
        // Trigger reCAPTCHA verification when the component mounts
        recaptchaRef.current.execute();
    }, []);

    const SubmitFunc = async () => {
        if (recaptchaValue) {
            let response = await authenticationService.login(LoginInputs.Email, LoginInputs.Password,recaptchaValue);
            sessionStorage.setItem('Email', LoginInputs.Email);
            sessionStorage.setItem('Password', LoginInputs.Password);

            if (response && response.status < 400) {
                let data = response.data
                if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa') {
                    let phoneNum = data.profile.PhoneNumber ? data.profile.PhoneNumber.slice(9) : ""
                    const phoneNumber = "XXX-XXXX" + phoneNum;

                    navigate("/verificationCode", {state: {type: "phone", value: phoneNumber}});
                } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                    let token = data.profile.token
                    let role = data.profile.Role
                    if (data.profile['PassNeedsToChange']) {
                        navigate('/ResetPassword')
                    } else {
                        const route = role ? role : ""
                        if (role === "Call Center")
                            navigate(`/${route}/DeviceManagement`);
                        else
                            navigate(`/${route}/Dashboard`);
                    }
                }
            } else {
                let responseError = response && response.response ? response.response.data.error : ""
                if (responseError === "locked") {
                    let confirmApprove = await AlertConfirm({
                        proceedLabel: 'OK',
                        cancelLabel: 'none',
                        options: {hasSVG: true, svgName: "alert-triangle"},

                    }, "Please contact the the admin to unlock the user.", `Your account has been locked.`)
                }
                setBackendErrors('Wrong Email or Password. Please try again')
            }
        } else {
            console.error('Please complete reCAPTCHA.');
        }
    }

    const handleChange = async (event) => {
        let inputs = {...LoginInputs}
        let name = event.target.name
        let value = event.target.value
        LoginInputs[name] = event.target.value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        switch (name) {
            case 'Email':
                if (value && !utils.mailValidation(value)) {
                    formErrors['Email'] = true
                    setIsError(true)
                    form[0]['error'] = "Please enter valid email address"
                } else if (!value) {
                    form[0]['error'] = "Please enter email address"
                    formErrors['Email'] = true
                    setIsError(true)
                } else {
                    formErrors['Email'] = false
                    form[0]['error'] = ""
                }
                break;
            case 'Password':
                if (!value) {
                    form[1]['error'] = "Please enter password"
                    formErrors['Password'] = true
                    setIsError(true)
                } else {
                    formErrors['Password'] = false
                    form[1]['error'] = ""
                }
                break;
            default:

                setIsError(false)

        }
        inputs[name] = value
        setLoginInputs(inputs)
        setFormType(form)
        setError(formErrors)
        setBackendErrors(null)
    }


    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const recaptchaRef = React.createRef();

    return (

        <div
            className={"flex-col font-Poppins w-login h-login rounded-3xl flex p-[3rem] mt-[13.781rem] mr-[15.75rem] inset-0"}>
            <SVGraphics className={'flex min-w-[23rem] min-h-[3.75rem] self-center'}
                        svgname={'verticaPlus'} fill={'none'}/>

            <div>
                <div className={"mt-[2.813rem] flex-col flex"}>
                    <TextInput text={"Login Account"}
                               className={"text-[#000] font-Poppins text-[26px] font-bold leading-tight"}/>
                    <TextInput text={"Please enter your details"}
                               className={" mt-[2.25rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"}/>
                </div>
                <div className={"w-[23rem] h-[1px] bg-[#EFEFEF] mt-[2.25rem]"}/>
                <div className={"mt-[2.25rem]"}>
                    <FormBuilder
                        data={formType}
                        formData={LoginInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"flex-col flex w-full h-full mt-[1.938rem]"}
                        labelClassName={"text-black leading-trim font-Poppins text-lg font-normal leading-7"}
                        inputClassName={inputClassName}
                        isShown={error}
                        type={showPassword ? 'text' : 'password'}
                        showPassword={showPassword}
                        showOnClick={() => setShowPassword(!showPassword)}
                        isError={isError}
                    />
                </div>

                <div className={"w-full text-left mt-2 text-lg"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>
            </div>

            <div className={'mt-[3.25rem]'}>
                <button type="button"
                        onClick={() => SubmitFunc()}
                        disabled={!LoginInputs['Email'] || !LoginInputs['Password'] || (LoginInputs['Email'] && !utils.mailValidation(LoginInputs['Email']))}
                        className="rounded-full bg-[#52007E] shadow-md flex w-[23rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white
                            font-Poppins text-lg font-bold leading-7 capitalize">
                    Login
                </button>
            </div>

            <div
                className={"w-full font-normal mt-[1.875rem] text-center cursor-pointer text-base font-Poppins text-[#52007E]" +
                    " font-inter text-base font-normal leading-normal underline text-right mr-[2.573rem]"}
                onClick={() => navigate('/forgotPassword')}>
                Forgot password?
            </div>

            <div className={'hidden'}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                    size="invisible"

                />
            </div>


        </div>


    )
}
