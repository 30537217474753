import React from "react";
import MainRouters from "./view/MainRouters";
import './App.css';


function App() {
  return (
      <MainRouters/>
  );
}

export default App;
