import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import formData from "../../../components/forms/formsData.json";
import {FormBuilder} from "../../../components/forms/FormBuilder";
import {FormCard} from "../../../components/forms/FormCard";
import {object, string} from "yup";

import BodyHeader from "../../../components/customComp/BodyHeader";
import BodyFooter from "../../../components/customComp/BodyFooter";


const EditSite = (props) => {
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [error, setError] = useState([{}])
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [isError, setIsError] = useState(false)
    const [currentForm, setCurrentForm] = useState(formData['edit_site'])
    const params = useParams();

    const validateSchema = object().shape({
        Site_name: string().required('This field is required'),
        Language_index: string().required('This field is required'),
        Contact_link: string().required('This field is required'),
        Gel_acquisition_link: string().required('This field is required'),
        Website_link: string().required('This field is required'),
        Store_link: string().required('This field is required'),
    })

    useEffect(() => {
        setIsReadOnly(!!props.readOnly)
        fetchData().then(site => {
            getAllLanguages({}).then(p => {
                currentForm[1]['data'] = p.data.map(item => {
                    return {value: item['Language_index'], label: item['Language']}
                })
                setCurrentForm([...currentForm])
                if(site && site.data){
                    setEntry(site.data)
                }
            })
        })

    }, [])

    const fetchData = async () => {
        let index= params.Site_index
        return await props.restApi.getSiteByIndex(index)
    }

    const getAllLanguages = async () => {
        return await props.restApi.getAllLanguages()
    }



    const validateForm = async (currentEntry) => {
        let value = false
        let formErrors = {}
        await validateSchema.validate(currentEntry, {abortEarly: false})
            .then(function () {
                setIsError(false)
                setError(formErrors)
            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = currentForm.findIndex(x => x.name === error.path)
                    console.log(error)
                    currentForm[findIndex]['error'] = error.errors
                    console.log(error)
                })
                value = true
                setError(formErrors)
                setIsError(value)
            });
        return value
    }


    const onChange = async (name, value, isNew) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        if (name === "Site" && isNew && value) {
            currentForm[1]['data'].push({label:value.value,value:value.value})
            value=value.value
        }else if(name ==="Site" && value){
            value=value.value
        }else if(name ==="Enabled"){
            value= !currentEntry['Enabled']
        }
        currentEntry[name] = value
        setEntry({...currentEntry})
        setCurrentForm([...currentForm])

        if (error[name]) {
            await validateForm(currentEntry)
        }
    }


    const updateForm = async () => {
        let currentEntry = {...entry}
        let validateEntry = await validateForm(currentEntry)
        if (!validateEntry) {
            let response = await props.restApi.updateSite(currentEntry)
            if (response && response.data) {
                navigate(`/${props.rolePath}/Sites`)
            }
        }
    }

    return (
        <div className="w-full px-[3.75rem] py-[3rem]">
            <BodyHeader title={"Edit Site"} includeButton={false}/>

            <FormCard
                cardMargin={''}
                cardTitle={''}
                data={<FormBuilder
                    data={currentForm}
                    marginSecondRow={"mt-[4.125rem]"}
                    marginFirstRow={"mt-[5.125rem]"}
                    formData={entry}
                    disabled={props.readOnly}
                    hasCardTitle={true}
                    onChange={e => onChange(e.target.name, e.target.value, e.target.isNew)}
                    containerClassName={""}
                    labelClassName={"font-Poppins text-inputSize  font-bold"}
                    inputClassName={"mt-[0.5rem] w-[23rem] rounded-lg h-[3rem] font-Poppins "}
                    isShown={error}
                    isError={isError}
                />}
            />

            <BodyFooter cancelText={"Cancel"}
                        createText={"Save"}
                        marginTop={"mt-[8rem]"}
                        isReadOnly={isReadOnly}
                        onCreateClick={() => updateForm()}
                        onCancelClick={() => navigate(`/${props.rolePath}/Sites`)}/>


        </div>
    )
}

export default EditSite;
