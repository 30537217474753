import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import formData from "../../../components/forms/formsData.json";
import {FormBuilder} from "../../../components/forms/FormBuilder";
import {FormCard} from "../../../components/forms/FormCard";
import {array, object, string} from "yup";
import Constants from "../../../utils/constants";
import BodyHeader from "../../../components/customComp/BodyHeader";
import BodyFooter from "../../../components/customComp/BodyFooter";
import AlertConfirm from "../../../components/modals/Confirm";


const AddNewUser = (props) => {
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [error, setError] = useState([{}])
    const [isError, setIsError] = useState(false)
    const [usersForm, setUsersForm] = useState(formData['add_user'])
    const [companies, setCompanies] = useState([]);

    const validateSchema = object().shape({
        First_name: string().required('This field is a required field'),
        Last_name: string().required('This field is a required field'),
        Role: string().required('This field is a required field'),
        Email: string().required('This field is a required field').matches(Constants.emailRegExp, "Invalid Email"),
        Site: array().required('This field is a required field'),
        Company: array().required('This field is a required field')
    })

    useEffect(() => {
        let companies = []
        getAllCompanies({}).then(r => {
            usersForm[5]['data'] = r.data.map(item => {
                companies.push(item)
                return {value: item['Company_index'], label: item['Company_name']}
            })
            setCompanies([...companies])
            setUsersForm([...usersForm])
        })

    }, [])


    const validateForm = async (currentEntry) => {
        let value = false
        let formErrors = {}

        await validateSchema.validate(currentEntry, {abortEarly: false})
            .then(function () {
                setIsError(false)
                setError(formErrors)

            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = usersForm.findIndex(x => x.name === error.path)
                    usersForm[findIndex]['error'] = error.errors
                    console.log(error)

                })
                value = true
                setError(formErrors)
                setIsError(value)
            });
        return value
    }


    const getAllCompanies = async () => {
        return await props.restApi.getAllCompanies()
    }

    const onChange = async (name, value) => {
        let user = {...entry}
        if (value === "") {
            value = null
        }
        if (name === "Company") {
            let selectedCompanies = value
            user["Company"]=user["Company"]?user["Company"]:[]
            let differenceCompanies = user["Company"].filter((item) => !selectedCompanies.includes(item));
            let sitesOptions = []
            selectedCompanies.map(item => {
                let findIndex = companies.findIndex(company => item === company.Company_name)
                if (findIndex >= 0) {
                    let companySites = companies[findIndex]['sites']
                    companySites.map(site => {
                        sitesOptions.push({value: site['Site_index'], label: site['Site_name']})
                    })
                }
            })

            if(differenceCompanies.length>0){
                let selectedSites = user["Site"]
                let newSelectedSitesArr = []
                for (const company of differenceCompanies) {
                    let findIndex = companies.findIndex(item => company === item.Company_name)
                    if(findIndex>=0){
                        let companySites = companies[findIndex]['sites']
                        for (const selectedSite of selectedSites) {
                            let findSiteIndex = companySites.findIndex(companySite => companySite['Site_name'] === selectedSite)
                            if(findSiteIndex<0){
                                newSelectedSitesArr.push(selectedSite)
                            }
                        }
                    }
                }
                user["Site"] = newSelectedSitesArr
            }

            usersForm[6]['data'] = sitesOptions
            setUsersForm([...usersForm])
        }

        user[name] = value
        setEntry({...user})

        if (error[name]) {
            await validateForm(user)
        }
    }


    const createForm = async () => {
        let currentEntry = {...entry}
        let validateEntry = await validateForm(currentEntry)
        let chosenSites = currentEntry['Site']
        let selectedSites = []
        let selectedCompanies = currentEntry['Company']?currentEntry['Company'].map(item => {
            let findIndex = companies.findIndex(company => company['Company_name'] === item)
            if (findIndex >= 0) {
                let sites = companies[findIndex]['sites']
                sites.map(site => {
                    if (chosenSites.indexOf(site.Site_name) >= 0) {
                        selectedSites.push(site['Site_index'])
                    }
                })
                return companies[findIndex]['Company_index']
            }
        }):[]


        if (!validateEntry && selectedCompanies.length > 0 && selectedSites.length > 0) {
            currentEntry['selectedCompanies']=selectedCompanies
            currentEntry['selectedSites']=selectedSites
            let response = await props.restApi.addUser(currentEntry)
            if (response && response.data) {
                navigate(`/${props.rolePath}/AdminManagement`)
            }
        } else {
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Please fill all required fields", ``)
        }
    }

    return (
        <div className="w-full px-[3.75rem] py-[3rem]">
            <BodyHeader title={"New Admin"} includeButton={false}/>

            <FormCard
                cardMargin={''}
                cardTitle={''}
                data={<FormBuilder
                    data={usersForm}
                    marginSecondRow={"mt-[4.125rem]"}
                    marginFirstRow={"mt-[4.125rem]"}
                    formData={entry}
                    hasCardTitle={true}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    containerClassName={"mr-[0.75rem] "}
                    labelClassName={"font-Poppins text-inputSize  font-bold"}
                    inputClassName={"w-[23rem] rounded-lg h-[3rem] font-Poppins "}
                    isShown={error}
                    isError={isError}
                />}
            />


            <BodyFooter cancelText={"Cancel"}
                        createText={"Create"}
                        marginTop={"mt-[12rem]"}
                        showAlert={true}
                        onCreateClick={() => createForm()}
                        onCancelClick={() => navigate(`/${props.rolePath}/AdminManagement`)}/>


        </div>
    )
}

export default AddNewUser;
