import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const getCurrentUser=()=>{
    return authenticationService.currentUserValue;
}

const CallCenterApi = {


    getAllDevicesTrack: async (filtersArr) => {
        return await restApi.post(
            `callCenter/${getCurrentUser().profile['UserID']}/devicesTrack/getAll`,
            filtersArr
        );
    },

    getDevicesTrackByIndex: async (index) => {
        return await restApi.get(
            `callCenter/${getCurrentUser().profile['UserID']}/devicesTrack/${index}`
        );
    },


    exportAllDevicesDashboardData: async (devicesArr) => {
        return await restApi.post(
            `callCenter/${getCurrentUser().profile['UserID']}/devicesTrack/exportAll`,
            devicesArr
        );
    },

    getDeviceQuestionnaires: async (device_index) => {
        return await restApi.get(
            `callCenter/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/getDeviceQuestionnaires`
        );
    },

    setPatientPhone: async (device_index,patientPhone) => {
        return await restApi.post(
            `callCenter/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/setPatientPhone`,
            patientPhone
        );
    },

    getAllCompanies: async (filtersArr) => {
        return await restApi.post(
            `callCenter/${getCurrentUser().profile['UserID']}/companies/getAll`,
            filtersArr
        );
    },

    getAllSites: async () => {
        return await restApi.get(
            `callCenter/${getCurrentUser().profile['UserID']}/sites/getAll`
        );
    },


}
export default CallCenterApi