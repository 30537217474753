import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import formData from "../../../components/forms/formsData.json";
import {FormBuilder} from "../../../components/forms/FormBuilder";
import {FormCard} from "../../../components/forms/FormCard";
import {array, object, string} from "yup";
import BodyHeader from "../../../components/customComp/BodyHeader";
import BodyFooter from "../../../components/customComp/BodyFooter";
import AlertConfirm from "../../../components/modals/Confirm";


const AddNewCompany = (props) => {
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [error, setError] = useState([{}])
    const [isError, setIsError] = useState(false)
    const [currentForm, setCurrentForm] = useState(formData['add_company'])
    const [sitesOptions, setSitesOptions] = useState([])

    const validateSchema = object().shape({
        Company_name: string().required('This field is required'),
        Site: array().required('This field is a required field'),
    })

    useEffect(() => {
        getAllSites({}).then(p => {
            currentForm[1]['data'] = p.data.map(item => {
                return {value: item['Site_index'], label: item['Site_name']}
            })
            setSitesOptions([...currentForm[1]['data']])
            setCurrentForm([...currentForm])
        })

    }, [])


    const getAllSites = async () => {
        return await props.restApi.getAllPossibleSites()
    }

    const validateForm = async (currentEntry) => {
        let value = false
        let formErrors = {}
        await validateSchema.validate(currentEntry, {abortEarly: false})
            .then(function (value) {
                setIsError(false)
                setError(formErrors)
            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = currentForm.findIndex(x => x.name === error.path)
                    console.log(error)
                    currentForm[findIndex]['error'] = error.errors
                    console.log(error)
                })
                value = true
                setError(formErrors)
                setIsError(value)
            });
        return value
    }


    const onChange = async (name, value) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        currentEntry[name] = value
        setEntry({...currentEntry})
        setCurrentForm([...currentForm])

        if (error[name]) {
            await validateForm(currentEntry)
        }
    }


    const createForm = async () => {
        let currentEntry = {...entry}
        let validateEntry = await validateForm(currentEntry)
        let selectedSites = currentEntry['Site'] ?currentEntry['Site'].map(item => {
            let findIndex = sitesOptions.findIndex(site => site.label === item)
            if (findIndex >= 0) {
                return sitesOptions[findIndex].value
            }
        }):[]

        if (!validateEntry && selectedSites.length>0) {
            currentEntry['selectedSites']=selectedSites
            let response = await props.restApi.addCompany(currentEntry)
            if (response && response.data) {
                navigate(`/${props.rolePath}/Companies`)
            }
        }else{
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Please fill all required fields", ``)
        }
    }

    return (
        <div className="w-full px-[3.75rem] py-[3rem]">
            <BodyHeader title={"New Company"} includeButton={false}/>

            <FormCard
                cardMargin={''}
                cardTitle={''}
                data={<FormBuilder
                    data={currentForm}
                    marginSecondRow={"mt-[4.125rem]"}
                    marginFirstRow={"mt-[5.125rem]"}
                    formData={entry}
                    hasCardTitle={true}
                    onChange={e => onChange(e.target.name, e.target.value, e.target.isNew)}
                    containerClassName={""}
                    labelClassName={"font-Poppins text-inputSize  font-bold"}
                    inputClassName={"mt-[0.5rem] w-[23rem] rounded-lg h-[3rem] font-Poppins "}
                    isShown={error}
                    isError={isError}
                />}
            />

            <BodyFooter cancelText={"Cancel"}
                        createText={"Create"}
                        marginTop={"mt-[24rem]"}
                        showAlert={true}
                        onCreateClick={() => createForm()}
                        onCancelClick={() => navigate(`/${props.rolePath}/Companies`)}/>


        </div>
    )
}

export default AddNewCompany;
