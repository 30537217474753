import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function MultiSelect(props) {
    const options = props.data ? props.data : [];
    let value = props.value ? props.value : [];

    // const borderColor = value.length > 0 ? "1px solid #E9E9EB" : "1px solid #E9E9EB";

    // Function to render the placeholder or selected values
    const renderValue = (selected) => {
        if (selected.length === 0) {
            return <span style={{ color: '#AAA' }}>{props.placeholder}</span>;
        }
        return selected.join(', ');
    };

    return (
        <div className={props.containerClassName} key={props.name}>
            {props.labelComp}
            <Select
                className={props.inputClassName}
                multiple
                value={value}
                displayEmpty // Add this to ensure the renderValue is used even when value is empty
                disabled={props.disabled}
                onChange={props.onChange}
                renderValue={renderValue}
                MenuProps={MenuProps}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        backgroundColor: props.bgColor,
                        width: "100%",
                        color: "#E9E9EB",
                        overflow: 'auto',
                        height: '48px',
                        maxHeight:'48px',
                        textTransform: "capitalize",
                        boxShadow: 'unset',
                        fontSize: "16px",
                        outline: "unset",
                        paddingLeft: '1rem',
                        borderRadius: "0.5rem",
                    })
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.label} value={option.label}>
                        <Checkbox checked={value.indexOf(option.label) > -1}/>
                        <ListItemText primary={option.label}/>
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
