import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import formData from "../../../components/forms/formsData.json";
import {FormBuilder} from "../../../components/forms/FormBuilder";
import {FormCard} from "../../../components/forms/FormCard";
import {array, object, string} from "yup";

import BodyHeader from "../../../components/customComp/BodyHeader";
import BodyFooter from "../../../components/customComp/BodyFooter";
import AlertConfirm from "../../../components/modals/Confirm";


const EditCompany = (props) => {
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [error, setError] = useState([{}])
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [isError, setIsError] = useState(false)
    const [currentForm, setCurrentForm] = useState(formData['edit_company'])
    const [sitesOptions, setSitesOptions] = useState([])

    const params = useParams();

    const validateSchema = object().shape({
        Company_name: string().required('This field is required'),
        Site: array().required('This field is a required field'),

    })

    useEffect(() => {
        setIsReadOnly(!!props.readOnly)
        fetchData().then(company => {
            company = company && company.data?company.data:{}
            getAllSites({}).then(p => {
                let selectedSites = []
                currentForm[1]['data'] = company['sites'].map(item => {
                    selectedSites.push(item['Site_name'])
                    return {value: item['Site_index'], label: item['Site_name']}
                })
                p.data.map(item => {
                    currentForm[1]['data'].push({value: item['Site_index'], label: item['Site_name']})
                })
                company["Site"] = selectedSites
                setSitesOptions([...currentForm[1]['data']])
                setCurrentForm([...currentForm])
                setEntry(company)
            })
        })

    }, [])

    const fetchData = async () => {
        let index = params.Company_index
        return await props.restApi.getCompanyByIndex(index)
    }

    const getAllSites = async () => {
        return await props.restApi.getAllPossibleSites()
    }

    const validateForm = async (currentEntry) => {
        let value = false
        let formErrors = {}
        await validateSchema.validate(currentEntry, {abortEarly: false})
            .then(function () {
                setIsError(false)
                setError(formErrors)
            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = currentForm.findIndex(x => x.name === error.path)
                    console.log(error)
                    currentForm[findIndex]['error'] = error.errors
                    console.log(error)
                })
                value = true
                setError(formErrors)
                setIsError(value)
            });
        return value
    }


    const onChange = async (name, value) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        if (name === "Enabled") {
            value = !currentEntry['Enabled']
        }
        currentEntry[name] = value
        setEntry({...currentEntry})
        setCurrentForm([...currentForm])

        if (error[name]) {
            await validateForm(currentEntry)
        }
    }


    const updateForm = async () => {
        let currentEntry = {...entry}
        let selectedSites = currentEntry['Site']?currentEntry['Site'].map(item => {
            let findIndex = sitesOptions.findIndex(site => site.label === item)
            if (findIndex >= 0) {
                return sitesOptions[findIndex].value
            }
        }):[]

        let validateEntry = await validateForm(currentEntry)
        if (!validateEntry && selectedSites.length>0) {
            currentEntry['selectedSites']=selectedSites
            let response = await props.restApi.updateCompany(currentEntry)
            if (response && response.data) {
                navigate(`/${props.rolePath}/Companies`)
            }
        }else{
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Please fill all required fields", ``)
        }
    }

    return (
        <div className="w-full px-[3.75rem] py-[3rem]">
            <BodyHeader title={"Edit Company"} includeButton={false}/>

            <FormCard
                cardMargin={''}
                cardTitle={''}
                data={<FormBuilder
                    data={currentForm}
                    marginSecondRow={"mt-[4.125rem]"}
                    marginFirstRow={"mt-[5.125rem]"}
                    formData={entry}
                    disabled={isReadOnly}
                    hasCardTitle={true}
                    onChange={e => onChange(e.target.name, e.target.value, e.target.isNew)}
                    containerClassName={""}
                    labelClassName={"font-Poppins text-inputSize  font-bold"}
                    inputClassName={"mt-[0.5rem] w-[23rem] rounded-lg h-[3rem] font-Poppins "}
                    isShown={error}
                    isError={isError}
                />}
            />

            <BodyFooter cancelText={"Cancel"}
                        createText={"Save"}
                        marginTop={"mt-[18rem]"}
                        isReadOnly={isReadOnly}
                        onCreateClick={() => updateForm()}
                        onCancelClick={() => navigate(`/${props.rolePath}/Companies`)}/>


        </div>
    )
}

export default EditCompany;
