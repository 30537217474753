import React from "react";
import Select from 'react-select';

export function CountrySelectInput(props) {
    const borderColor= props.value ?"1px solid #00A4BD":"1px solid #E9E9EB"
    let selectedCountries= props.value.split(',')
    let value =selectedCountries.map(item=>{
      return  props.data?.find(option => option.value === item)
    })

    return (
        <div className={props.containerClassName}>
                {props.labelComp}

                <Select
                    className={props.inputClassName}
                    classNamePrefix="select"
                    isSearchable={true}
                    onChange={props.onChange}
                    value={value}
                    isDisabled={props.disabled}
                    name="color"
                    isMulti
                    options={props.data}
                    styles={{
                            control: (base, state) => ({
                                    ...base,
                                border: borderColor,
                                backgroundColor: props.bgColor,
                                width: "100%",
                                color: "#E9E9EB",
                                overflow: 'auto',
                                height: '41px',
                                textTransform: "capitalize",
                                boxShadow: 'unset',
                                fontSize: "16px",
                                outline: "unset",
                                paddingLeft:'1rem',
                                borderRadius:"0.5rem",
                            })
                    }}
                />
        </div>

    )
}
