import React, {useEffect, useState} from "react";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import DashboardBody from "./dashboard/DashboardBody";
import FiltersSideBar from "../../components/sideBar/FiltersSideBar";
import constants from "../../utils/constants";
import utils from "../../utils/utils";


const DashboardComp = (props) => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [filtersObj, setFiltersObj] = useState({});

    const openSideBar = () => {
        document.body.style.overflow = "hidden"
        setIsSideBarOpen(true)
    }

    const closeSideBar = () => {
        document.body.style.overflow = "auto"
        setIsSideBarOpen(false)
    }

    const [filterInputs, setFilterInputs] = useState([
        {key: "Date", name: "Date", placeholder: "Date", type: "Date"},
        {key: "Company", name: "Company", placeholder: "Company", type: "Select", data: []},
        {key: "Site", name: "Site", placeholder: "Site", type: "Select", data: []}
     /*   {key: "FirstTreatmentDiff", name: "FirstTreatmentDiff", placeholder: "Time from first treatment", type: "Select",
            data: constants.TimeFromFirstTreatment}*/
    ]);


    const filtersCategory=[
        {label:"Details",

            inputs: [{
                name: "Age", label: "Age", data: constants.AgeFilterData},
                {name: "BaselineIIEF",label:"Baseline IIEF-EF",data:constants.BaseLineFilterData}
                ,{name: "EnergyMode",label:"Energy mode",data:constants.EnergyMode}]
        },
        {label:"RING",
            inputs:[{name: "Ring-NumberOfTreatments",label:"Number of treatments",data:constants.NumberOfTreatmentsFilterData}
                ,{name: "Ring-TreatmentTimeAvg",label:"Time (min)",data:constants.SessionsTimeFilterData},
                {name: "Ring-QualityFactor",label:"Quality factor %",data:constants.QualityFilterData},
                {name: "Ring-EnergyLevel",label:"Energy level",data:constants.EnergyLevelFilterData},
                {name: "Ring-Temperature",label:"Temperature (°C)",data:constants.TemperatureFilterData}]
        },
        {label:"RING+PAD",
            inputs:[{name: "RingPad-NumberOfTreatments",label:"Number of treatments",data:constants.NumberOfTreatmentsFilterData},
                {name: "RingPad-TreatmentTimeAvg",label:"Time (min)",data:constants.SessionsTimeFilterData},
                {name: "RingPad-QualityFactor",label:"Quality factor %",data:constants.QualityFilterData},
                {name: "RingPad-EnergyLevel",label:"Energy level",data:constants.EnergyLevelFilterData},
                {name: "RingPad-Temperature",label:"Temperature (°C)",data:constants.TemperatureFilterData}]
        }]

    useEffect(() => {
        let companiesArr =[]
        let sitesArr = []

        getAllDashboardData({}).then(p => {
            getAllCompanies().then(r => {
                let data = r.data
                data.map(company => {
                    let sites = company['sites']
                    sites.map(site=>
                        sitesArr.push({value: site.Site_ID, label: site.Site_name})
                    )
                    companiesArr.push({value: company.Company_ID, label: company.Company_name})
                })
                filterInputs[1].data = companiesArr
                filterInputs[2].data = sitesArr
                setFilterInputs(filterInputs)
                setData(p)
            })

            setData(p)

        })

    }, [])

    const getAllCompanies = async () => {
        return await props.restApi.getAllCompanies({})
    }

    const getAllDashboardData = async (filtersObj) => {
        return await props.restApi.getAllDashboardData(filtersObj)
    }

    const setData = (p) => {
        if(p && p.data){
            setDashboardData({...p.data})
        }
    }


    const onFilterChange = async (name, value) => {
        let filters = {...filtersObj}
        filters[name] = value

        if(((name ==="Site" || name ==="Company") && value.indexOf("All")>=0 )||value.length===0){
            delete filters[name]
        }
        setFiltersObj({...filters})
        handleApplyFilters(filters)
    }

    const handleApplyFilters=(filters)=>{
        console.log("filters",filters)
        if(filters['Date']){
      /*      if(filters['Date'][0]){
                filters['Date'][0] = new Date(filters['Date'][0])
                filters['Date'][0].setHours(12,0,0,0)
            } if(filters['Date'][1]){
                filters['Date'][1] = new Date(filters['Date'][1])
                filters['Date'][1].setHours(12,0,0,0)
            }*/
        }
        getAllDashboardData(filters).then(p => {
            setData(p)
            closeSideBar()
        })
    }

    const onAdvanceFilterChange = async (name, value) => {
        let filters = {...filtersObj}
        filters[name] = value
        if(((name ==="Site" || name ==="Company") && value.indexOf("All")>=0 )||value.length===0){
            delete filters[name]
        }
        setFiltersObj({...filters})
    }

    const clearFilters = async () => {
        let filters = {}
        setFiltersObj({...filters})
        handleApplyFilters({})
    }

    const onDownloadClick = () => {
        let headers = ["Devices","Unlock Devices", "Average age", "BaseLine IIEF-EF", "Actual IIEF-EF", "Errors",
        "Date","Company","Site","Time From First Treatment","Age","Baseline IIEF-EF","Energy mode",
            "Ring-Number of treatments","Ring-Time","Ring-Quality factor","Ring-Energy level","Ring-Temperature",
            "RingPad-Number of treatments","RingPad-Time","RingPad-Quality factor","RingPad-Energy level","RingPad-Temperature"
        ]

        let dataToAdd = [[
            dashboardData['TotalDevices'],
            dashboardData['UnlockDevices'],
            utils.getFloatNumber(utils.getAgeFromBirthYear(dashboardData["AverageAge"])),
            utils.getFloatNumber(dashboardData['AverageBaseLineIIEF']),
            utils.getFloatNumber(dashboardData['AverageActualLineIIEF']),
            dashboardData['TotalDevicesErrors'],
            filtersObj["Date"],
            filtersObj["Company"]?.join(','),
            filtersObj["Site"]?.join(','),
            filtersObj["FirstTreatmentDiff"]?.join(','),
            filtersObj["Age"]?.join(','),
            filtersObj["BaselineIIEF"]?.join(','),
            filtersObj["EnergyMode"]?.join(','),
            filtersObj["Ring-NumberOfTreatments"]?.join(","),
            filtersObj["Ring-TreatmentTimeAvg"]?.join(","),
            filtersObj["Ring-QualityFactor"]?.join(","),
            filtersObj["Ring-EnergyLevel"]?.join(","),
            filtersObj["Ring-Temperature"]?.join(","),
            filtersObj["RingPad-NumberOfTreatments"]?.join(","),
            filtersObj["RingPad-TreatmentTimeAvg"]?.join(","),
            filtersObj["RingPad-QualityFactor"]?.join(","),
            filtersObj["RingPad-EnergyLevel"]?.join(","),
            filtersObj["RingPad-Temperature"]?.join(",")
        ]]
        utils.generateReport("ohhmed_dashboard_table", headers, dataToAdd)
    }

    return (
        <div className={"w-full px-[4.438rem] py-[3.625rem]"}>
            <BodyHeader title={"Dashboard"} includeButton={false}/>

            <div
                className=" h-fit font-Poppins rounded-lg ">

                <BodySearchFilter
                                  onFilterChange={(name, value) => onFilterChange(name, value)}
                                  onDownloadClick={() => onDownloadClick()}
                                  filtersObj={filtersObj}
                                  hasSearch={false}
                                  hasNewLine={true}
                                  hasFilter={true}
                                  hasAdvanceFilter={true}
                                  clearFilters={() => clearFilters()}
                                  hasClear={true}
                                  onAdvanceFilterClick={openSideBar}
                                  hasDownload={true}
                                  filterInputs={filterInputs}
                />

                <DashboardBody dashboardData={dashboardData}/>


                {isSideBarOpen && <FiltersSideBar
                    restApi={props.restApi}
                    filtersObj={filtersObj}
                    onFilterChange={(name, value) => onAdvanceFilterChange(name, value)}
                    onApplyClick={() => handleApplyFilters(filtersObj)}
                    sideBarWidth={'w-[61rem]'}
                    gridStyle ={' grid grid-cols-2 gap-x-[6rem] gap-y-[1.5rem]'}
                    filtersCategory={filtersCategory}
                    setIsLoading ={()=>setIsLoading(!isLoading)}
                    closeSideBar={() => closeSideBar()}
                    clearFilters={() => clearFilters()}
                    isSideBarOpen={isSideBarOpen}
                />}

            </div>

        </div>
    )
}

export default DashboardComp;
