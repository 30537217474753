import axios from 'axios'
import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const globalUrl = process.env.REACT_APP_API_ENDPOINT

const LoginAPICalls = {
    signIn: async (email, password,recaptchaValue) => {

        return await restApi.post(
            `auth/signin`,
            {
                "Email": email,
                "Password": password,
                "recaptchaValue": recaptchaValue,
            }
        );

    },

    getVersion: async () => {
        return await restApi.get(
            `auth/getVersion`
        );
    },

    changeNewPassword: async (password) => {
        return await restApi.post(
            `auth/changePass`,
            {newPassword: password.newPassword.trim(), confirmNewPassword: password.confirmNewPassword.trim(),}
        );
    },


    forgetPass: async (Email,recaptchaValue) => {
        return await restApi.post(
            `auth/forgetPass`,
            {Email: Email.Email, recaptchaValue: recaptchaValue
            }
        );
    },




    submitForgotPasswordCode: async (code) => {
        let requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentfpUserValue.token
            }
        };
        try {
            return await axios.post(
                `${globalUrl}/api/v1/webapp/auth/forgetPass/verify`,
                {code: code},
                requestConfig
            );
        } catch (err) {

            return err
        }
    },

    submit2fa: async (code) => {

        let requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentMfaUserValue.token,
            }
        };
        try {
            return await axios.post(
                `${globalUrl}/api/v1/webapp/auth/2fa`,
                {TwoFaCode: code},
                requestConfig
            );
        } catch (err) {
            return err
        }

    },




};

export default LoginAPICalls
