import React from "react";
import SVGraphics from "../../assets/SVGraphics";

export function Error(props) {

    const hasText = props.message && props.message!==""

    const ErrorComp=hasText?  <div className={'flex mt-[0.5rem] items-center'}>
        <SVGraphics className={'h-[1rem] w-[1rem] mr-2.5 self-start'} svgname={'wrongInput'}
                    style={{display: props.isShown ? 'block' : 'none'}}/>
        <label className={"text-[#F93232] leading-trim-both text-edge-cap font-Poppins text-sm font-light leading-5"}
               style={{display: props.isShown ? 'block' : 'none'}}>{props.message}</label>
    </div>
        :
        <div></div>

    return (
        <>
            {ErrorComp}
        </>

    )
}
