import SVGraphics from "../../assets/SVGraphics";
import React from "react";

const SearchInput=require('./SearchInput')


export function SearchInputButtonComponent(props) {


    return(
        <div className={'flex  w-tableWidth mt-16'}>
            <div  className={'flex'}>

                <input type="search" id="default-search"
                       onChange={props.onChange}
                       className={`rounded-l-md w-searchWidth  p-4 pl-10 text-sm text-gray-900  bg-white d outline-0  ${props.inputClass}`}
                       placeholder="Search" required/>
                <div className={'bg-verticaButton flex items-center rounded-r-md'}>
                    <SVGraphics className="mr-3 h-[1rem] w-[1rem] ml-4" svgname={'glass'} />
                </div>
            </div>
            <div >
                <button type="button"
                        className="font-Poppins w-loginButton text-white ml-2 bg-verticaButton hover:bg-verticaButtonHover font-Poppins font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center" >
                    {props.buttonText}
                </button>
            </div>
        </div>

    )
}
