import React, {useEffect, useState} from "react";
import SVGraphics from "../../assets/SVGraphics";
import {authenticationService} from "../../services/AuthinticationService";
import {SearchInput} from "../input/SearchInput";
import {SearchInputButtonComponent} from "../input/SearchInputButtonComponent";
import {SelectInput} from "../input/SelectInput";
import {MultiSelect} from "../input/MultiSelect";
import {DateTimeInput} from "../input/DateTimeInput";


const BodySearchFilter = (props) => {
    useEffect(() => {
    }, [])


    const currentUser = authenticationService.currentUserValue;

    const getInput = (input) => {

        if (input.type === "Select") {

            return <MultiSelect
                key={input.name}
                name={input.name}
                containerClassName={" w-[23rem]"}
                inputClassName={"w-[23rem] h-[3rem] max-w-[23rem] flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] bg-[#FEFEFE] "}
                onChange={(event) => props.onFilterChange(input.name, event.target.value)}
                data={input.data}
                maxHeight={"3rem"}
                value={props.filtersObj[input.name]}
                placeholder={input.placeholder}
                // selected={props.filtersObj[input.name]}
            />
        /*    <SelectInput
                key={input.name}
                name={input.name}
                containerClassName={" w-[23rem]"}
                inputClassName={"w-[23rem] h-[3rem] max-w-[23rem] flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] border border-[#E9E9EB] bg-[#FEFEFE] "}
                onChange={(event) => props.onFilterChange(input.name, event.value)}
                data={input.data}
                maxHeight={"3rem"}
                value={getSelectedValue(input.name)}
                placeholder={input.placeholder}
                selected={getSelectedValue(input.name)}
            />*/
        } else if (input.type === "Button") {
            return <div className={"w-[23rem]"} key={input.name}>
                <div
                    onClick={input.onClick}
                    className={'cursor-pointer flex w-[14rem] h-[3rem] p-[0.75rem] px-[1.5rem] justify-center items-center gap-[1rem] flex-shrink-0 rounded-full border border-[#52007E] bg-white'}>
                    <SVGraphics className={"w-[1rem] h-[1rem]"} svgname={'filter'}/>
                    <div className={'leading-trim-cap text-[#52007E] font-Poppins font-bold text-lg'}>
                        {input.name}
                    </div>
                </div>
            </div>
        } else if (input.type === "Date") {
            return <DateTimeInput
                containerClassName={" w-[23rem]"}
                inputClassName={"cursor-pointer w-[23rem] h-[3rem] max-w-[23rem] flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] bg-[#FEFEFE] "}
                key={input.name}
                name={input.name}
                hasRange={true}
                selected={props.filtersObj && props.filtersObj[input.name]?props.filtersObj[input.name]:[]}
                placeholder={input.placeholder}
                onChange={(event) => props.onFilterChange(input.name, event)}
                data={input.data}
            />
            
        }
    }

    const getSelectedValue = (name) => {
        let filter = props.filtersObj
        return filter[name]
    }

    const getFilterCom = () => {
        let inputsComp = []
        if (props.hasSearch) {
            inputsComp.push(<SearchInput
                key={'Search'}
                onSearchChange={(searchText) => props.onSearchChange(searchText)}>
                Search
            </SearchInput>)
        }
        if (props.hasFilter) {
            props.filterInputs.map((input) => {
                inputsComp.push(getInput(input))
            })
        }
        if(props.hasClear){
            inputsComp.push(
                <div
                    className={"mt-[0.75rem] mr-[2.75rem] cursor-pointer text-left leading-trim tracking-tight text-capitalize font-Poppins text-[#52007E] text-opacity-100 text-lg font-normal underline"}
                    onClick={() => props.clearFilters()}
                >
                    Clear All Filters
                </div>
            )
        }

        let IsGridClass = props.filterInputs && props.filterInputs.length>0
            ?'w-[75.5rem] grid grid-cols-3 gap-x-[3.25rem] gap-y-[1.5rem]':''


        return <div className={IsGridClass} key={"filterInputs"}>
            {inputsComp}
        </div>
    }


    return (
        <div className={`${props.customMargin?props.customMargin:"mt-[3.625rem]"}`}>

            <div className={"flex"}>

                {props.hasBackButton &&  <div
                    onClick={props.onBackButtonClick}
                    className={'cursor-pointer flex w-[14rem] h-[3rem] p-[0.75rem] px-[1.5rem] justify-center items-center gap-[1rem] flex-shrink-0 rounded-full bg-white shadow-lg'}>
                    <SVGraphics className={"w-[1rem] h-[1rem]"} svgname={'backArrow'}/>
                    <div className={'leading-trim-cap text-[#52007E] font-Poppins font-bold text-lg'}>
                        Back
                    </div>
                </div>
                }
                {getFilterCom()}

                <div className={`ml-[3.25rem] w-[18rem]`}>
                    {
                        props['hasAdvanceFilter'] &&
                        <div
                            onClick={props.onAdvanceFilterClick}
                            className={'cursor-pointer flex w-[14rem] h-[3rem] p-[0.75rem] px-[1.5rem] justify-center items-center gap-[1rem] flex-shrink-0 rounded-full border border-[#52007E] bg-white'}>
                            <SVGraphics className={"w-[1rem] h-[1rem]"} svgname={'filter'}/>
                            <div className={'leading-trim-cap text-[#52007E] font-Poppins font-bold text-lg'}>
                                Filter
                            </div>
                        </div>
                    }
                    {props.hasFilter && props.filterInputs.length>0&& <div className={"flex"}>
                        {props.hasClearInRow &&
                            <div
                                className={"mt-[0.75rem] cursor-pointer text-left leading-trim tracking-tight text-capitalize font-Poppins text-[#52007E] text-opacity-100 text-lg font-normal underline"}
                                onClick={() => props.clearFilters()}
                            >
                                Clear All Filters
                            </div>
                        }
                        {
                            props.hasDownload &&
                            <>
                                <div className={`${props['hasAdvanceFilter'] ? 'ml-[8rem] mt-[1rem]' : ''}`}
                                     onClick={() => props.onDownloadClick()}>
                                    <SVGraphics className={'ml-auto mr-auto w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                                svgname={'downloadSelected'} fill={'none'}/>
                                    <div
                                        className={"w-[7rem] text-[#52007E] text-center leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                                        {props.downloadText ? props.downloadText : "Download"}</div>
                                </div>
                            </>
                        }
                        {
                            props.hasAdditionalDownload &&
                            <>
                                <div className={` ${props['hasAdvanceFilter'] ? ' mt-[1rem]' : ''}`}
                                     onClick={() => props.onAdditionalDownloadClick()}>
                                    <SVGraphics className={'ml-auto mr-auto w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                                svgname={'downloadSelected'} fill={'none'}/>
                                    <div
                                        className={"max-w-[7rem] text-[#52007E] text-center leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                                        {props.additionalDownloadText ? props.additionalDownloadText : "Download"}</div>
                                </div>
                            </>
                        }
                    </div>}

                </div>

                { (!props.hasFilter || props.filterInputs.length===0) &&
                    <div className={"flex ml-auto gap-x-[1.5rem]"}>
                    {
                        props.hasDownload &&
                        <>
                            <div className={``}
                                 onClick={() => props.onDownloadClick()}>
                                <SVGraphics className={'ml-auto mr-auto w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                            svgname={'downloadSelected'} fill={'none'}/>
                                <div
                                    className={"w-[7rem] text-[#52007E] text-center leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                                    {props.downloadText ? props.downloadText : "Download"}</div>
                            </div>
                        </>
                    }
                    {
                        props.hasAdditionalDownload &&
                        <>
                            <div className={``}
                                 onClick={() => props.onAdditionalDownloadClick()}>
                                <SVGraphics className={'ml-auto mr-auto w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                            svgname={'downloadSelected'} fill={'none'}/>
                                <div
                                    className={"max-w-[7rem] text-[#52007E] text-center leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                                    {props.additionalDownloadText ? props.additionalDownloadText : "Download"}</div>
                            </div>
                        </>
                    }
                </div>}
            </div>

            {props['hasNewLine'] &&
                <div className={`mt-[3rem] mb-[3rem] w-full h-[0.0625rem] rounded-lg bg-[#E0E0D7]`} id={"line"}/>}


        </div>
    )
}

export default BodySearchFilter;
