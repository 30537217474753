import React from "react";
import SVGraphics from "../../assets/SVGraphics";
import "../../style/input/searchInput.css";

export function SearchInput(props) {

    const inputClass = props.inputClassName?props.inputClassName:'flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] border border-[#E9E9EB] bg-[#FEFEFE] py-[0.75rem] px-[1.5rem] '
    return (
        <div
            className={inputClass}>
            <input type="text" id="default-search"
                   onChange={(event) => props.onSearchChange(event.target.value)}
                   className={`w-[92%] items-center bg-[#FEFEFE]`}
                   placeholder="Search"/>
            <SVGraphics className={"h-[1rem] w-[1rem]"} svgname={'glass'}/>
        </div>
    )
}
