import set from 'set-value'
import ExcelJS from 'exceljs';

class utils {

    get(obj, path) {
        // Get the path as an array
        path = this.stringToPath(path);

        if (!path)
            return null

        // Cache the current object
        let current = obj;
        // For each item in the path, dig into the object
        for (let i = 0; i < path.length; i++) {
            // If the item isn't found, return the default (or null)
            if (current === null || this.isUndefined(current) || this.isUndefined(current[path[i]]))
                return '';
            // Otherwise, update the current  value
            current = current[path[i]];
        }
        return current;
    };

    set(object, path, value) {
        set(object, path, value);
    }

    removeEmptyOrNull = (source) => {
        const obj = Object.assign({}, source);
        Object.keys(obj).forEach(k =>
            (obj[k] && typeof obj[k] === 'object') && this.removeEmptyOrNull(obj[k]) ||
            (obj[k] === undefined || obj[k] === null || obj[k].length === 0 ||
                (obj[k] instanceof String && obj[k].isEmpty()) || obj[k] === "" || obj[k] === '') && delete obj[k]
        );

        if (Array.isArray(obj) && obj.length < 1) {
            return null;
        }

        return obj;
    };

    /**
     * Checks if value is empty. Deep-checks arrays and objects
     * Note: isEmpty([]) == true, isEmpty({}) == true, isEmpty([{0:false},"",0) == true, isEmpty({0:1}) == false
     * @param value
     * @returns {boolean}
     */
    isEmpty = (value) => {

        let is = this.isEmpty;

        let isEmptyObject = function (a) {
            if (typeof a.length === 'undefined') { // it's an Object, not an Array
                let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
                    return !is(a[element]);
                });
                return hasNonempty ? false : isEmptyObject(Object.keys(a));
            }

            return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
                return !is(element); // at least one element should be non-empty
            });
        };

        let isEmptyString = function (s) {
            if (typeof s === 'string')
                return s.length === 0;
        };

        return (
            // value === false
            // ||
            typeof value === 'undefined'
            || value == null
            || (typeof value === 'object' && isEmptyObject(value))
            || (typeof value === 'string' && isEmptyString(value))
        );
    };


    isUndefined = (obj) => {
        return typeof obj === 'undefined';
    };


    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    stringToPath = (path) => {
        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        let output = [];

        // Split to an array with dot notation
        path.split('.').forEach(function (item) {

            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {

                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };


    ObjectEmptiesToNulls = function (source) {
        const obj = Object.assign({}, source);
        Object.keys(obj).forEach(k => {

            if (obj[k] === null)
                return obj[k]

            if ((obj[k] && typeof obj[k] === 'object') && this.ObjectEmptiesToNulls(obj[k]) ||
                (obj[k] === undefined || obj[k].length === 0 ||
                    (obj[k] instanceof String && obj[k].isEmpty()) || obj[k] === "" || obj[k] === ''))
                obj[k] = null
        });
        return obj;
    }


    parseJwt(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };


    mailValidation(email) {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    }


    generateReport = async (fileName, headers, data) => {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add headers
        worksheet.addRow(headers);

        // Add data rows
        for (const row of data) {
            worksheet.addRow(row);
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
    };

    generateMultipleSheetReport = async (fileName, sheetDataArray) => {


        const workbook = new ExcelJS.Workbook();

        for (const {sheetName, headers, data} of sheetDataArray) {

            const worksheet = workbook.addWorksheet(sheetName);

            // Add headers
            worksheet.addRow(headers);

            // Add data rows
            for (const row of data) {
                worksheet.addRow(row);
            }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
    };

    getTimeFromDate = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    secondsToMMSS(seconds) {
        if (seconds) {
            const minutes = Math.floor(parseFloat(seconds) / 60);
            const remainingSeconds = parseFloat(seconds) % 60;

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(parseInt(remainingSeconds)).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;

        } else {
            return `00:00`
        }


    }

    getFloatNumber = (num) => {
        if (num) {
            let twoDecimal = parseFloat(num).toFixed(2);
            return Math.round(twoDecimal * 10) / 10;
        }
        return ""

    }
    getAgeFromBirthYear = (birthYear) => {
        const currentYear = new Date().getFullYear();
        if (birthYear) {
            return currentYear - Math.round(birthYear);
        } else {
            return 0
        }
    }

    checkIfSelfTestHasErrors = (obj) => {
        const errorsSetting = [
            "CurrentRF1", "CurrentRF2", "CurrentRF3", "VoltageRF1", "VoltageRF2", "VoltageRF3", "ImpedanceRF1", "ImpedanceRF2",
            "ImpedanceRF3", "TH_1", "TH_2", "TH_3", "TH_4", "TH_5", "TH_6", "System"]
        let isError = false
        errorsSetting.map(item => {
            if (obj[item]) {
                isError = true
            }
        })
        return isError
    }

    getDateTime = (date) => {

        const dateTime = new Date(date);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        const hours = dateTime.getHours();
        let minutes = dateTime.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        return {date: `${day}/${month}/${year}`, time: `${hours}:${minutes}`}
    }


    downloadAllDevicesDashboardData = (dataArr) => {
        let finalResult = []
        let finalQuestionnairesResult = []
        let dataToAdd = dataArr.map(item => {
            let Lock = item['Status'] ? "Lock" : "Unlock"
            let patient = item.patient ? item.patient : {}
            let patientQuestionnaires = patient['patientQuestionnaires'] && patient['patientQuestionnaires'].length > 0 ? patient['patientQuestionnaires'] : []
            let patientsDevicesHistories = patient['patientsDevicesHistories'] ? patient['patientsDevicesHistories'].map(deviceHistory => {
                return deviceHistory['External_Serial_Number']
            }) : []
            let patientTreatments = item['patientTreatments'] && item['patientTreatments'].length > 0 ? item['patientTreatments'] : []
            let selfTests = item['deviceSelfTests'] && item['deviceSelfTests'].length > 0 ? item['deviceSelfTests'] : []
            let treatmentRingAttributes = item['treatmentRingAttributes'] ? item['treatmentRingAttributes'] : {}
            let treatmentRingPadAttributes = item['treatmentRingPadAttributes'] ? item['treatmentRingPadAttributes'] : {}
            let patientAge = this.getFloatNumber(this.getAgeFromBirthYear(patient["yearsOfBirth"]))
            let patientBaseLine = this.getFloatNumber(patient['BaseLineIIEF'])
            let externalSn = Number(item['External_Serial_Number'])
            let internalSn = Number(item['Internal_Serial_Number'])
            let deviceCompany = ""
            patientTreatments.map(patientTreatment => {
                let ringTreatment = patientTreatment['treatmentRing'] ? patientTreatment['treatmentRing'] : {}
                let ringPadTreatment = patientTreatment['treatmentRingPad'] ? patientTreatment['treatmentRingPad'] : {}

                let dateTime = this.getDateTime(patientTreatment['createdAt'])
                let date = this.getFormattedDate(patientTreatment['createdAt'])
                let time = dateTime['time']
                let treatmentType = patientTreatment['isCumulative'] ?
                    "Cumulative" : "Treatment"
                if (deviceCompany.length === 0) {
                    deviceCompany = item['company']['Company_name']
                }
                finalResult.push([
                    date,
                    time,
                    externalSn,
                    internalSn,
                    patientsDevicesHistories.join(','),
                    item['MobileAppVersion'],
                    Number(item['FW_Version']),
                    Lock,
                    item['site']['Site_name'],
                    item['company']['Company_name'],
                    patientAge,
                    patientBaseLine,
                    this.getFloatNumber(patientTreatment['ActualLineIIEF']),
                    treatmentType,
                    patientTreatment['Energy'],
                    patientTreatment['OperationMode'] === "ContinuousPress" ? "Continuous press" : "Double click",
                    patientTreatment['ProgressTimer'],
                    "",
                    ringTreatment['totalTimeRF1_R']?ringTreatment['totalTimeRF1_R']:0,
                    ringTreatment['totalTimeRF2_R']?ringTreatment['totalTimeRF2_R']:0,
                    ringTreatment['totalTimeRF3_R']?ringTreatment['totalTimeRF3_R']:0,
                    ringTreatment['avgImpedanceRF1_R']?ringTreatment['avgImpedanceRF1_R']:0,
                    ringTreatment['avgImpedanceRF2_R']?ringTreatment['avgImpedanceRF2_R']:0,
                    ringTreatment['avgImpedanceRF3_R']?ringTreatment['avgImpedanceRF3_R']:0,
                    ringTreatment['avgVoltageRF1_R']?ringTreatment['avgVoltageRF1_R']:0,
                    ringTreatment['avgVoltageRF2_R']?ringTreatment['avgVoltageRF2_R']:0,
                    ringTreatment['avgVoltageRF3_R']?ringTreatment['avgVoltageRF3_R']:0,
                    ringTreatment['avgCurrentRF1_R']?ringTreatment['avgCurrentRF1_R']:0,
                    ringTreatment['avgCurrentRF2_R']?ringTreatment['avgCurrentRF2_R']:0,
                    ringTreatment['avgCurrentRF3_R']?ringTreatment['avgCurrentRF3_R']:0,
                    ringTreatment['avgTempRF1_R']?ringTreatment['avgTempRF1_R']:0,
                    ringTreatment['avgTempRF2_R']?ringTreatment['avgTempRF2_R']:0,
                    ringTreatment['avgTempRF3_R']?ringTreatment['avgTempRF3_R']:0,
                    ringTreatment['totalTimePressingButton_R']?ringTreatment['totalTimePressingButton_R']:0,
                    ringTreatment['avgBatteryVoltage_R']?ringTreatment['avgBatteryVoltage_R']:0,
                    ringTreatment['timeAtLevel1_R']?ringTreatment['timeAtLevel1_R']:0,
                    ringTreatment['timeAtLevel2_R']?ringTreatment['timeAtLevel2_R']:0,
                    ringTreatment['timeAtLevel3_R']?ringTreatment['timeAtLevel3_R']:0,
                    ringTreatment['timeAtLevel4_R']?ringTreatment['timeAtLevel4_R']:0,
                    ringTreatment['timeAtLevel5_R']?ringTreatment['timeAtLevel5_R']:0,
                    ringTreatment['timeAtLevel6_R']?ringTreatment['timeAtLevel6_R']:0,
                    ringTreatment['calcBatteryPrecent_R']?ringTreatment['calcBatteryPrecent_R']:0,
                    ringTreatment['sessionRingTimerSeconds']?ringTreatment['sessionRingTimerSeconds']:0,
                    ringTreatment['QualityFactor_R']?ringTreatment['QualityFactor_R']:0,
                    ringPadTreatment['totalTimeRF1_RP']?ringPadTreatment['totalTimeRF1_RP']:0,
                    ringPadTreatment['totalTimeRF2_RP']?ringPadTreatment['totalTimeRF2_RP']:0,
                    ringPadTreatment['totalTimeRF3_RP']?ringPadTreatment['totalTimeRF3_RP']:0,
                    ringPadTreatment['avgImpedanceRF1_RP']?ringPadTreatment['avgImpedanceRF1_RP']:0,
                    ringPadTreatment['avgImpedanceRF2_RP']?ringPadTreatment['avgImpedanceRF2_RP']:0,
                    ringPadTreatment['avgImpedanceRF3_RP']?ringPadTreatment['avgImpedanceRF3_RP']:0,
                    ringPadTreatment['avgVoltageRF1_RP']?ringPadTreatment['avgVoltageRF1_RP']:0,
                    ringPadTreatment['avgVoltageRF2_RP']?ringPadTreatment['avgVoltageRF2_RP']:0,
                    ringPadTreatment['avgVoltageRF3_RP']?ringPadTreatment['avgVoltageRF3_RP']:0,
                    ringPadTreatment['avgCurrentRF1_RP']?ringPadTreatment['avgCurrentRF1_RP']:0,
                    ringPadTreatment['avgCurrentRF2_RP']?ringPadTreatment['avgCurrentRF2_RP']:0,
                    ringPadTreatment['avgCurrentRF3_RP']?ringPadTreatment['avgCurrentRF3_RP']:0,
                    ringPadTreatment['avgTempRF1_RP']?ringPadTreatment['avgTempRF1_RP']:0,
                    ringPadTreatment['avgTempRF2_RP']?ringPadTreatment['avgTempRF2_RP']:0,
                    ringPadTreatment['avgTempRF3_RP']?ringPadTreatment['avgTempRF3_RP']:0,
                    ringPadTreatment['totalTimePressingButton_RP']?ringPadTreatment['totalTimePressingButton_RP']:0,
                    ringPadTreatment['avgBatteryVoltage_RP']?ringPadTreatment['avgBatteryVoltage_RP']:0,
                    ringPadTreatment['timeAtLevel1_RP']?ringPadTreatment['timeAtLevel1_RP']:0,
                    ringPadTreatment['timeAtLevel2_RP']?ringPadTreatment['timeAtLevel2_RP']:0,
                    ringPadTreatment['timeAtLevel3_RP']?ringPadTreatment['timeAtLevel3_RP']:0,
                    ringPadTreatment['timeAtLevel4_RP']?ringPadTreatment['timeAtLevel4_RP']:0,
                    ringPadTreatment['timeAtLevel5_RP']?ringPadTreatment['timeAtLevel5_RP']:0,
                    ringPadTreatment['timeAtLevel6_RP']?ringPadTreatment['timeAtLevel6_RP']:0,
                    ringPadTreatment['calcBatteryPrecent_RP']?ringPadTreatment['calcBatteryPrecent_RP']:0,
                    ringPadTreatment['sessionRingPadTimerSeconds']?ringPadTreatment['sessionRingPadTimerSeconds']:0,
                    ringPadTreatment['QualityFactor_RP']?ringPadTreatment['QualityFactor_RP']:0
                ])
            })

            selfTests.map(selfTest => {
                let dateTime = this.getDateTime(selfTest['createdAt'])
                let date = this.getFormattedDate(selfTest['createdAt'])
                let time = dateTime['time']
                let treatmentType = "Self-test"
                let selfTestHasError = this.checkIfSelfTestHasErrors(selfTest) ? "Fail" : "Pass"
                finalResult.push([
                    date,
                    time,
                    externalSn,
                    internalSn,
                    patientsDevicesHistories.join(','),
                    item['MobileAppVersion'],
                    Number(item['FW_Version']),
                    Lock,
                    item['site']['Site_name'],
                    item['company']['Company_name'],
                    patientAge,
                    "",
                    "",
                    treatmentType,
                    selfTest['Energy'],
                    selfTest['OperationMode'] === "ContinuousPress" ? "Continuous press" : "Double click",
                    selfTest['ProgressTimer'],
                    selfTestHasError,
                    selfTest['totalTimeRF1_R'],
                    selfTest['totalTimeRF2_R'],
                    selfTest['totalTimeRF3_R'],
                    selfTest['ImpedanceValueRF1'],
                    selfTest['ImpedanceValueRF2'],
                    selfTest['ImpedanceValueRF3'],
                    selfTest['VoltageValueRF1'],
                    selfTest['VoltageValueRF2'],
                    selfTest['VoltageValueRF3'],
                    selfTest['CurrentValueRF1'],
                    selfTest['CurrentValueRF2'],
                    selfTest['CurrentValueRF3'],
                    selfTest['avgTempRF1_R'],
                    selfTest['avgTempRF2_R'],
                    selfTest['avgTempRF3_R'],
                    selfTest['totalTimePressingButton_R'],
                    selfTest['avgBatteryVoltage_R'],
                    selfTest['timeAtLevel1_R'],
                    selfTest['timeAtLevel2_R'],
                    selfTest['timeAtLevel3_R'],
                    selfTest['timeAtLevel4_R'],
                    selfTest['timeAtLevel5_R'],
                    selfTest['timeAtLevel6_R'],
                    selfTest['calcBatteryPrecent_R'],
                    selfTest['sessionRingTimerSeconds'],
                    selfTest['QualityFactor_R'],
                    selfTest['totalTimeRF1_RP'],
                    selfTest['totalTimeRF2_RP'],
                    selfTest['totalTimeRF3_RP'],
                    selfTest['avgImpedanceRF1_RP'],
                    selfTest['avgImpedanceRF2_RP'],
                    selfTest['avgImpedanceRF3_RP'],
                    selfTest['avgVoltageRF1_RP'],
                    selfTest['avgVoltageRF2_RP'],
                    selfTest['avgVoltageRF3_RP'],
                    selfTest['avgCurrentRF1_RP'],
                    selfTest['avgCurrentRF2_RP'],
                    selfTest['avgCurrentRF3_RP'],
                    selfTest['avgTempRF1_RP'],
                    selfTest['avgTempRF2_RP'],
                    selfTest['avgTempRF3_RP'],
                    selfTest['totalTimePressingButton_RP'],
                    selfTest['avgBatteryVoltage_RP'],
                    selfTest['timeAtLevel1_RP'],
                    selfTest['timeAtLevel2_RP'],
                    selfTest['timeAtLevel3_RP'],
                    selfTest['timeAtLevel4_RP'],
                    selfTest['timeAtLevel5_RP'],
                    selfTest['timeAtLevel6_RP'],
                    selfTest['calcBatteryPrecent_RP'],
                    selfTest['sessionRingPadTimerSeconds'],
                    selfTest['QualityFactor_RP'],
                    selfTest['TH_1'] ? "Fail" : "Pass",
                    selfTest['TH_2'] ? "Fail" : "Pass",
                    selfTest['TH_3'] ? "Fail" : "Pass",
                    selfTest['TH_4'] ? "Fail" : "Pass",
                    selfTest['TH_5'] ? "Fail" : "Pass",
                    selfTest['TH_6'] ? "Fail" : "Pass",
                    selfTest['System'] ? "Fail" : "Pass",
                ])
            })

            patientQuestionnaires.map((questionnaire,index) => {
                let dateTime = this.getDateTime(questionnaire['createdAt'])
                let date = this.getFormattedDate(questionnaire['createdAt'])
                let time = dateTime['time']
                let questionnaireAnswersArr = questionnaire['questionnaireAnswers'] ? questionnaire['questionnaireAnswers'] : []
                let questionnaireAnswersObj = {}
                questionnaireAnswersArr.map(answer => {
                    questionnaireAnswersObj[answer['QuestionID']] = answer['AnswerValue']
                })

                let answer1 = questionnaireAnswersObj['QuestionID1']
                let answer2 = questionnaireAnswersObj['QuestionID2']
                let answer3 = questionnaireAnswersObj['QuestionID3']
                let answer4 = questionnaireAnswersObj['QuestionID4']
                let answer5 = questionnaireAnswersObj['QuestionID5']
                let answer6 = questionnaireAnswersObj['QuestionID6']
                let actualIIEF= index=== patientQuestionnaires.length-1?"": Number(answer1)+ Number(answer2)+ Number(answer3)+ Number(answer4)+ Number(answer5)+ Number(answer6)

                finalQuestionnairesResult.push([
                    date,
                    time,
                    Number(item['External_Serial_Number']),
                    Number(item['Internal_Serial_Number']),
                    patientsDevicesHistories.join(','),
                    item['MobileAppVersion'],
                    Number(item['FW_Version']),
                    Lock,
                    item['site']['Site_name'],
                    item['company'] ? item['company']['Company_name'] : '',
                    this.getFloatNumber(this.getAgeFromBirthYear(item['patient']["yearsOfBirth"])),
                    this.getFloatNumber(patient['BaseLineIIEF']),
                    actualIIEF,
                    Number(answer1),
                    Number(answer2),
                    Number(answer3),
                    Number(answer4),
                    Number(answer5),
                    Number(answer6)
                ])

            })
        })

        let headers = ["Date", "Time", "Ext SN ", "Int SN", "Previous Ext SN", "SW No.", "FW No.", "Status", "Site", "Company", "Age",
            "Base line IIEF-EF", "Actual IIEF-EF", "Trnt. Type", "Energy mode", "Operation mode", "Progress timer", "Self-test Results",
            "Tow RF1", "Tow RF2", "Tow RF3", "Average Impedance RF1", "Average Impedance RF2", "Average Impedance RF3",
            "Average Voltage RF1", "Average Voltage RF2", "Average Voltage RF3", "Average current RF1", "Average current RF2",
            "Average current RF3", "Average Temp RF1", "Average Temp RF2", "Average Temp RF3", "Time pressing energy button",
            "Average battery voltage x10", "Time at level 1", "Time at level 2", "Time at level 3", "Time at level 4",
            "Time at level 5", "Time at level 6", "Calc_Battery_Percent (Ring)", "session_ring_timer_seconds", "Quality_factor_ring",
             "Tow RF1(+Pad)", "Tow RF2(+Pad)",
            "Tow RF3(+Pad)", "Average Impedance RF1(+Pad)", "Average Impedance RF2(+Pad)", "Average Impedance RF3(+Pad)",
            "Average Voltage RF1(+Pad)", "Average Voltage RF2(+Pad)", "Average Voltage RF3(+Pad)", "Average current RF1(+Pad)",
            "Average current RF2(+Pad)", "Average current RF3(+Pad)", "Average Temp RF1(+Pad)", "Average Temp RF2(+Pad)", "Average Temp RF3(+Pad)",
            "Time pressing energy button(+Pad)", "Average battery voltage x10(+Pad)", "Time at level 1(+Pad)",
            "Time at level 2(+Pad)", "Time at level 3(+Pad)", "Time at level 4(+Pad)", "Time at level 5(+Pad)", "Time at level 6(+Pad)",
            "Calc_Battery_Percent (Ring+Pad)", "session_ring_pad_timer_seconds", "Quality_factor_ring_pad", "TH#1", "TH#2", "TH#3", "TH#4", "TH#5", "TH#6", "System"
        ]

        /*     let IIEF_headers = ["Date", "Time", "Ext SN ", "Int SN", "Previous Ext SN", "SW No.", "FW No.", "Status", "Site", "Company", "Age",
                 "Base line IIEF-EF", "Actual IIEF-EF",
                 "How often were you able to get an erection during sexual activity?",
                 "When you had erections with sexual stimulation, how often were your erections hard enough for penetration?",
                 "When you attempted intercourse, how often were you able to penetrate (enter) your partner?",
                 "During sexual intercourse, how often were you able to maintain your erection after you had penetrated (entered) your partner?",
                 "During sexual intercourse, how difficult was it to maintain your erection to completion of intercourse?",
                 "How do you rate your confidence that you could get and keep an erection?"]  */

        let IIEF_headers = ["Date", "Time", "Ext SN ", "Int SN", "Previous Ext SN", "SW No.", "FW No.", "Status", "Site", "Company", "Age",
            "Base line IIEF-EF", "Actual IIEF-EF",
            "Q1", "Q2", "Q3", "Q4", "Q5", "Q6"]

        const sheet1Data = {
            sheetName: 'DeviceDashboard',
            headers: headers,
            data: finalResult,
        };

        const sheet2Data = {
            sheetName: 'IIEF-EF',
            headers: IIEF_headers,
            data: finalQuestionnairesResult,
        };

        const sheetDataArray = [sheet1Data, sheet2Data];
        this.generateMultipleSheetReport(`"ohhmed_devices_dashboard_table"`, sheetDataArray)
    }

    getFormattedDate = (date) => {
        const currentDate = new Date(date);
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

    downloadDeviceDashboardData = (currentDevice,patientTreatments, currentRingTreatments, currentRingPadTreatments, currentSelfTests, devicesHistory, questionnaires) => {
        let headers = ["Date", "Time", "Ext SN ", "Int SN", "Previous Ext SN", "SW No.", "FW No.", "Status", "Site", "Company", "Age",
            "Base line IIEF-EF", "Actual IIEF-EF", "Trnt. Type", "Energy mode", "Operation mode", "Progress timer", "Self-test Results",
            "Tow RF1", "Tow RF2", "Tow RF3", "Average Impedance RF1", "Average Impedance RF2", "Average Impedance RF3",
            "Average Voltage RF1", "Average Voltage RF2", "Average Voltage RF3", "Average current RF1", "Average current RF2",
            "Average current RF3", "Average Temp RF1", "Average Temp RF2", "Average Temp RF3", "Time pressing energy button",
            "Average battery voltage x10", "Time at level 1", "Time at level 2", "Time at level 3", "Time at level 4",
            "Time at level 5", "Time at level 6", "Calc_Battery_Percent (Ring)", "session_ring_timer_seconds", "Quality_factor_ring"
            , "Tow RF1(+Pad)", "Tow RF2(+Pad)", "Tow RF3(+Pad)", "Average Impedance RF1(+Pad)", "Average Impedance RF2(+Pad)",
            "Average Impedance RF3(+Pad)", "Average Voltage RF1(+Pad)", "Average Voltage RF2(+Pad)", "Average Voltage RF3(+Pad)",
            "Average current RF1(+Pad)", "Average current RF2(+Pad)", "Average current RF3(+Pad)", "Average Temp RF1(+Pad)",
            "Average Temp RF2(+Pad)", "Average Temp RF3(+Pad)", "Time pressing energy button(+Pad)", "Average battery voltage x10(+Pad)",
            "Time at level 1(+Pad)", "Time at level 2(+Pad)", "Time at level 3(+Pad)", "Time at level 4(+Pad)", "Time at level 5(+Pad)",
            "Time at level 6(+Pad)", "Calc_Battery_Percent (Ring+Pad)", "session_ring_pad_timer_seconds", "Quality_factor_ring_pad",
            "TH#1", "TH#2", "TH#3", "TH#4", "TH#5", "TH#6", "System"
        ]
        let patientsDevicesHistories = devicesHistory.map(history => {
            return history['External_Serial_Number']
        })

        let Lock = currentDevice['Status'] ? "Lock" : "Unlock"
        let siteName = currentDevice['site']['Site_name']
        let companyName = currentDevice['company']['Company_name']
        let age = this.getFloatNumber(this.getAgeFromBirthYear(currentDevice['patient']["yearsOfBirth"]))
        let baseLine = this.getFloatNumber(currentDevice['patient']['BaseLineIIEF'])

        let patientTreatmentArr =[]
        patientTreatments.map(item=>{
            let dateTime = this.getDateTime(item['createdAt'])
            let date = this.getFormattedDate(item['createdAt'])
            let externalSn = currentDevice['External_Serial_Number']
            let internalSn = currentDevice['Internal_Serial_Number']
            if (!externalSn || !internalSn || externalSn.trim() === "" || internalSn.trim() === "") {
                let findIndex = devicesHistory.findIndex(device => {
                    return device.Device_index === item.Device_index
                })
                if (findIndex >= 0) {
                    externalSn = devicesHistory[findIndex]['External_Serial_Number']
                    internalSn = devicesHistory[findIndex]['Internal_Serial_Number']
                }
            }
            let time = dateTime['time']
            let ringIndex =  currentRingTreatments.findIndex(ringTreatment=> ringTreatment['treatmentID'] === item['treatmentID'])
            let ringPadIndex =  currentRingPadTreatments.findIndex(ringPadTreatment=> ringPadTreatment['treatmentID'] === item['treatmentID'])
            let ringTreatment = ringIndex>=0?currentRingTreatments[ringIndex]:{}
            let ringPadTreatment = ringPadIndex>=0?currentRingPadTreatments[ringPadIndex]:{}
            let treatmentType = item['isCumulative'] ? "Cumulative" : "Treatment"
            if(item['sessionTimerSeconds'] && item['sessionTimerSeconds']>0){
                patientTreatmentArr.push([
                    date,
                    time,
                    Number(externalSn),
                    Number(internalSn),
                    patientsDevicesHistories.join(','),
                    item['MobileAppVersion'],
                    Number(currentDevice['FW_Version']),
                    Lock,
                    siteName,
                    companyName,
                    age,
                    baseLine,
                    this.getFloatNumber(item['ActualLineIIEF']),
                    treatmentType,
                    item['Energy'],
                    item['OperationMode'] === "ContinuousPress" ? "Continuous press" : "Double click",
                    item['ProgressTimer'],
                    "",
                    ringTreatment['totalTimeRF1_R']?ringTreatment['totalTimeRF1_R']:0,
                    ringTreatment['totalTimeRF2_R']?ringTreatment['totalTimeRF2_R']:0,
                    ringTreatment['totalTimeRF3_R']?ringTreatment['totalTimeRF3_R']:0,
                    ringTreatment['avgImpedanceRF1_R']?ringTreatment['avgImpedanceRF1_R']:0,
                    ringTreatment['avgImpedanceRF2_R']?ringTreatment['avgImpedanceRF2_R']:0,
                    ringTreatment['avgImpedanceRF3_R']?ringTreatment['avgImpedanceRF3_R']:0,
                    ringTreatment['avgVoltageRF1_R']?ringTreatment['avgVoltageRF1_R']:0,
                    ringTreatment['avgVoltageRF2_R']?ringTreatment['avgVoltageRF2_R']:0,
                    ringTreatment['avgVoltageRF3_R']?ringTreatment['avgVoltageRF3_R']:0,
                    ringTreatment['avgCurrentRF1_R']?ringTreatment['avgCurrentRF1_R']:0,
                    ringTreatment['avgCurrentRF2_R']?ringTreatment['avgCurrentRF2_R']:0,
                    ringTreatment['avgCurrentRF3_R']?ringTreatment['avgCurrentRF3_R']:0,
                    ringTreatment['avgTempRF1_R']?ringTreatment['avgTempRF1_R']:0,
                    ringTreatment['avgTempRF2_R']?ringTreatment['avgTempRF2_R']:0,
                    ringTreatment['avgTempRF3_R']?ringTreatment['avgTempRF3_R']:0,
                    ringTreatment['totalTimePressingButton_R']?ringTreatment['totalTimePressingButton_R']:0,
                    ringTreatment['avgBatteryVoltage_R']?ringTreatment['avgBatteryVoltage_R']:0,
                    ringTreatment['timeAtLevel1_R']?ringTreatment['timeAtLevel1_R']:0,
                    ringTreatment['timeAtLevel2_R']?ringTreatment['timeAtLevel2_R']:0,
                    ringTreatment['timeAtLevel3_R']?ringTreatment['timeAtLevel3_R']:0,
                    ringTreatment['timeAtLevel4_R']?ringTreatment['timeAtLevel4_R']:0,
                    ringTreatment['timeAtLevel5_R']?ringTreatment['timeAtLevel5_R']:0,
                    ringTreatment['timeAtLevel6_R']?ringTreatment['timeAtLevel6_R']:0,
                    ringTreatment['calcBatteryPrecent_R']?ringTreatment['calcBatteryPrecent_R']:0,
                    ringTreatment['sessionRingTimerSeconds']?ringTreatment['sessionRingTimerSeconds']:0,
                    ringTreatment['QualityFactor_R']?ringTreatment['QualityFactor_R']:0,
                    ringPadTreatment['totalTimeRF1_RP']?ringPadTreatment['totalTimeRF1_RP']:0,
                    ringPadTreatment['totalTimeRF2_RP']?ringPadTreatment['totalTimeRF2_RP']:0,
                    ringPadTreatment['totalTimeRF3_RP']?ringPadTreatment['totalTimeRF3_RP']:0,
                    ringPadTreatment['avgImpedanceRF1_RP']?ringPadTreatment['avgImpedanceRF1_RP']:0,
                    ringPadTreatment['avgImpedanceRF2_RP']?ringPadTreatment['avgImpedanceRF2_RP']:0,
                    ringPadTreatment['avgImpedanceRF3_RP']?ringPadTreatment['avgImpedanceRF3_RP']:0,
                    ringPadTreatment['avgVoltageRF1_RP']?ringPadTreatment['avgVoltageRF1_RP']:0,
                    ringPadTreatment['avgVoltageRF2_RP']?ringPadTreatment['avgVoltageRF2_RP']:0,
                    ringPadTreatment['avgVoltageRF3_RP']?ringPadTreatment['avgVoltageRF3_RP']:0,
                    ringPadTreatment['avgCurrentRF1_RP']?ringPadTreatment['avgCurrentRF1_RP']:0,
                    ringPadTreatment['avgCurrentRF2_RP']?ringPadTreatment['avgCurrentRF2_RP']:0,
                    ringPadTreatment['avgCurrentRF3_RP']?ringPadTreatment['avgCurrentRF3_RP']:0,
                    ringPadTreatment['avgTempRF1_RP']?ringPadTreatment['avgTempRF1_RP']:0,
                    ringPadTreatment['avgTempRF2_RP']?ringPadTreatment['avgTempRF2_RP']:0,
                    ringPadTreatment['avgTempRF3_RP']?ringPadTreatment['avgTempRF3_RP']:0,
                    ringPadTreatment['totalTimePressingButton_RP']?ringPadTreatment['totalTimePressingButton_RP']:0,
                    ringPadTreatment['avgBatteryVoltage_RP']?ringPadTreatment['avgBatteryVoltage_RP']:0,
                    ringPadTreatment['timeAtLevel1_RP']?ringPadTreatment['timeAtLevel1_RP']:0,
                    ringPadTreatment['timeAtLevel2_RP']?ringPadTreatment['timeAtLevel2_RP']:0,
                    ringPadTreatment['timeAtLevel3_RP']?ringPadTreatment['timeAtLevel3_RP']:0,
                    ringPadTreatment['timeAtLevel4_RP']?ringPadTreatment['timeAtLevel4_RP']:0,
                    ringPadTreatment['timeAtLevel5_RP']?ringPadTreatment['timeAtLevel5_RP']:0,
                    ringPadTreatment['timeAtLevel6_RP']?ringPadTreatment['timeAtLevel6_RP']:0,
                    ringPadTreatment['calcBatteryPrecent_RP']?ringPadTreatment['calcBatteryPrecent_RP']:0,
                    ringPadTreatment['sessionRingPadTimerSeconds']?ringPadTreatment['sessionRingPadTimerSeconds']:0,
                    ringPadTreatment['QualityFactor_RP']?ringPadTreatment['QualityFactor_RP']:0,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                ])
            }
        })
        let selfTests = currentSelfTests.map(item => {
            let dateTime = this.getDateTime(item['createdAt'])
            let date = this.getFormattedDate(item['createdAt'])
            let time = dateTime['time']
            let treatmentType = "Self-test"
            let externalSn = item['External_Serial_Number']
            let internalSn = item['Internal_Serial_Number']
            let selfTestHasError = this.checkIfSelfTestHasErrors(item) ? "Fail" : "Pass"
            return [
                date,
                time,
                Number(externalSn),
                Number(internalSn),
                patientsDevicesHistories.join(','),
                item['MobileAppVersion'],
                Number(currentDevice['FW_Version']),
                Lock,
                siteName,
                companyName,
                age,
                "",
                "",
                treatmentType,
                item['Energy'],
                item['OperationMode'] === "ContinuousPress" ? "Continuous press" : "Double click",
                item['ProgressTimer'],
                selfTestHasError,
                item['totalTimeRF1_R'],
                item['totalTimeRF2_R'],
                item['totalTimeRF3_R'],
                item['ImpedanceValueRF1'],
                item['ImpedanceValueRF2'],
                item['ImpedanceValueRF3'],
                item['VoltageValueRF1'],
                item['VoltageValueRF2'],
                item['VoltageValueRF3'],
                item['CurrentValueRF1'],
                item['CurrentValueRF2'],
                item['CurrentValueRF3'],
                item['avgTempRF1_R'],
                item['avgTempRF2_R'],
                item['avgTempRF3_R'],
                item['totalTimePressingButton_R'],
                item['avgBatteryVoltage_R'],
                item['timeAtLevel1_R'],
                item['timeAtLevel2_R'],
                item['timeAtLevel3_R'],
                item['timeAtLevel4_R'],
                item['timeAtLevel5_R'],
                item['timeAtLevel6_R'],
                item['calcBatteryPrecent_R'],
                item['sessionRingTimerSeconds'],
                item['QualityFactor_R'],
                item['totalTimeRF1_RP'],
                item['totalTimeRF2_RP'],
                item['totalTimeRF3_RP'],
                item['avgImpedanceRF1_RP'],
                item['avgImpedanceRF2_RP'],
                item['avgImpedanceRF3_RP'],
                item['avgVoltageRF1_RP'],
                item['avgVoltageRF2_RP'],
                item['avgVoltageRF3_RP'],
                item['avgCurrentRF1_RP'],
                item['avgCurrentRF2_RP'],
                item['avgCurrentRF3_RP'],
                item['avgTempRF1_RP'],
                item['avgTempRF2_RP'],
                item['avgTempRF3_RP'],
                item['totalTimePressingButton_RP'],
                item['avgBatteryVoltage_RP'],
                item['timeAtLevel1_RP'],
                item['timeAtLevel2_RP'],
                item['timeAtLevel3_RP'],
                item['timeAtLevel4_RP'],
                item['timeAtLevel5_RP'],
                item['timeAtLevel6_RP'],
                item['calcBatteryPrecent_RP'],
                item['sessionRingPadTimerSeconds'],
                item['QualityFactor_RP'],
                item['TH_1'] ? "Fail" : "Pass",
                item['TH_2'] ? "Fail" : "Pass",
                item['TH_3'] ? "Fail" : "Pass",
                item['TH_4'] ? "Fail" : "Pass",
                item['TH_5'] ? "Fail" : "Pass",
                item['TH_6'] ? "Fail" : "Pass",
                item['System'] ? "Fail" : "Pass",
            ]
        })

        let IIEF_headers = ["Date", "Time", "Ext SN ", "Int SN", "Previous Ext SN", "SW No.", "FW No.", "Status", "Site", "Company", "Age",
            "Base line IIEF-EF", "Actual IIEF-EF",
            "How often were you able to get an erection during sexual activity?",
            "When you had erections with sexual stimulation, how often were your erections hard enough for penetration?",
            "When you attempted intercourse, how often were you able to penetrate (enter) your partner?",
            "During sexual intercourse, how often were you able to maintain your erection after you had penetrated (entered) your partner?",
            "During sexual intercourse, how difficult was it to maintain your erection to completion of intercourse?",
            "How do you rate your confidence that you could get and keep an erection?"]

        let IIEF_data = questionnaires.map((questionnaire,index) => {
            let dateTime = this.getDateTime(questionnaire['createdAt'])
            let date = this.getFormattedDate(questionnaire['createdAt'])
            let time = dateTime['time']
            let questionnaireAnswersArr = questionnaire['questionnaireAnswers'] ? questionnaire['questionnaireAnswers'] : []
            let questionnaireAnswersObj = {}
            questionnaireAnswersArr.map(answer => {
                questionnaireAnswersObj[answer['QuestionID']] = answer['AnswerValue']
            })

            let answer1 = questionnaireAnswersObj['QuestionID1']
            let answer2 = questionnaireAnswersObj['QuestionID2']
            let answer3 = questionnaireAnswersObj['QuestionID3']
            let answer4 = questionnaireAnswersObj['QuestionID4']
            let answer5 = questionnaireAnswersObj['QuestionID5']
            let answer6 = questionnaireAnswersObj['QuestionID6']
            let actualIIEF= index=== questionnaires.length-1?"": Number(answer1)+ Number(answer2)+ Number(answer3)+ Number(answer4)+ Number(answer5)+ Number(answer6)
            return [
                date,
                time,
                Number(currentDevice['External_Serial_Number']),
                Number(currentDevice['Internal_Serial_Number']),
                patientsDevicesHistories.join(','),
                currentDevice['MobileAppVersion'],
                Number(currentDevice['FW_Version']),
                Lock,
                currentDevice['site']['Site_name'],
                currentDevice['company']['Company_name'],
                this.getFloatNumber(this.getAgeFromBirthYear(currentDevice['patient']["yearsOfBirth"])),
                this.getFloatNumber(currentDevice['patient']['BaseLineIIEF']),
                actualIIEF,
                Number(answer1),
                Number(answer2),
                Number(answer3),
                Number(answer4),
                Number(answer5),
                Number(answer6)

            ]

        })


        const sheet1Data = {
            sheetName: 'DeviceDashboard',
            headers: headers,
            data: [...patientTreatmentArr, ...selfTests],
        };

        const sheet2Data = {
            sheetName: 'IIEF-EF',
            headers: IIEF_headers,
            data: IIEF_data,
        };

        const sheetDataArray = [sheet1Data, sheet2Data];
         this.generateMultipleSheetReport(`ohhmed_device_${currentDevice['Internal_Serial_Number']}_table`, sheetDataArray)
    }

}

export default new utils();
