const Config = {

    globalUrl: process.env.REACT_APP_API_ENDPOINT,
    userRoles: ['CtManager'],
    loginRoles: ['CtManager'],

    roles: {
        CtManager: 'CtManager',
    },
    version: 'V' + require('../../package.json')['version'],
    BuildVersion: 'V' + require('../../package.json')['BuildVersion'],
};

Object.freeze(Config);
export default Config;
