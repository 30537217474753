import React from "react";
import {TextInput} from "./TextInput";

export function InputImage(props) {
    return (
        <div className={props.containerClassName}>
            <div className={props.inputClassName}>
            <input type={"file"} id={"file"} disabled={props.disabled} name={props.name} className={props.inputClassName} placeholder={props.placeholder} onChange={props.onChange}/>
            <TextInput text={props.fileName?props.fileName:'Add Logo'} className={props.labelClassName}/>


            </div>
            {
                props.previewImage &&
                    <>
                        <div
                            style={props.removeStyle}
                            className={"rounded-full w-8 h-8 p-1 text-center float-right cursor-pointer mt-3 mr-3 text-black font-semibold"}
                            onClick={props.deletePhoto}>
                            &#10005;
                        </div>
                        <img
                            className="mt-8"
                            src={props.previewImage}
                            alt=""/>

                    </>
            }

        </div>
    )
}
