import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const getCurrentUser=()=>{
    return authenticationService.currentUserValue;
}

const DistributorApi = {
    getAllLanguages: async (filtersArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/language/getAll`,
            filtersArr
        );
    },

    getAllDevicesTrack: async (filtersArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/devicesTrack/getAll`,
            filtersArr
        );
    },

    getDevicesTrackByIndex: async (index) => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/devicesTrack/${index}`
        );
    },

    getAllPossibleSites: async () => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/sites/getAllPossibleSites`
        );
    },

    getSiteByIndex: async (index) => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/sites/${index}`
        );
    },
    getAllCompanies: async (filtersArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/companies/getAll`,
            filtersArr
        );
    },

    getAllSites: async () => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/sites/getAll`
        );
    },

    exportAllDevicesDashboardData: async (devicesArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/devicesTrack/exportAll`,
            devicesArr
        );
    },

    getDeviceQuestionnaires: async (device_index) => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/getDeviceQuestionnaires`
        );
    },

    setPatientPhone: async (device_index,patientPhone) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/setPatientPhone`,
            patientPhone
        );
    },

    getCompanyByIndex: async (index) => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/companies/${index}`
        );
    },

    getAllUsers: async (filtersArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/users/getAll`,
            filtersArr
        );
    },

    getUserByIndex: async (index) => {
        return await restApi.get(
            `distributor/${getCurrentUser().profile['UserID']}/users/${index}`
        );
    },

    getAllDashboardData: async (filtersArr) => {
        return await restApi.post(
            `distributor/${getCurrentUser().profile['UserID']}/dashboard/getAll`,
            filtersArr
        );
    },



}
export default DistributorApi