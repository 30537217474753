let  Constants = {

    emailRegExp: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneRegExp: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,


    TimeFromFirstTreatment:[
        {label: "2 Weeks", value: "2 Weeks"},
        {label: "1 Month", value: "1 Month"},
        {label: "2 Month", value: "2 Month"},
        {label: "3 Month", value: "3 Month"},
        {label: "4 Month", value: "4 Month"},
        {label: "5 Month", value: "5 Month"},
        {label: "6 Month", value: "6 Month"},
        {label: "1 Year", value: "1 Year"},
        {label: "2 Years", value: "2 Years"},
        {label: ">2 Years", value: ">2 Years"},
    ],


    AgeFilterData:[
        {label: "<20", value: "<20"},
        {label: "20-29", value: "20-29"},
        {label: "30-39", value: "30-39"},
        {label: "40-49", value: "40-49"},
        {label: "50-59", value: "50-59"},
        {label: "60-69", value: "60-69"},
        {label: "70-79", value: "70-79"},
        {label: "80-89", value: "80-89"},
        {label: "90-100", value: "90-100"},
        {label: ">100", value: ">100"}],

    BaseLineFilterData:[{label: "0-5", value: "0-5"},
        {label: "6-10", value: "6-10"},
        {label: "11-15", value: "11-15"},
        {label: "16-20", value: "16-20"},
        {label: "21-25", value: "21-25"},
        {label: "26-30", value: "26-30"}],

    EnergyMode:[
        {label: "Normal", value: "Normal"},
        {label: "Adaptive", value: "Adaptive"}],


NumberOfTreatmentsFilterData:[
    {label: "0", value: "0"},
    {label: "1-4", value: "1-4"},
        {label: "5-9", value: "5-9"},
        {label: "10-14", value: "10-14"},
        {label: "15-19", value: "15-19"},
        {label: "20-24", value: "20-24"},
        {label: "25-29", value: "25-29"},
        {label: "30-34", value: "30-34"},
        {label: "35-39", value: "35-39"},
        {label: "40-44", value: "40-44"},
        {label: "45-49", value: "45-49"},
        {label: ">50", value: ">50"}
],
    TemperatureFilterData:[
        {label:"<20",    value:"<20"},
        {label:"20-29",  value:"20-29"},
        {label:"30-35",  value:"30-35"},
        {label:"35-37",  value:"35-37"},
        {label:"38-40",  value:"38-40"},
        {label:"41-43",  value:"41-43"},
        {label:"44-45",  value:"44-45"},
        {label:"46-50",  value:"46-50"},
        {label:">50",    value:">50"}],

    SessionsTimeFilterData:[
        {label:"0-4",    value:"0-4"},
        {label:"5-9",  value:"5-9"},
        {label:"10-15",  value:"10-15"}],

    QualityFilterData:[
        {label:"0-9",    value:"0-9"},
        {label:"10-19",  value:"10-19"},
        {label:"20-29",  value:"20-29"},
        {label:"30-39",  value:"30-39"},
        {label:"40-49",  value:"40-49"},
        {label:"50-59",  value:"50-59"},
        {label:"60-69",  value:"60-69"},
        {label:"70-79",  value:"70-79"},
        {label:"80-89",  value:"80-89"},
        {label:"90-100",  value:"90-100"}
    ],
    EnergyLevelFilterData:[
        {label:"1-1.5",  value:"1-1.5"},
        {label:"1.5-2",  value:"1.5-2"},
        {label:"2-2.5",  value:"2-2.5"},
        {label:"2.5-3",  value:"2.5-3"},
        {label:"3-3.5",  value:"3-3.5"},
        {label:"3.5-4",  value:"3.5-4"},
        {label:"4-4.5",  value:"4-4.5"},
        {label:"4.5-5",  value:"4.5-5"},
        {label:"5-5.5",  value:"5-5.5"},
        {label:"5.5-6",  value:"5.5-6"}
    ],

}

export default Constants

