import React, {useEffect, useState} from "react";
import '../App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import {authenticationService} from "../services/AuthinticationService";
import SVGraphics from "../assets/SVGraphics";
import {SideNavigationBar} from "../components/navigation/SideNavigationBar";
import IdleTimer from "react-idle-timer";

import {VerificationCode} from "./auth/VerificationCode";
import {ForgotPassword} from "./auth/ForgotPassword";
import {Login} from "./auth/Login";
import {ResetPassword} from "./auth/ResetPassword";
import AddNewUser from "./app/admins/AddNewUser";
import EditUser from "./app/admins/EditUser";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Config from "../config/Config";
import {AdminApi, LoginAPICalls} from "../services/ApiService";
import VerticaBG from "../assets/VERTICA-bg.png"
import logo from "../assets/verticaPlus.png";
import DashboardComp from "./app/DashboardComp";
import LanguageComp from "./app/LanguageComp";
import SNManagementComp from "./app/SNManagementComp";
import DeviceManagementComp from "./app/DeviceManagementComp";
import AdminManagementComp from "./app/AdminManagementComp";
import CompaniesComp from "./app/CompaniesComp";
import AddNewCompany from "./app/companies/AddNewCompany";
import EditCompany from "./app/companies/EditCompany";
import DistributorApi from "../services/ApiServices/DistributorApi";
import CallCenterApi from "../services/ApiServices/CallCenterApi";
import SitesComp from "./app/SitesComp";
import AddNewSite from "./app/sites/AddNewSite";
import EditSite from "./app/sites/EditSite";
import DeviceTreatmentsMain from "./app/device/DeviceTreatmentsMain";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

function MainRouters() {

    const [isIdle, setIsIdle] = useState(false);
    const autoLogoutTime = 12 * (60  * 60  * 1000 );
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate();
    const [beVersion, setBeVersion] = useState('');
    const pathname = window.location.pathname

    const currentUser = authenticationService.isLoggedin() ? authenticationService.currentUserValue.role : ""


    useEffect(() => {
        if (!isLoginRoutes && !currentUser) {
            navigate("/login")
        }

        const currentUrl = window.location.href;
        let currentUserRole= currentUser?currentUser:""

        if(!currentUrl.includes(currentUserRole)){
            navigate(`${rolePath}/Dashboard`)
        }

        const getVersion = async () => {
            let BEVersion = await LoginAPICalls.getVersion()
            console.log("BEVersion", BEVersion)
            setBeVersion(BEVersion.data)
        }
        getVersion().then()

    }, [])

    const rolePath = currentUser ? currentUser : ""

    const getUserRoutes = (user) => {
        if (user === "SuperAdmin" || user === "Admin") {
            return [
                {name: 'Dashboard', label: 'Dashboard', selectedSvg: "dashboardSelected", svg: "dashboard"},
                {name: 'Sites', label: 'Sites', selectedSvg: "siteIconSelected", svg: "siteIcon"},
                {name: 'Companies', label: 'Companies', selectedSvg: "companySelected", svg: "company"},
                {
                    name: 'AdminManagement',
                    label: 'Admin Management',
                    selectedSvg: "profileAdminSelected",
                    svg: "profileAdmin"
                },
                {
                    name: 'DeviceManagement',
                    label: 'Device Management',
                    selectedSvg: "SNManagementSelected",
                    svg: "SNManagement",
                    isPhoto: true,
                },
                {
                    name: 'SNManagement',
                    label: 'SN Management',
                    selectedSvg: "SNManagementSelected",
                    svg: "SNManagement"
                },
                {name: 'Languages', label: 'Languages', selectedSvg: "globalSelected", svg: "global"}
            ]
        } else if (user === "Distributor") {
            return [
                {name: 'Dashboard', label: 'Dashboard', selectedSvg: "dashboardSelected", svg: "dashboard"},
                {name: 'Sites', label: 'Sites', selectedSvg: "siteIconSelected", svg: "siteIcon"},
                {name: 'Companies', label: 'Companies', selectedSvg: "companySelected", svg: "company"},
                {
                    name: 'AdminManagement',
                    label: 'Admin Management',
                    selectedSvg: "profileAdminSelected",
                    svg: "profileAdmin"
                },
                {
                    name: 'DeviceManagement',
                    label: 'Device Management',
                    selectedSvg: "SNManagementSelected",
                    svg: "SNManagement"
                },
            ]
        } else {
            return [
                {
                    name: 'DeviceManagement',
                    label: 'Device Management',
                    selectedSvg: "SNManagementSelected",
                    svg: "SNManagement"
                },
            ]
        }
    }
    const CtManagerRoutes = (user) => {
        let restApi = ""
        if (user === "SuperAdmin" || user === "Admin") {
            restApi = AdminApi
            return [
                {path: `/${rolePath}/Dashboard`, element: <DashboardComp restApi={restApi} rolePath={rolePath}/>},
                {path: `/${rolePath}/Companies`, element: <CompaniesComp restApi={restApi} rolePath={rolePath}/>},
                {
                    path: `/${rolePath}/Companies/AddCompany`,
                    element: <AddNewCompany restApi={restApi} rolePath={rolePath}/>
                },
                {
                    path: `/${rolePath}/Companies/editCompany/:Company_index`,
                    element: <EditCompany restApi={restApi} rolePath={rolePath}/>
                },

                {path: `/${rolePath}/Sites`, element: <SitesComp restApi={restApi} rolePath={rolePath}/>},
                {path: `/${rolePath}/Sites/AddSite`, element: <AddNewSite restApi={restApi} rolePath={rolePath}/>},
                {
                    path: `/${rolePath}/Sites/editSite/:Site_index`,
                    element: <EditSite restApi={restApi} rolePath={rolePath}/>
                },

                {
                    path: `/${rolePath}/AdminManagement`,
                    element: <AdminManagementComp restApi={restApi} rolePath={rolePath}/>
                },
                {
                    path: `/${rolePath}/AdminManagement/AddAdmin`,
                    element: <AddNewUser restApi={restApi} rolePath={rolePath}/>
                },
                {
                    path: `/${rolePath}/AdminManagement/editAdmin/:Admin_index`,
                    element: <EditUser restApi={restApi} rolePath={rolePath} isReadOnly={false}/>
                },

                {
                    path: `/${rolePath}/DeviceManagement`,
                    element: <DeviceManagementComp restApi={restApi} rolePath={rolePath}/>
                }, {
                    path: `/${rolePath}/DeviceManagement/:Device_index`,
                    element: <DeviceTreatmentsMain restApi={restApi} rolePath={rolePath}/>
                },
                {path: `/${rolePath}/SNManagement`, element: <SNManagementComp restApi={restApi} rolePath={rolePath}/>},
                {path: `/${rolePath}/Languages`, element: <LanguageComp restApi={restApi} rolePath={rolePath}/>},
            ]
        } else if (user === "Distributor") {
            restApi = DistributorApi
            return [
                {path: `/${rolePath}/Dashboard`, element: <DashboardComp restApi={restApi} rolePath={rolePath}/>},
                {
                    path: `/${rolePath}/Sites`,
                    element: <SitesComp restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/Companies`,
                    element: <CompaniesComp restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/AdminManagement`,
                    element: <AdminManagementComp restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/Sites/editSite/:Site_index`,
                    element: <EditSite restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/Companies/editCompany/:Company_index`,
                    element: <EditCompany restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/AdminManagement/editAdmin/:Admin_index`,
                    element: <EditUser restApi={restApi} rolePath={rolePath} readOnly={true}/>
                },
                {
                    path: `/${rolePath}/DeviceManagement`,
                    element: <DeviceManagementComp restApi={restApi} rolePath={rolePath}/>
                },
                {
                    path: `/${rolePath}/DeviceManagement/:Device_index`,
                    element: <DeviceTreatmentsMain restApi={restApi} rolePath={rolePath}/>
                },
                {path: `/${rolePath}/SNManagement`, element: <SNManagementComp restApi={restApi} rolePath={rolePath}/>},
            ]
        } else {
            restApi = CallCenterApi

            return [{
                path: `/${rolePath}/DeviceManagement`,
                element: <DeviceManagementComp restApi={restApi} rolePath={rolePath}/>
            }, {
                path: `/${rolePath}/DeviceManagement/:Device_index`,
                element: <DeviceTreatmentsMain restApi={restApi} rolePath={rolePath}/>
            },]
        }


    }

    const handleLogout = () => {
        authenticationService.logout();
        navigate("/login");
    };
    const handleIdle = () => {
        setIsIdle(true);
        handleLogout();
    };

    const isLoginRoutes = pathname === '/login' || pathname === '/' || pathname === '/verificationCode' || pathname === '/forgotPassword' || pathname === '/ResetPassword'

    const userRoutes = CtManagerRoutes(currentUser)

    const NavTypes = getUserRoutes(currentUser)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
            <div
                className={`main w-full h-full bg-white font-Poppins overflow-x-none
            ${!isLoginRoutes ?
                    'bg-gradient-to-b from-[#F8F8F7] to-[#FFFFFF] p-0' : 'bg-[#E9E9EB] flex'}`}>
                {isLoginRoutes &&
                    <img src={VerticaBG}/>
                    /*   <SVGraphics svgname={'backGround'} style={{
                       backgroundPosition: 'center',
                       backgroundSize: 'cover',
                       backgroundColor: '#00A4BD',
                       backgroundRepeat: 'no-repeat'
                   }}/>*/}

                {!isLoginRoutes && currentUser ?
                    <div className="flex w-full h-14 items-center flex-shrink-0 bg-white shadow-md relative z-20">
                        <IdleTimer
                            onIdle={handleIdle}
                            timeout={autoLogoutTime}
                            events={['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove']}
                        />
                        <img src={logo} className={"flex w-[12.875rem] self-center"}/>



                        <SVGraphics svgname={'aboutIcon'} className={"ml-auto mr-[2rem] w-[1.5rem] h-[1.5rem]"}  onClick={handleMenu}/>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>
                                <a href="https://vertica-labs.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                </MenuItem>
                        </Menu>

                    </div> : null
                }

                <div className={"w-full h-auto flex"}>
                    {!isLoginRoutes && currentUser ?
                        <SideNavigationBar
                            logoutUser={() => handleLogout()}
                            rolePath={rolePath}
                            NavTypes={NavTypes}/> : null
                    }

                    <Routes>

                        {currentUser ?
                            userRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.element}/>
                            })
                            : null}
                        <Route exact path="/verificationCode" element={<VerificationCode/>}/>
                        <Route exact path="/forgotPassword" element={<ForgotPassword/>}/>
                        <Route exact path="/ResetPassword" element={<ResetPassword/>}/>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/" element={<Login/>}/>
                    </Routes>


                </div>

                {isLoginRoutes && <OverlayTrigger
                    placement={"top"}
                    delay={{show: 250, hide: 750}}
                    trigger={["hover", "focus"]}
                    // show={true}
                    rootClose
                    overlay={
                        <Tooltip id="tooltip-disabled" className={"versionToolTip"}>
                            <label
                                className={'text-[#52007E] text-sm'}>be-V:{beVersion?.version} </label>
                        </Tooltip>}>
                    <div
                        className={`absolute flex text-[#52007E] text-xs
                    ${!isLoginRoutes ?
                            ' ml-auto ' : ' ml-[115rem] mt-[57.5rem]'}
                        `}>{Config.BuildVersion}

                    </div>

                </OverlayTrigger>}


        </div>
    );
}

export default MainRouters;
