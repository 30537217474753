import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {authenticationService} from "./services/AuthinticationService";
import "./assets/Fonts/fonts.css"

let isAuthenticated = authenticationService.isLoggedin();
if (isAuthenticated) authenticationService.reauthenticate();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    /*  <React.StrictMode>
        <App />
      </React.StrictMode>*/
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
