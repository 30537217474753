import React from "react";
import SVGraphics from "../../../assets/SVGraphics";

import {Table} from "../../../components/table/table";
import utils from "../../../utils/utils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import "../../../style/UserOptions.css"

const DeviceTreatmentHistory = (props) => {

    const ringTableSubHeaders = [
        {
            rows: 8, text: "RING Results", color: "transparent",
            className: `h-[2.313rem] bg-[transparent] rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 6, text: "Time at Level (s)", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 3, text: "RF1", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 3, text: "RF2", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        }, {
            rows: 3, text: "RF3", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },

    ]

    const padTableSubHeaders = [
        {
            rows: 8, text: "RING+PAD Results", color: "transparent",
            className: `h-[2.313rem] bg-[transparent] rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 6, text: "Time at Level (s)", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 3, text: "RF1", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 3, text: "RF2", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 3, text: "RF3", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },

    ]

    const columnsRing = [
        {
            Header: 'Date',
            accessor: 'TreatmentDate',
            headerClassName: 'w-[7.375rem]',
            Cell: (cell) => {
                return (<div>
                    {cell.row.original['TreatmentDate']}
                </div>)
            }
        },
        {
            Header: 'Time',
            accessor: 'TreatmentTime',
            headerClassName: 'w-[4.625rem]',
            Cell: (cell) => {
                return (<div>
                    {cell.row.original['TreatmentTime']}
                </div>)
            }
        },
        {
            Header: 'Trnt. Type',
            accessor: 'TreatmentType',
            headerClassName: 'w-[4.375rem]',
            Cell: (cell) => {
                return (

                    <OverlayTrigger

                        placement={"bottom-start"}
                        delay={{show: 250, hide: 250}}
                        trigger={["hover", "focus"]}
                        offset={0}
                        rootClose
                        // show={true}
                        overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>

                            <div className={""}>{cell.row.original['isCumulative'] ? 'Cumulative' : 'Treatment'}</div>
                        </Tooltip>}>
                        <div
                            className={"cursor-pointer"}
                        >
                            <SVGraphics className={'cursor-pointer w-[1.5rem] h-[1.5rem]'}
                                        fill="none"
                                        svgname={cell.row.original['isCumulative'] ? 'cumulativeTreatment' : 'normalTreatment'}/>
                        </div>


                    </OverlayTrigger>


                )
            }
        },
        {
            Header: 'Energy Mode',
            accessor: 'Energy',
            headerClassName: 'w-[6.563rem]',
            Cell: (cell) => {
                return (<div>
                    {cell.row.original['patientTreatment']['Energy']}
                </div>)
            }
        },
        {
            Header: 'Quality',
            additionalText: ' (%)',
            accessor: 'QualityFactor_R',
            headerClassName: 'max-w-[5.75rem]'
        },
        {
            Header: 'Trnt. Time',
            accessor: 'sessionRingTimerSeconds',
            additionalText: '(s)',
            headerClassName: 'max-w-[6.25rem]',
        },
        {
            Header: 'Press. Time',
            accessor: 'totalTimePressingButton_R',
            additionalText: '(s)',
            headerClassName: 'w-[6.25]',
        },
        {
            Header: 'Battery Level',
            accessor: 'calcBatteryPrecent_R',
            additionalText: '(%)',
            headerClassName: 'w-[6.875rem]',
        },
        {
            Header: '1',
            accessor: 'timeAtLevel1_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },


        {
            Header: '2',
            accessor: 'timeAtLevel2_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: '3',
            accessor: 'timeAtLevel3_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '4',
            accessor: 'timeAtLevel4_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '5',
            accessor: 'timeAtLevel5_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '6',
            accessor: 'timeAtLevel6_R',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },

        {
            Header: 'T',
            accessor: 'totalTimeRF1_R',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF1_R',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF1_R',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        },


        {
            Header: 'T',
            accessor: 'totalTimeRF2_R',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF2_R',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF2_R',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        }, {
            Header: 'T',
            accessor: 'totalTimeRF3_R',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF3_R',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF3_R',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        },


    ]

    const columnsRingPad = [
        {
            Header: 'Date',
            accessor: 'Date',
            headerClassName: 'w-[7.375rem]',
            Cell: (cell) => {
                return (<div>
                    {utils.getFormattedDate(cell.row.original['createdAt'])}
                </div>)
            }
        },
        {
            Header: 'Time',
            accessor: 'Time',
            headerClassName: 'w-[4.625rem]',
            Cell: (cell) => {
                return (<div>
                    {utils.getTimeFromDate(cell.row.original['createdAt'])}
                </div>)
            }
        },
        {
            Header: 'Trnt. Type',
            accessor: 'TreatmentType',
            headerClassName: 'w-[4.375rem]',
            Cell: (cell) => {
                return (
                    <OverlayTrigger

                        placement={"bottom-start"}
                        delay={{show: 250, hide: 250}}
                        trigger={["hover", "focus"]}
                        offset={0}
                        rootClose
                        // show={true}
                        overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>

                            <div className={""}>{cell.row.original['isCumulative'] ? 'Cumulative' : 'Treatment'}</div>
                        </Tooltip>}>
                        <div
                            className={"cursor-pointer"}
                        >
                            <SVGraphics className={'cursor-pointer w-[1.5rem] h-[1.5rem]'}
                                        fill="none"
                                        svgname={cell.row.original['isCumulative'] ? 'cumulativeTreatment' : 'normalTreatment'}/>
                        </div>


                    </OverlayTrigger>
                )
            }
        },
        {
            Header: 'Energy Mode',
            accessor: 'Energy Mode',
            headerClassName: 'w-[6.563rem]',
            Cell: (cell) => {
                return (<div>
                    {cell.row.original['patientTreatment']['Energy']}
                </div>)
            }
        },
        {
            Header: 'Quality',
            accessor: 'QualityFactor_RP',
            additionalText: ' (%)',
            headerClassName: 'max-w-[5.75rem]'
        },
        {
            Header: 'Trnt. Time',
            accessor: 'sessionRingPadTimerSeconds',
            additionalText: '(s)',
            headerClassName: 'max-w-[6.25rem]',
        },
        {
            Header: 'Press. Time',
            accessor: 'totalTimePressingButton_RP',
            additionalText: '(s)',
            headerClassName: 'w-[6.25]',
        },
        {
            Header: 'Battery Level',
            accessor: 'calcBatteryPrecent_RP',
            additionalText: '(%)',
            headerClassName: 'w-[6.875rem]',
        },
        {
            Header: '1',
            accessor: 'timeAtLevel1_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: '2',
            accessor: 'timeAtLevel2_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: '3',
            accessor: 'timeAtLevel3_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '4',
            accessor: 'timeAtLevel4_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '5',
            accessor: 'timeAtLevel5_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        }, {
            Header: '6',
            accessor: 'timeAtLevel6_RP',
            additionalText: '',
            headerClassName: 'min-w-[3.5rem]',
        },

        {
            Header: 'T',
            accessor: 'totalTimeRF1_RP',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF1_RP',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF1_RP',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        },


        {
            Header: 'T',
            accessor: 'totalTimeRF2_RP',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF2_RP',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF2_RP',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        },

        {
            Header: 'T',
            accessor: 'totalTimeRF3_RP',
            additionalText: '(s)',
            headerClassName: 'min-w-[3.5rem]',

        },
        {
            Header: 'Z',
            accessor: 'avgImpedanceRF3_RP',
            additionalText: '(Ω)',
            headerClassName: 'min-w-[3.5rem]',
        },
        {
            Header: 'T',
            accessor: 'avgTempRF3_RP',
            additionalText: '(°C)',
            headerClassName: 'w-[5.313rem]',
        },


    ]

    return (

        <div>

            <div className={'text-[#52007E] font-Poppins text-2xl font-normal leading-9'}> Treatments History</div>


            <div className={"mt-[1.5rem] max-w-[96.313rem] overflow-x-auto"}>
                <Table data={props.currentRingTreatments} columns={columnsRing} isLoading={false}
                       subHeaders={ringTableSubHeaders}/>
            </div>


            <div className={"mt-[1.5rem] max-w-[96.313rem] overflow-x-auto"}>
                <Table data={props.currentRingPadTreatments} columns={columnsRingPad} isLoading={false}
                       subHeaders={padTableSubHeaders}/>
            </div>
        </div>


    )

}

export default DeviceTreatmentHistory



