import React, { useState} from "react";
import SVGraphics from "../../../assets/SVGraphics";

import utils from "../../../utils/utils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const DeviceProfile = (props) => {

    const [hoveredSvg, setHoveredSvg] = useState(false)



    const getPatientAge = (yearOfBirth) => {
        const currentYear = new Date().getFullYear();
        return currentYear - yearOfBirth;
    }

    const onChangePhone = (value) => {
        props.onPhoneChange(value)
    }



    return (


        <div
            className="mt-[1.875rem] p-[1.5rem] rounded-lg bg-white shadow-custom grid grid-cols-5 gap-x-[2.5rem] gap-y-[2.25rem]">

            <div className={'flex w-[16.875rem]'} key={'External_Serial_Number'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> S/N:</label>
                {props.devicesHistory && props.devicesHistory.length > 0 ?
                    <OverlayTrigger

                        placement={"bottom-start"}
                        delay={{show: 250, hide: 250}}
                        trigger={["hover", "focus"]}
                        offset={0}
                        rootClose
                        // show={true}
                        overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>

                            <>
                                <div className={"text-black font-Poppins text-lg font-bold leading-7 ml-[0.75rem]"}>Previous S/N:</div>
                                {
                                    props.devicesHistory?.map(deviceHistory => {
                                        let changeDate = new Date(deviceHistory['ChangeDate']).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        });
                                        return <div className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}
                                                    key={deviceHistory['index']}>
                                            {deviceHistory.External_Serial_Number} {changeDate}</div>
                                    })
                                }
                            </>

                        </Tooltip>}>
                        <div
                            className={"cursor-pointer text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}
                        >{props.currentDevice['External_Serial_Number']}</div>
                    </OverlayTrigger> :
                    <label
                        className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['External_Serial_Number']} </label>}
            </div>

            <div className={'flex w-[16.875rem]'} key={'SWNo:'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> SW No:</label>
                <label className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['MobileAppVersion']} </label>
            </div>

            <div className={'flex w-[16.875rem]'} key={'FW_Version'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> SW No:</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['FW_Version']} </label>
            </div>

            <div className={'flex w-[16.875rem]'} key={'Status'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> Unlock Date</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {utils.getFormattedDate(props.currentDevice['UnlockDate'])} </label>
            </div>

            <div className={'flex w-[16.875rem]'} key={'Site'}>

                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> Site</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['site']['Site_name']} </label>
            </div>

            <div className={'flex w-[16.875rem]'} key={'IIEF-EFActual'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> IIEF-EF Actual:</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {utils.getFloatNumber(props.currentDevice['patient']['ActualLineIIEF'])} </label>
            </div>
            <div className={'flex w-[16.875rem]'} key={'IIEF-EFBase'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> IIEF-EF Baseline:</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {utils.getFloatNumber(props.currentDevice['patient']['BaseLineIIEF'])} </label>
            </div>
            <div className={'flex w-[16.875rem]'} key={'Nickname'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> Nickname:</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['patient']['name']} </label>
            </div>
            <div className={'flex w-[16.875rem]'} key={'Age'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> Age:</label>
                <label
                    className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {getPatientAge(props.currentDevice['patient']['yearsOfBirth'])} </label>
            </div>
            <div className={'flex w-[16.875rem]'} key={'Phone'}>
                <label className={"text-black font-Poppins text-lg font-bold leading-7"}> Phone:</label>
                {props.editPhone ?
                    <div className={'flex'}>
                        <input
                            key={'Phone_number'}
                            className={'ml-[0.2rem] mt-[-0.5rem] w-[12.438rem] px-[1.5rem] py-[0.75rem] h-[3rem] border rounded-lg border-light-grey bg-white'}
                            value={props.patientPhone}
                            onChange={(event) => onChangePhone(event.target.value)}
                        />
                        <div className={'ml-[0.1rem] mt-[-0.5rem]'}>
                            <SVGraphics
                                fill={'none'}
                                className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                                onClick={() => props.handleEditPhone(false)}
                                svgname={'cancelRow'}/>
                            <SVGraphics
                                fill={'none'}
                                className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                                onClick={() => props.saveEditPhone()}
                                svgname={'saveRow'}/>
                        </div>
                    </div> :
                    <>
                        <label
                            className={"text-black font-Poppins text-lg leading-7 ml-[0.75rem]"}> {props.currentDevice['patient']['phoneNumber']} </label>
                        <SVGraphics
                            className={"w-[1.5rem] h-[1.5rem] cursor-pointer ml-[0.75rem] mt-[0.2rem]"}
                            onClick={() => props.handleEditPhone(true)}
                            onMouseEnter={() => setHoveredSvg(true)}
                            onMouseLeave={() => setHoveredSvg(false)}
                            svgname={hoveredSvg ? 'editHovered' : 'edit'}
                        />
                    </>
                }


            </div>


        </div>
    )

}

export default DeviceProfile



