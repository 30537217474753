import React from "react";
import AlertConfirm from "../modals/Confirm";


const BodyFooter = (props) => {


    const onCancelClick = async () => {
        if (props.showAlert && !props.isReadOnly) {
            let confirm = await AlertConfirm({
                proceedLabel: 'Leave',
                cancelLabel: 'Stay',
                options: {hasSVG: true,svgName: "alert-triangle-yellow"},

            }, "The changes will not be saved", `Are you sure you want to leave?`)

            if(confirm){
                props.onCancelClick()
            }
        } else {
            props.onCancelClick()

        }
    }

    return (
        <div className={'w-full flex ' + props.marginTop}>

            <div
                className={"mt-[0.75rem] mr-[5.063rem] cursor-pointer ml-auto text-right leading-trim tracking-tight text-capitalize font-Poppins text-[#52007E] text-opacity-100 text-lg font-normal underline"}
                onClick={() => onCancelClick()}
            >
                {props.cancelText}
            </div>


            {!props.isReadOnly &&<div
                onClick={() => props.onCreateClick()}
                className={"cursor-pointer w-[14rem] h-[3rem] py-[0.75rem] px-[1.5rem] text-center text-lg font-bold font-Poppins  gap-3 flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                    "text-[#FFF]"}>
                {props.createText}
            </div>}

        </div>
    )
}

export default BodyFooter;
