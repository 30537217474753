import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Table} from "../../components/table/table";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import utils from "../../utils/utils";


const SitesComp = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [dataArr, setDataArr] = useState([{}]);
    const [filtersObj, setFiltersObj] = useState({});
    const [filterInputs, setFilterInputs] = useState([]);

    useEffect(() => {
        let companies = [{}]
        let sites = [{}]
        fetchData(filtersObj).then(p => {
            p.data.forEach((site) => {
                site['EnabledText'] = !!site['Enabled']? 'Active':'Disable'
                site['Language'] = site['language']['Language']

            })

            setData(p)
        })

    }, [])


    const fetchData = async (filters) => {
        return await props.restApi.getAllSites(filters)
    }

    const setData = (p) => {
        setDataArr([...p.data])
        setFilteredData([...p.data])
        setIsLoading(false)
    }



    const columns = [
        {
            Header: 'Site',
            accessor: 'Site_name',
            headerClassName: 'w-[31.875rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/sites/editSite/${cell.row.original['Site_index']}`)}>
                    {cell.row.original?.Site_name}
                </div>)
            }
        },
        {
            Header: 'Site ID',
            accessor: 'Site_ID',
            headerClassName: 'w-[23.875rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/sites/editSite/${cell.row.original['Site_index']}`)}>
                    {cell.row.original?.Site_ID}
                </div>)
            }
        },
        {
            Header: 'Language',
            accessor: 'Language',
            headerClassName: 'w-[23.875rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/sites/editSite/${cell.row.original['Site_index']}`)}>
                    {cell.row.original?.Language}
                </div>)
            }
        },
        {
            Header: 'Status',
            accessor: 'EnabledText',

            Cell: (cell) => {
                let isEnabled = cell.row.original?.Enabled
                let textStyle= isEnabled?'cursor-pointer':'cursor-pointer text-[#999996]'
                return (<div className={textStyle}
                             onClick={() => navigate(`/${props.rolePath}/sites/editSite/${cell.row.original['Site_index']}`)}>
                    {cell.row.original?.EnabledText}
                </div>)
            }
        },

    ]

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = []
        filteredData = dataArr.filter(user =>
            String(user['Site_name']).toLowerCase().indexOf(filterValue) > -1 ||
            String(user['Site_ID']).toLowerCase().indexOf(filterValue) > -1
        )
        setFilteredData(filteredData)
    }

    const onFilterChange = async (name, value) => {
        let filters = {...filtersObj}
        filters[name] = value
        if((name ==="Site" || name ==="Role") && value==="All"){
            delete filters[name]
        }
        setFiltersObj({...filters})
        fetchData(filters).then(p => {
            setData(p)
        })
    }


    const onDownloadClick = () =>{
        let headers = ["Site","Site ID","Language","Status"]
        let dataToAdd = filteredData.map(item=>{
            let enabledText= item['Enabled']?'Active':'Disable'
            return [
                item.Site_name,
                item.Site_ID,
                item['language']['Language'],
                enabledText,
            ]
        })
        utils.generateReport("ohhmed_sites_table",headers,dataToAdd)

    }


    return (
        <div className="w-full px-[3.75rem] py-[3rem]">

            <BodyHeader title={"Sites"} includeButton={true} buttonText={" New Site"}
                        includePlus={true} readOnly={props.readOnly}
                        OnButtonClick={() => navigate(`/${props.rolePath}/Sites/AddSite`)}/>
            <div
                className=" h-fit  font-Poppins rounded-lg ">

                <BodySearchFilter
                                  onSearchChange={(searchText) => onSearchChange(searchText)}
                                  onFilterChange={(name, value) => onFilterChange(name, value)}
                                  onDownloadClick={() => onDownloadClick()}
                                  filtersObj={filtersObj}
                                  hasSearch={true}
                                  hasFilter={false}
                                  hasDownload={true}
                                  filterInputs={filterInputs}
                />

                <div className={"mt-[1.5rem]"}>
                    <Table data={filteredData} columns={columns} isLoading={isLoading}/>
                </div>


            </div>

        </div>
    )
}

export default SitesComp;
