import React from "react";
import SVGraphics from "../../../assets/SVGraphics";


const DeviceGeneralSettings = (props) => {

    let isVibrate = !!props.currentDevice['patient']['patientSetting']['Vibrate']
    let isSound = !!props.currentDevice['patient']['patientSetting']['Sound']
    let isVibrateClass=!isVibrate?" text-[#E0E0D7]":" text-black"
    let isSoundClass= !isSound?" text-[#E0E0D7]":" text-black"
  return (


        <div className={"p-[1.5rem] rounded-[0.625rem] bg-white shadow-custom w-[39rem] h-[9.563rem] ml-[6.5rem]"}>
            <div className={"text-black font-Poppins text-lg font-bold leading-7"}> General </div>

            <div className={'flex mt-[1.5rem]'}>

                <div className={"w-[7.25rem]"}>
                    <div className={"flex"}>
                        <SVGraphics fill={'none'} className={"w-[2.625rem] h-[2.625rem]"} svgname={"EnergyMode"}/>
                        <label className={"mt-[0.5rem] text-black font-Poppins text-lg font-bold leading-6 ml-[0.438rem]"}>
                            {props.currentDevice['patient']['patientSetting']['Energy']}</label>
                    </div>

                    <div className={"w-full text-center text-[#999996] font-Poppins text-sm font-bold "}> Energy mode</div>
                </div>

                <div className={"w-[18rem] ml-[3rem]"}>
                    <div className={"flex"}>
                        <SVGraphics fill={'none'} className={"w-[2.625rem] h-[2.625rem]"} svgname={"OperationMode"}/>
                        <label className={"mt-[0.5rem] text-black font-Poppins text-lg font-bold leading-6 ml-[0.438rem]"}> {
                            props.currentDevice['patient']['patientSetting']['OperationMode']==="ContinuousPress"?"Continuous Press":"Double click"
                        }</label>
                    </div>

                    <div className={"w-full text-center text-[#999996] font-Poppins text-sm font-bold "}> Operation mode</div>
                </div>

                <div className={"w-[11.313rem] "}>
                    <div className={"flex"}>
                        <SVGraphics fill={'none'} className={"w-[2.625rem] h-[2.625rem]"} svgname={"ProgressTimer"}/>

                        <label className={"mt-[0.5rem] font-Poppins text-lg font-bold leading-6 ml-[0.438rem]" +isVibrateClass}> Vibrate</label>
                        <label className={"mt-[0.5rem] font-Poppins text-lg font-bold leading-6 ml-[0.438rem]" + isSoundClass}>/Sound</label>
                    </div>

                    <div className={"w-full text-center text-[#999996] font-Poppins text-sm font-bold "}> Progress timer</div>
                </div>


            </div>
        </div>
    )

}

export default DeviceGeneralSettings



