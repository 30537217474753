import React, {useEffect, useState} from "react";
import SVGraphics from "../../../assets/SVGraphics";
import Files from "react-files";
import AlertConfirm from "../../../components/modals/Confirm";
import {SelectInput} from "../../../components/input/SelectInput";
import {InputCustom} from "../../../components/input/InputCustom";
import Checkbox from "@mui/material/Checkbox";

const LanguageSideBar = (props) => {
    const [entry, setEntry] = useState({});
    const [language, setLanguage] = useState({});
    const [language_index, setLanguage_index] = useState("");
    const [hoveredDownload, setHoveredDownload] = useState(-1);


    const filesToUpload = [
        {text: "Language File", key: "LanguageFile", type: ".txt"},
        {text: "Vertica IFU", key: "IFU", type: ".pdf"},
        {text: "Vertica Plus IFU", key: "VerticaPlusIFU", type: ".pdf"},
        {text: "Vertica QSG", key: "QSG", type: ".pdf"},
        {text: "Privacy Policy", key: "PrivacyPolicy", type: ".pdf"},
        {text: "Terms and Conditions", key: "TermsAndConditions", type: ".pdf"},
        {text: "Warranty", key: "Warranty", type: ".pdf"}]
    const {isSideBarOpen} = props

    useEffect(() => {
        if (props.entryToEdit) {
            console.log("props.entryToEdit",props.entryToEdit)
            let currentEntry = props.entryToEdit
            filesToUpload.map(item => {
                entry[item['key']] = {fileName: currentEntry[item['key']]}
            })
            setLanguage_index(currentEntry.Language_index)
            setLanguage({Language: currentEntry.Language,IsRTL: currentEntry.IsRTL})
            setEntry({...entry})
        }

    }, []);


    const closeSideBar = async () => {

        let confirm = await AlertConfirm({
            proceedLabel: 'Leave',
            cancelLabel: 'Stay',
            options: {hasSVG: true, svgName: "alert-triangle-yellow"},

        }, "The changes will not be saved", `Are you sure you want to leave?`)

        if (confirm) {
            props.closeSideBar()
        }
    }


    const editLanguage = async () => {
        const formData = new FormData();
        const jsonStr = JSON.stringify({Language: language});
        formData.append('body', jsonStr);
        Object.keys(entry).map((key) => {
            if (entry[key].file) {
                formData.append(key, entry[key].file[0]);
            }
        })
        let response = await props.restApi.editLanguage(formData, language_index)
        if (response) {
            props.setIsLoading()
            props.closeSideBar()
        }

    }
    const addLanguage = async () => {
        const formData = new FormData();
        let isError = !language || language === ""
        const jsonStr = JSON.stringify({Language: language});
        formData.append('body', jsonStr);
        filesToUpload.map(async item => {
            if (!entry[item['key']]) {
                isError = true
            } else {
                formData.append(item['key'], entry[item['key']].file[0]);
            }
        })
        if (isError) {
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle-yellow"},

            }, "Please note that all files are required", `Missing files`)
        } else {
            let response = await props.restApi.addLanguage(formData)
            if (response) {
                props.setIsLoading()
                props.closeSideBar()
            }
        }
    }
    const saveInfo = async () => {
        if (props.entryToEdit) {
            await editLanguage()
        } else {
            await addLanguage()
        }

    }

    const handleHoverDelete = (index) => {
        setHoveredDownload(index);
    };

    const handleUnhoverDelete = () => {
        setHoveredDownload(-1);
    };

    const DownloadFile = async (fileName) => {
        if (fileName) {
            let response = await props.restApi.downloadFile(fileName, language_index)
            if (response && response.data) {

                window.open(response.data, '_blank');

               /* const link = document.createElement('a');
                link.href = response.data;
                link.download = '';
                link.target = '_blank';
                link.style.display = 'none';
                document.body.appendChild(link);
                // link.click();
                link.download()
                document.body.removeChild(link);*/

            }
        }
    };


    const addFile = (file, key) => {
        entry[key] = {file: file, fileName: file[0].name}
        setEntry({...entry})
    }

    const labelStyle = "h-[1rem] font-semibold text-[#000] font-Poppins text-[1.125rem] font-medium"
    const inputStyle = "mt-[1.5rem] flex w-[23rem] h-[3rem] px-[1.5rem] py-[0.75rem] justify-between items-center flex-shrink-0 rounded-l-lg border border-[#E9E9EB] bg-[#FEFEFE]"

    const text = props.entryToEdit ? "Edit" : "New"

    console.log("language",language)
    return (
        <div
            className={"fixed z-10 top-0 right-0 bg-opacity-80 "}
            style={{width: isSideBarOpen ? "100%" : "0px"}}>
            <div
                className={"p-[1.5rem] h-[65rem] mt-[3.625rem] bg-[#FEFEFE] shadow-md"}
                style={{
                    width: isSideBarOpen ? "46rem" : "0px",
                    marginTop: "3.625rem",
                    float: "right",
                    maxHeight:'900px',
                    overflowY: 'scroll'
                }}
            >
                <SVGraphics onClick={() => closeSideBar()} className={'w-[1.5rem] h-[1.5rem] cursor-pointer'}
                            svgname={'close'}/>

                <div className={"py-[0.75rem] px-[1.75rem]"}>
                    <label
                        className={"font-Poppins text-[#52007E] leading-trim text-capitalize font-bold text-lg"}> {text} </label>

                    <div className={labelStyle + " mt-[3.688rem]"}> Language</div>

                    <InputCustom
                        containerClassName={"mt-[1.5rem] max-w-[23rem] h-[3rem]"}
                        inputClassName={"w-[23rem] min-h-[3rem] max-w-[23rem] rounded-[0.5rem] bg-[#FEFEFE] "}
                        onChange={(event) => setLanguage({...language,Language:event.target.value})}
                        type={"text"}
                        placeholder={"Language"}
                        name={"Language"}
                        value={language['Language']}
                    />

                    <div className={'flex  mt-[1.5rem]'}>
                        <div className={labelStyle + " mt-[0.3rem]"}> RTL Direction</div>

                        <Checkbox checked={!!language['IsRTL']} onChange={()=>setLanguage({...language,IsRTL:!language['IsRTL']})}/>

                    </div>


                    {/* <SelectInput
                        key={"language"}
                        name={"language"}
                        containerClassName={"mt-[1.5rem] max-w-[23rem] h-[3rem]"}
                        inputClassName={"w-[23rem] min-h-[3rem] max-w-[23rem] rounded-[0.5rem] bg-[#FEFEFE] "}
                        maxHeight={"3rem"}
                        onChange={(event) => setLanguage(event.value)}
                        data={
                        [
                            {value:"English",label:"English"},
                            {value:"Hebrew",label:"Hebrew"}
                        ]}
                        value={language}
                        placeholder={'Select'}
                        selected={language}
                    />*/}


                    {filesToUpload.map((item, index) => {
                        let isHovered = hoveredDownload === index
                        let textColor = isHovered ? 'text-[#52007E]' : 'text-[#999996]'
                        return <div className={"mt-[1.5rem]"} key={item.text}>
                            <div className={labelStyle}>{item.text}</div>
                            <div className={'flex' + ' max-h-[4.375rem]'}>
                                <div className={inputStyle}> {entry[item.key] ? entry[item.key]['fileName'] : ""}</div>
                                <Files
                                    maxFiles={1}
                                    maxFileSize={1000000000}
                                    minFileSize={0}
                                    accepts={[item.type]}
                                    onChange={(file) => addFile(file, item.key)}
                                    clickable>
                                    <div
                                        className={"cursor-pointer py-[0.65rem] mt-[1.5rem] w-[8.313rem] h-[3rem] text-[#52007E] font-medium font-Poppins text-lg text-center gap-12 rounded-r-lg border border-[#52007E] bg-white shadow-[#52007E]"}>
                                        Browse
                                    </div>

                                </Files>

                                <div className={"mt-[1rem] ml-[3rem] cursor-pointer h-fit"}
                                     onClick={() => DownloadFile(entry[item.key]['fileName'])}>

                                    <SVGraphics
                                        onMouseEnter={() => handleHoverDelete(index)}
                                        onMouseLeave={handleUnhoverDelete}
                                        className={'w-[2.25rem] h-[2.25rem] mr-auto ml-auto'}
                                        svgname={isHovered ? 'downloadSelected' : 'download'}
                                        fill={'none'}/>
                                    <label
                                        className={" leading-trim-cap font-Poppins text-[0.625rem] font-light tracking-normal " + textColor}>
                                        Download</label>

                                </div>
                            </div>
                        </div>
                    })}


                    <div className={'w-full flex mt-[3.688rem]'}>

                        <div
                            className={"mt-[0.75rem] mr-[2.75rem] cursor-pointer ml-auto text-right leading-trim tracking-tight text-capitalize font-Poppins text-[#52007E] text-opacity-100 text-lg font-normal underline"}
                            onClick={() => closeSideBar()}
                        >
                            Cancel
                        </div>


                        <div
                            onClick={() => saveInfo()}
                            className={"cursor-pointer w-[10.5rem] h-[3rem] py-[0.75rem] px-[1.5rem] text-center text-lg font-bold font-Poppins  gap-3 flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                                "text-[#FFF]"}>
                            Save
                        </div>

                    </div>

                </div>


            </div>

        </div>
    )

}

export default LanguageSideBar



