import React, {useEffect, useState} from "react";
import SVGraphics from "../../assets/SVGraphics";
import {SelectInput} from "../input/SelectInput";
import {MultiSelect} from "../input/MultiSelect";
import {DateTimeInput} from "../input/DateTimeInput";

const FiltersSideBar = (props) => {
    const [entry, setEntry] = useState({});
    const {isSideBarOpen} = props


    useEffect(() => {

    }, []);


    const closeSideBar = async () => {
        document.body.style.overflow = "auto"
        props.closeSideBar(false)
    }

    const getInputByType =  (input) => {
        if(input.type && input.type==="Date"){
            return <DateTimeInput
                containerClassName={" w-[23rem]"}
                inputClassName={"cursor-pointer w-[23rem] h-[3rem] max-w-[23rem] flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] border border-[#E9E9EB] bg-[#FEFEFE] "}
                name={input.name}
                hasRange={false}
                placeholder={input.label}
                selected={props.filtersObj[input.name]}
                data={input.data}
                onChange={(event) => props.onFilterChange(input.name, event)}
            />
        }else{
            return <MultiSelect
                key={input.name}
                name={input.name}
                containerClassName={" w-[23rem]"}
                inputClassName={"w-[23rem] h-[3rem] max-w-[23rem] flex w-[23rem] h-[3rem] justify-between items-center rounded-[0.5rem] bg-[#FEFEFE] "}
                onChange={(event) => props.onFilterChange(input.name, event.target.value)}
                data={input.data}
                maxHeight={"3rem"}
                value={props.filtersObj[input.name]}
                placeholder={input.label}
            />
        }
    }




    const filtersCategory=props.filtersCategory?props.filtersCategory:[]

    return (
        <div
            className={"fixed z-10 top-0 right-0 bg-opacity-80 "}
            style={{width: isSideBarOpen ? "100%" : "0px"}}>
            <div
                className={"p-[1.5rem] h-fit mt-[3.625rem] bg-[#FEFEFE] shadow-md overflow-y-auto"}
                style={{
                    width: isSideBarOpen ? props.sideBarWidth : "0px",
                    marginTop: "3.625rem",
                    float: "right",
                }}
            >
                <SVGraphics onClick={() => closeSideBar()} className={'w-[1.5rem] h-[1.5rem] cursor-pointer'}
                            svgname={'close'}/>

                <div className={"py-[0.75rem] px-[2.813rem] h-[52rem] overflow-y-auto "}>

                    {filtersCategory.map(item => {

                      return <div className={' mt-[3rem]'}>
                          <label className={"font-Poppins text-[#52007E] text-capitalize font-bold text-lg leading-7"}> {item.label} </label>
                          <div className={'w-full mt-[1.5rem] ' +props.gridStyle}>
                              {item.inputs.map((input) =>{
                                  return getInputByType(input)
                              })}
                          </div>

                      </div>

                    })}










                    <div className={'w-full flex mt-[2rem]'}>
                        <div
                            className={"mt-[0.75rem] mr-[2.75rem] cursor-pointer text-right leading-trim tracking-tight text-capitalize font-Poppins text-[#52007E] text-opacity-100 text-lg font-normal underline"}
                            onClick={() => props.clearFilters()}
                        >
                            Clear All
                        </div>

                        <div
                            onClick={() => props.onApplyClick()}
                            className={"cursor-pointer w-[14rem] h-[3rem] py-[0.75rem] px-[1.5rem] text-center text-lg font-bold font-Poppins  gap-3 flex-shrink-0 rounded-full bg-[#52007E] shadow-black ml-auto " +
                                "text-[#FFF]"}>
                            Apply
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )

}

export default FiltersSideBar



