import React from "react";
import SVGraphics from "../../../assets/SVGraphics";
import utils from "../../../utils/utils";

const DashboardBody = (props) => {


    return (
      <>
          <div className={'flex'}>

              <div className={'flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[3rem] h-[1.338rem]"}  svgname={"deviceDashboard"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{props.dashboardData['TotalDevices']}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Devices</div>
                  </div>
              </div>

              <div className={'ml-[12rem] flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[3rem] h-[3rem]"}  svgname={"unlockDeviceDashboard"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{props.dashboardData['UnlockDevices']}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Unlock devices</div>
                  </div>
              </div>

              <div className={'ml-[12rem] flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[3rem] h-[3rem]"}  svgname={"avgAge"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{utils.getFloatNumber(utils.getAgeFromBirthYear(props.dashboardData["AverageAge"]))}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Average age</div>
                  </div>
              </div>

          </div>
          <div className={'flex mt-[9rem]'}>

              <div className={'flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[3rem] h-[3rem]"}  svgname={"BaseLine"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{utils.getFloatNumber(props.dashboardData['AverageBaseLineIIEF'])}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Baseline IIEF-EF</div>
                  </div>
              </div>

              <div className={'ml-[12rem] flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[2.5rem] h-[3.125rem]"}  svgname={"Actual"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{utils.getFloatNumber(props.dashboardData['AverageActualLineIIEF'])}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Actual IIEF-EF</div>
                  </div>
              </div>

              <div className={'ml-[12rem] flex w-[23rem] p-[1.5rem] items-center flex-shrink-0 rounded-10 bg-white shadow-lg'}>
                  <div className={'flex p-[1.5rem] justify-center items-center rounded-full bg-[#D4EFF1] w-[6rem] h-[6rem]'}>
                      <SVGraphics className={"w-[2rem] h-[3.125rem]"}  svgname={"ErrorDashboard"} fill="none"/>
                  </div>
                  <div className={"ml-[4.25rem]"}>
                      <div className={"text-[#06135A] leading-trim-cap font-Poppins text-[2.5rem] font-bold leading-60"}>{props.dashboardData['TotalDevicesErrors']}</div>
                      <div className={"text-[#72777B] leading-trim-cap font-Poppins text-[1.125rem] font-normal leading-27"}>Errors</div>
                  </div>
              </div>


          </div>
      </>
    )

}

export default DashboardBody



