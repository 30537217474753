import React, {useEffect, useState} from "react";
import {Table} from "../../components/table/table";
import BodyHeader from "../../components/customComp/BodyHeader";
import LanguageSideBar from "./language/LanguageSideBar";
import SVGraphics from "../../assets/SVGraphics";
import AlertConfirm from "../../components/modals/Confirm";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";


const LanguageComp = (props) => {
    const [filteredData, setFilteredData] = useState([]);
    const [entry, setEntry] = useState([{}]);
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState(null);
    const [isLoading,setIsLoading]= useState(false);
    const [hoveredDelete, setHoveredDelete] = useState(-1);
    const [hoverEdit, setHoveredEdit] = useState(-1);


    useEffect(() => {
        getAllLanguages({}).then(p => {
            setData(p)
        })

    }, [isLoading,isSideBarOpen])

    const openSideBar = (entryToEdit) => {
        if(entryToEdit){
            setEntryToEdit({...entryToEdit})
        }
        document.body.style.overflow = "hidden"
        setIsSideBarOpen(true)
    }

    const closeSideBar = () => {
        document.body.style.overflow = "auto"
        setIsSideBarOpen(false)
        setEntryToEdit(null)
    }

    const getAllLanguages = async () => {
        return await props.restApi.getAllLanguages()
    }

    const setData = (p) => {
        setEntry([...p.data])
        setFilteredData([...p.data])
    }

    const handleHoverDelete = (index) => {
      setHoveredDelete(index);
    };

    const handleUnhoverDelete = () => {
        setHoveredDelete(-1);
    };
    const handleHoverEdit = (index) => {
      setHoveredEdit(index);
    };

    const handleUnhoverEdit = () => {
        setHoveredEdit(-1);
    };


    const columns = [
        {
            Header: 'Language',
            accessor: 'Language',
            headerClassName: 'w-[70rem]',
        },
        {
            Header: 'Edit',
            accessor: '',
            headerClassName: 'w-[11.063rem]',
            Cell: (cell) => {
                return (
                    <SVGraphics
                        onMouseEnter={()=>handleHoverEdit(cell.row.index)}
                        onMouseLeave={handleUnhoverEdit}
                        className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                        onClick={() => openSideBar(cell.row.original)}
                        svgname={hoverEdit ===cell.row.index?'editHovered':'edit'}/>)
            }
        },
        {
            Header: 'Delete',
            headerClassName: 'w-[11.063rem]',
            accessor: '',
            Cell: (cell) => {
                return (
                    <SVGraphics
                        onMouseEnter={()=>handleHoverDelete(cell.row.index)}
                        onMouseLeave={handleUnhoverDelete}
                        className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                        onClick={() => deleteLanguage(cell.row.original)}
                        svgname={hoveredDelete ===cell.row.index ?'deleteIconHovered':'deleteIcon'}/>
                )
            }
        },
    ]

    const deleteLanguage =async (row) => {
        let index = row['Language_index']
        let confirm = await AlertConfirm({
            proceedLabel: 'Delete',
            cancelLabel: 'Cancel',
            options: {hasSVG: true, svgName: "alert-triangle"},

        }, "The action can't be undone", `Are you sure you want to delete?`)
        if(confirm){
            let response = await props.restApi.deleteLanguage(index)
            if(response && response.data){
                setIsLoading(!isLoading)
            }else{
                await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: {hasSVG: true, svgName: "alert-triangle"},
                }, "Language is associated to site", `Cant delete Language`)
            }

        }
    }
    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = entry.filter(item =>
            String(item['Language']).toLowerCase().indexOf(filterValue) > -1
        )
        setFilteredData(filteredData)
    }




    return (
        <div className="w-full px-[3.75rem] py-[3rem]">

            <BodyHeader title={"Language"} includeButton={true} includePlus={true} buttonText={" New Language"}
                        OnButtonClick={()=>openSideBar()} readOnly={props.readOnly}/>
            <div
                className="h-fit  font-Poppins rounded-lg ">

                <BodySearchFilter
                           onSearchChange={(searchText) => onSearchChange(searchText)}
                           hasSearch={true}
                           hasFilter={false}
                           filterInputs={[]}
                />

                <div className={"mt-[1.5rem]"}>
                    <Table data={filteredData} columns={columns} isLoading={false}/>
                </div>


            </div>

            {isSideBarOpen && <LanguageSideBar
                entryToEdit={entryToEdit}
                restApi={props.restApi}
                setIsLoading ={()=>setIsLoading(!isLoading)}
                closeSideBar={() => closeSideBar()}
                isSideBarOpen={isSideBarOpen}
            />}

        </div>
    )
}

export default LanguageComp;
