import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from 'react-bootstrap';
import { confirmable, createConfirmation } from "react-confirm";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Constants from "../../utils/constants";
import SVGraphics from "../../assets/SVGraphics";

const ConfirmComposed = ({
                             proceedLabel,
                             cancelLabel,
                             title,
                             message,
                             extraMessage,
                             extraExtraMessage,
                             show,
                             proceed,
                             warningRed,
                             red,
                             hasSVG,
                             titleColorBlack,
                             svgName,
                             alertSize,
                             enableEscape = true,
                             t
                         }) => {
    useEffect(() => {
        document.getElementById('modal-ok-button').focus();
    }, []);

    const messageMargin= title!==''?'mt-[1.5rem] ':''
    return (
        <div id="modalError" tabIndex="-1" aria-hidden="true" className="backdrop-blur-sm fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center">
            <div className="w-2/5 h-auto max-w-xl md:h-auto self-center">
                <Modal id={'Confirm-modal-body'} centered={true}
                       show={show} onHide={() => proceed(false)} keyboard={enableEscape} dialogClassName={'app-modal'}>
                    <Modal.Body className={'w-[35rem] h-auto min-h-[14rem] px-[3rem] py-[1.5rem] ' + alertSize}>
                        {hasSVG &&
                            <SVGraphics className={'w-[1.5rem] h-[1.5rem] ml-auto mr-auto'} svgname={svgName} fill={"#FEFEFE"}/>}
                        <div className={`mt-[1.5rem] font-Poppins text-lg
                        font-bold leading-[1.75rem] text-center leading-trim-both text-capitalize ${titleColorBlack? 'text-[#000] ' :'text-[#52007E] '} leading-trim-both text-capitalize`}>
                            {title}</div>
                        <div className={messageMargin+' font-Poppins text-lg leading-[1.75rem] text-center leading-trim-both text-capitalize text-black'}>
                            {message}
                        </div>
                        <div className={cancelLabel !== 'none'?'py-[1.5rem] pl-[2.5] flex justify-between':'py-[2rem]'}>
                            <button id={'modal-ok-button'}
                                    // className="border-solid border-[#52007E] rounded-full bg-[#FEFEFE] flex w-[12rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-[#52007E] text-bold"
                                    className={cancelLabel !== 'none'?
                                        "w-[14rem] h-[3rem] justify-center items-center gap-12 flex-shrink-0 rounded-full border border-[#52007E] bg-white shadow-black font-bold text-[#52007E] font-Poppins text-lg"
                                        :"w-[14rem] h-[3rem] justify-center items-center rounded-full bg-[#52007E] shadow-black font-bold text-white font-Poppins text-lg"}
                                    onClick={() => proceed(true)} onKeyPress={event => {
                                if (event.key === 'Enter' && cancelLabel === 'none') {
                                    proceed(true);
                                }
                            }}>
                                {proceedLabel}
                            </button>
                            {(cancelLabel !== 'none') ?
                                <button id={'modal-ok-button'}
                                        className=" rounded-full bg-[#52007E]  flex w-[14rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white font-bold font-Poppins text-lg"
                                        onClick={() => proceed(false)}>
                                {cancelLabel}
                            </button> : <div />}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

const Confirm = compose(
    withTranslation()
)(ConfirmComposed);

Confirm.propTypes = {
    proceedLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    extraMessage: PropTypes.string,
    extraExtraMessage: PropTypes.string,
    show: PropTypes.bool,
    warningRed: PropTypes.bool,
    red: PropTypes.bool,
    proceed: PropTypes.func,
    enableEscape: PropTypes.bool
};

const AlertConfirm = function AlertConfirm(
    { proceedLabel: proceedLabel = "Yes", cancelLabel: cancelLabel = "No", options: options = {} }, message, title) {
    return createConfirmation(confirmable(Confirm))({
        message,
        proceedLabel,
        cancelLabel,
        title,
        ...options
    });
}

export default AlertConfirm;
