import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Table} from "../../components/table/table";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import utils from "../../utils/utils";
import {TableRowOptions} from "../../components/table/TableRowOptions";


const CompaniesComp = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [dataArr, setDataArr] = useState([{}]);
    const [filtersObj, setFiltersObj] = useState({});
    const [filterInputs, setFilterInputs] = useState([
        {key: "Company_name", name: "Company_name", placeholder: "Company", type: "Select", data: []},
        {key: "Site", name: "Site", placeholder: "Site", type: "Select", data: []}
    ]);
    useEffect(() => {
        let companies = []
        let sites = []

        fetchData(filtersObj).then(p => {

            p.data.forEach((company) => {
                companies.push({value: company.Company_name, label: company.Company_name})
                let companySites = company['sites']
                let selectedSites = companySites.map(item => {
                    return item['Site_name']
                })
                company['Sites_text'] = selectedSites.join(',')
            })
            getAllSites().then(r => {
                let data = r.data
                data.map(item =>
                    sites.push({value: item['Site_index'], label: item['Site_name']})
                )
                filterInputs[0].data = companies
                filterInputs[1].data = sites
                setFilterInputs([...filterInputs])
                setData(p)

            })

        })

    }, [])


    const fetchData = async (filters) => {
        return await props.restApi.getAllCompanies(filters)
    }

    const getAllSites = async () => {
        return await props.restApi.getAllSites({})
    }


    const setData = (p) => {
        setDataArr([...p.data])
        setFilteredData([...p.data])
        setIsLoading(false)
    }


    const columns = [

        {
            Header: 'Company',
            accessor: 'Company_name',
            headerClassName: ' w-[19.125rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/companies/editCompany/${cell.row.original['Company_index']}`)}>
                    {cell.row.original?.Company_name}
                </div>)
            }
        },
        {
            Header: 'Company ID',
            accessor: 'Company_ID',
            headerClassName: ' w-[19.125rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/companies/editCompany/${cell.row.original['Company_index']}`)}>
                    {cell.row.original?.Company_ID}
                </div>)
            }
        },
        {
            Header: 'Site',
            accessor: 'Sites_text',
            headerClassName: ' w-[19.125rem]',
            Cell: (cell) => {
                let sites = cell.row.original['sites']
                let selectedSites = sites.map(item => {
                    return item['Site_name']
                })
                return (
                    <TableRowOptions
                        onButtonClick={() => navigate(`/${props.rolePath}/companies/editCompany/${cell.row.original['Company_index']}`, {state: cell.row.original})}
                        options={selectedSites}/>
                )
            }
        },
        {
            Header: 'Devices',
            accessor: 'NumOfDevices',
            headerClassName: ' w-[15.125rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/companies/editCompany/${cell.row.original['Company_index']}`)}>
                    {cell.row.original['devices']
                        ? cell.row.original['devices'].length : 0}
                </div>)
            }
        },
        {
            Header: 'Unlock Devices',
            accessor: 'NumOfUnlockDevices',
            headerClassName: ' w-[15.125rem]',
            Cell: (cell) => {
                let unlockCount=0
                let devicesArr= cell.row.original['devices'] ? cell.row.original['devices'] : []
                devicesArr.map(item=>{
                    if(item['Status']){
                        unlockCount=unlockCount+1
                    }
                })
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/companies/editCompany/${cell.row.original['Company_index']}`)}>
                    {unlockCount}
                </div>)
            }
        },

    ]

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = dataArr.filter(user => {
            let sites = user['sites']
            for (const siteElement of sites) {
                if(String(siteElement['Site_name']).toLowerCase().indexOf(filterValue) > -1){
                    return true
                }
            }
               return String(user['Company_name']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Site']).toLowerCase().indexOf(filterValue) > -1
            }
        )
        setFilteredData(filteredData)
    }

    const onFilterChange = async (name, value) => {
        let filters = {...filtersObj}
        filters[name] = value
        if(((name ==="Site" || name ==="Company_name") && value.indexOf("All")>=0 )||value.length===0){
            delete filters[name]
        }
        setFiltersObj({...filters})
        fetchData(filters).then(p => {
            setData(p)
        })
    }

    const clearFilters = async () => {
        let filters = {}
        setFiltersObj({...filters})
        fetchData(filters).then(p => {
            setData(p)
        })
    }


    const onDownloadClick = () => {
        let headers = ["Company","Company_ID", "Site", "Devices", "Unlock Devices"]
        let dataToAdd = filteredData.map(item => {
            let sites =item['sites']?item['sites']:[]
            let sitesText = sites.map(item=> {
                return item['Site_name']
            })
            let unlockCount=0
            let devicesArr= item['devices'] ? item['devices'] : []
            devicesArr.map(item => {
                if (item['Status']) {
                    unlockCount = unlockCount + 1
                }
            })

            return [
                item.Company_name,
                item.Company_ID,
                sitesText.join(','),
                devicesArr.length,
                unlockCount,
            ]
        })
        utils.generateReport("ohhmed_companies_table", headers, dataToAdd)
    }



    return (
        <div className="w-full px-[3.75rem] py-[3rem]">

            <BodyHeader title={"Companies"} includeButton={true} buttonText={" New Company"}
                        includePlus={true} readOnly={props.readOnly}
                        OnButtonClick={() => navigate(`/${props.rolePath}/Companies/AddCompany`)}/>
            <div
                className=" h-fit  font-Poppins rounded-lg ">

                <BodySearchFilter
                                  onSearchChange={(searchText) => onSearchChange(searchText)}
                                  onFilterChange={(name, value) => onFilterChange(name, value)}
                                  onDownloadClick={() => onDownloadClick()}
                                  filtersObj={filtersObj}
                                  hasSearch={true}
                                  hasFilter={true}
                                  hasDownload={true}
                                  clearFilters={() => clearFilters()}
                                  hasClearInRow={true}
                                  filterInputs={filterInputs}
                />

                <div className={"mt-[1.5rem]"}>
                    <Table data={filteredData} columns={columns} isLoading={isLoading}/>
                </div>


            </div>

        </div>
    )
}

export default CompaniesComp;
