import React, {useEffect, useRef, useState} from "react";
import {TextInput} from "../input/TextInput";
import SVGraphics from "../../assets/SVGraphics";
import {useNavigate} from "react-router-dom";
import {authenticationService} from "../../services/AuthinticationService";
import AppLogout from "../../view/auth/AppLogout ";

import device_management from "../../assets/device_management.svg"
import device_managementSelected from "../../assets/device_managementSelected.svg"


export function SideNavigationBar(props) {
    const {
        logoutUser
    } = props
    const [navType, setNavType] = useState('Dashboard');
    const [currentUser, setCurrentUser] = useState(null);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const navigate = useNavigate()
    const user = sessionStorage.getItem('loggedInUser')

    useEffect(() => {

        authenticationService.currentUser.subscribe(
            currentUser => setCurrentUser(currentUser)
        );
        const pathname = window.location.pathname
        setNavHandler(pathname)

    }, [])

    window.addEventListener('popstate', function(event) {
        const pathname = window.location.pathname
        setNavHandler(pathname)
    });

    const setNavHandler=(pathname)=>{
        if (pathname.includes("Dashboard")) {
            setNavType("Dashboard")
        } else if (pathname.includes("Companies")) {
            setNavType("Companies")
        } else if (pathname.includes("Sites")) {
            setNavType("Sites")
        } else if (pathname.includes("AdminManagement")) {
            setNavType("AdminManagement")
        } else if (pathname.includes("DeviceManagement")) {
            setNavType("DeviceManagement")
        }else if (pathname.includes("SNManagement")) {
            setNavType("SNManagement")
        }else if (pathname.includes("Languages")) {
            setNavType("Languages")
        }
    }



    const types = props.NavTypes

    const setType = (type) => {
        switch (type) {
            case 'Dashboard':
                setNavType(type)
                navigate(`/${props.rolePath}/Dashboard`, {state: {NavType: 1}})
                break;
            case 'Sites':
                setNavType(type)
                navigate(`/${props.rolePath}/Sites`, {state: {NavType: 7}})
                break;
            case 'Companies':
                setNavType(type)
                navigate(`/${props.rolePath}/Companies`, {state: {NavType: 2}})
                break;
            case 'AdminManagement':
                setNavType(type)
                navigate(`/${props.rolePath}/AdminManagement`, {state: {NavType: 3}})
                break;
            case 'DeviceManagement':
                setNavType(type)
                navigate(`/${props.rolePath}/DeviceManagement`, {state: {NavType: 4}})
                break;
            case 'SNManagement':
                setNavType(type)
                navigate(`/${props.rolePath}/SNManagement`, {state: {NavType: 5}})
                break;
            case 'Languages':
                setNavType(type)
                navigate(`/${props.rolePath}/Languages`, {state: {NavType: 6}})
                break;
            default:
                setNavType(type)
                navigate(`/${props.rolePath}/Dashboard`, {state: {NavType: 1}})
                break;
        }
    }

    return (
        <AppLogout>

            <div
                className="px-[1.5rem] bg-white shadow-lg min-w-[15.688rem] h-fit">

                <div className={"hidden md:block md:w-auto"} id="navbar-default">
                    <ul className="flex flex-col pt-1 min-h-[668px]">
                        {types.map((type,index) => {
                            let navTypeSelected = navType === type.name
                            let isPhoto = type.isPhoto
                            let textColor = navTypeSelected ? "text-[#06135A] font-bold" : "text-[#999996]"
                            return <li
                                key={type.name}
                                className={`${index===0?"mt-[4rem]":"mt-[4.5rem]"}`}
                                onClick={() => setType(type.name)}>
                                <div
                                    className={` cursor-pointer relative max-w-[15.25rem] flex items-center text-lg ${navTypeSelected ? "text-[#4C488F] bg-[#FEFEFE] " : "text-[#FEFEFE]"}`}>
                                    {!isPhoto && <SVGraphics
                                        className="cursor-pointer mr-[0.75rem] h-[1.5rem] m-w-[1.375rem] w-[1.375rem]"
                                        svgname={navTypeSelected ? type.selectedSvg : type.svg} fill="none"/>}
                                    {isPhoto && <img className="cursor-pointer mr-[0.75rem] h-[1.5rem] m-w-[1.375rem] w-[1.375rem]"
                                                     src={navTypeSelected ?device_managementSelected:device_management}/>}
                                    <TextInput text={type.label}
                                               className={"w-[10.438rem] cursor-pointer text-dark-grey text-cap font-Poppins-bold text-base leading-[1.5rem] " + textColor}/>
                                </div>
                            </li>
                        })}
                    </ul>

                </div>
                <div className={'flex items-center mt-[10.7rem] mb-[2rem]'} onClick={() => logoutUser()}>
                    <SVGraphics className="cursor-pointer mr-[0.75rem] h-[1.5rem] m-w-[1.375rem] w-[1.375rem]" svgname={'logout'}/>
                    <TextInput text={'Logout'}
                               className="cursor-pointer text-[#999996] leading-trim-both text-edge-cap font-Poppins text-base font-light leading-7"/>
                </div>
            </div>

        </AppLogout>
    )
}
