import React from "react";
import SVGraphics from "../../../assets/SVGraphics";

import {Table} from "../../../components/table/table";
import utils from "../../../utils/utils";

const DeviceSelfTestHistory = (props) => {


    const tableSubHeaders = [
        {rows: 4, text: "Self-Test Results", color: "transparent",
            className: `h-[2.313rem] bg-[transparent] rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`},
        {rows: 5, text: "RF1", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`},
        {rows: 5, text: "RF2", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-r border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`},
        {rows: 5, text: "RF3", color: "#FFF",
            className: `h-[2.313rem] bg-[#FFF] border-b border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`},

    ]



    const columns = [
        {
            Header: 'Date',
            accessor: 'Date',
            headerClassName: 'w-[7.375rem]',
            Cell: (cell) => {
                return (<div>
                    {utils.getFormattedDate(cell.row.original['createdAt'])}
                </div>)
            }
        }, {
            Header: 'Time',
            accessor: 'Time',
            headerClassName: 'w-[4.625rem]',
            Cell: (cell) => {
                return (<div>
                    {utils.getTimeFromDate(cell.row.original['createdAt'])}
                </div>)
            }
        }, {
            Header: 'Results',
            accessor: 'Results',
            headerClassName: '',
            Cell: (cell) => {
                //ResultOk //ResultNotOk
                let hasError = utils.checkIfSelfTestHasErrors(cell.row.original)
                return (

                    <SVGraphics className={'w-[2.25rem] h-[2.25rem]'}
                                      fill="none"
                                      svgname={hasError?'ResultNotOk':'ResultOk'}/>)
            }
        },
        {
            Header: 'System',
            accessor: 'System',
            headerClassName: 'w-[6.563rem]',
            Cell: (cell) => {
                let color = cell.row.original['System']?"text-[#E0345D]":''
                return (<div className={color}>
                    {cell.row.original['System']?"X":"V"}
                </div>)
            }
        },
        {
            Header: 'Z',
            accessor: 'ImpedanceValueRF1',
            additionalText:'(Ω)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['ImpedanceRF1']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['ImpedanceValueRF1']}
                </div>)
            }
        },
        {
            Header: 'V',
            accessor: 'VoltageValueRF1',
            additionalText:'(V)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['VoltageRF1']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['VoltageValueRF1']}
                </div>)
            }
        },
        {
            Header: 'I',
            accessor: 'CurrentValueRF1',
            additionalText:'(mA)',
            headerClassName: 'w-[5.125rem]',
            Cell: (cell) => {
                let color = cell.row.original['CurrentRF1']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['CurrentValueRF1']}
                </div>)
            }
        },
        {
            Header: 'TH #1',
            accessor: 'TH #1',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_1']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_1']?"X":"V"}
                </div>)
            }
        },
        {
            Header: 'TH #4',
            accessor: 'TH #4',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_4']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_4']?"X":"V"}
                </div>)

            }
        },


        {
            Header: 'Z',
            accessor: 'ImpedanceValueRF2',
            additionalText:'(Ω)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['ImpedanceRF2']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['ImpedanceValueRF2']}
                </div>)
            }
        },
        {
            Header: 'V',
            accessor: 'VoltageValueRF2',
            additionalText:'(V)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['VoltageRF2']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['VoltageValueRF2']}
                </div>)
            }
        },
        {
            Header: 'I',
            accessor: 'CurrentValueRF2',
            additionalText:'(mA)',
            headerClassName: 'w-[5.125rem]',
            Cell: (cell) => {
                let color = cell.row.original['CurrentRF2']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['CurrentValueRF2']}
                </div>)
            }
        },
        {
            Header: 'TH #2',
            accessor: 'd',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_2']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_2']?"X":"V"}
                </div>)
            }
        },
        {
            Header: 'TH #5',
            accessor: 'e',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_5']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_5']?"X":"V"}
                </div>)
            }
        },


        {
            Header: 'Z',
            accessor: 'ImpedanceValueRF3',
            additionalText:'(Ω)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['ImpedanceRF3']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['ImpedanceValueRF3']}
                </div>)
            }
        },
        {
            Header: 'V',
            accessor: 'VoltageValueRF3',
            additionalText:'(V)',
            headerClassName: 'max-w-[3.8rem]',
            Cell: (cell) => {
                let color = cell.row.original['VoltageRF3']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['VoltageValueRF3']}
                </div>)
            }
        },
        {
            Header: 'I',
            accessor: 'CurrentValueRF3',
            additionalText:'(mA)',
            headerClassName: 'w-[5.125rem]',
            Cell: (cell) => {
                let color = cell.row.original['CurrentRF3']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['CurrentValueRF3']}
                </div>)
            }
        },
        {
            Header: 'TH #3',
            accessor: 'x',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_3']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_3']?"X":"V"}
                </div>)
            }
        },
        {
            Header: 'TH #6',
            accessor: 'y',
            headerClassName: 'w-[5.5rem]',
            Cell: (cell) => {
                let color = cell.row.original['TH_6']?"text-[#E0345D]":''

                return (<div className={color}>
                    {cell.row.original['TH_6']?"X":"V"}
                </div>)
            }
        },


    ]


    return (
        <div>

            <div className={"flex"}>

                <div className={'text-[#52007E] font-Poppins text-2xl font-normal leading-9'}> Self-Test History</div>

                <div className={'flex ml-auto'}>
                {/*    <div className={`ml-auto`}
                         onClick={() => console.log("TODO")}>
                        <SVGraphics className={'ml-[0.5rem] w-[2.25rem] h-[2.25rem] cursor-pointer'}
                                    svgname={'downloadSelected'} fill={'none'}/>
                        <label
                            className={"w-[5.563rem] text-[#52007E] leading-trim-cap align-middle justify-center font-Poppins text-xs font-light tracking-normal"}>
                            {props.downloadText ? props.downloadText : "Download"}</label>
                    </div>*/}
                </div>

            </div>
            <div className={"mt-[1.5rem]"}>
                <Table data={props.entry} columns={columns} isLoading={false} subHeaders={tableSubHeaders}/>
            </div>
        </div>


    )

}

export default DeviceSelfTestHistory



