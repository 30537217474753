import React from "react";
import {TextInput} from "./TextInput";

import {InputLabel, OutlinedInput} from "@mui/material";
export function PasswordInput(props) {
    const inputStyle = {
        maxHeight: '0.5rem',

    };
    return (
        <div className={props.containerClassName}>
            <TextInput text={props.label} className={props.labelClassName} />
            <OutlinedInput
                error={props.isError}
                className={props.inputClassName}
                type={props.type}
                inputProps={{ style: inputStyle }}
                // error={isErr}
                onChange={props.onChange}
                // helperText={isErr ? errMessage : ''}
                placeholder={props.placeholder}
                // required={required}
                name={props.name}
                autoFocus={false}
                multiline={false}
                notched={false}
                endAdornment={props.endAdornment}
            />
        </div>
    )
}
