import React from "react";
import SVGraphics from "../../assets/SVGraphics";


export function FormCard(props) {


    return(

        <div className={`w-full ${props.cardMargin}`}>
            {props.hasNewLine && <div className={`mt-[2.125rem] mb-[2.125rem] w-full h-[0.0625rem] rounded-lg bg-[#E9E9EB]`} id={"line"}/>}

            {props.cardTitle && props.cardTitle!=="" && <div className={'flex'}>
                <div
                    className={"text-[#4C488F] leading-trim-both text-edge-cap font-Poppins text-2xl font-normal leading-[1rem]"}>
                    {props.cardTitle}</div>
            </div>}

            <div className={`w-full grid grid-cols-3 gap-x-[1.5rem] lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 sm:grid-cols-1 max-sm:grid-cols-2`}>
                {props.data}
            </div>

        </div>
    )
}