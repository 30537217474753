import React from "react";
import {components} from 'react-select';
import SVGraphics from "../../assets/SVGraphics";
import CreatableSelect from 'react-select/creatable';

export function CreateAbleSelectInput(props) {
    let value = props.data?.find(option => option.value === props.value)
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };

    const borderColor= props.value ?"1px solid #00A4BD":"1px solid #E9E9EB"
    const hasLabel = props.labelComp?props.labelComp:<div>
        <span className={'font-Poppins text-base '}>{props.label}</span>
    </div>

    return (
        <div className={props.containerClassName}>
            {hasLabel}
            <CreatableSelect
                className={`${props.inputClassName}`}
                classNamePrefix="select"
                onChange={props.onChange}
                placeholder={props.placeholder}
                isDisabled ={props.disabled}
                disabled={props.disabled}
                selected={props.selected}
                value={value?value:null}
                components={{ NoOptionsMessage }}
                options={props.data}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        width: "100%",
                        height: "100%",
                        maxHeight: "38px",
                        minHeight: "30px",
                        color: "#E9E9EB",
                        textTransform: "capitalize",
                        boxShadow: 'unset',
                        fontSize: "16px",
                        outline: "unset",
                        paddingLeft:'1rem',
                        borderRadius:"0.5rem",
                    })
                }}
            />
            {props.isIcon &&
                <SVGraphics className={'select-input-icon'} style={{position:'relative'}} svgname={props.svgName}/>}
        </div>

    )
}
