import {authenticationService} from "../AuthinticationService";
import restApi from "./RestApi";

const getCurrentUser=()=>{
    return authenticationService.currentUserValue;
}

const AdminApi = {


    getAllCompanies: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/companies/getAll`,
            filtersArr
        );
    },
    getAllLanguages: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/language/getAll`,
            filtersArr
        );
    },
    getAllSites: async () => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/sites/getAll`
        );
    },

    getAllPossibleSites: async () => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/sites/getAllPossibleSites`
        );
    },

    ExportFile: async (entry) => {
        return await restApi.postFiles(
            `admin/${getCurrentUser().profile['UserID']}/sn_management/export`,
            entry
        );
    },

    editDeviceSerialNumber: async (body) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/sn_management/edit`,
            body
        );
    },

    addLanguage: async (entry) => {
        return await restApi.postFiles(
            `admin/${getCurrentUser().profile['UserID']}/language/add`,
            entry
        );
    },

    editLanguage: async (entry,index) => {
        return await restApi.postFiles(
            `admin/${getCurrentUser().profile['UserID']}/language/${index}`,
            entry
        );
    },

    downloadFile: async (fileName,index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/file/${index}/${fileName}`
        );
    },

    deleteLanguage: async (index) => {
        return await restApi.delete(
            `admin/${getCurrentUser().profile['UserID']}/language/${index}`
        );
    },

    addCompany: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/companies/add`,
            entry
        );
    },
    addSite: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/sites/add`,
            entry
        );
    },

    updateCompany: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/companies/${entry['Company_index']}`,
            entry
        );
    },

    updateSite: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/sites/${entry['Site_index']}`,
            entry
        );
    },

    getCompanyByIndex: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/companies/${index}`
        );
    },

    getSiteByIndex: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/sites/${index}`
        );
    },

    addUser: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/users/addUser`,
            entry
        );
    },

    getAllUsers: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/users/getAll`,
            filtersArr
        );
    },

    getAllDashboardData: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/dashboard/getAll`,
            filtersArr
        );
    },
    getAllDevices: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/devices/getAll`,
            filtersArr
        );
    },

    deleteDevice: async (body) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/devices/delete`,
            body
        );
    },

    getAllDevicesTrack: async (filtersArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/devicesTrack/getAll`,
            filtersArr
        );
    },


    exportAllDevicesDashboardData: async (devicesArr) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/devicesTrack/exportAll`,
            devicesArr
        );
    },
    getDeviceQuestionnaires: async (device_index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/getDeviceQuestionnaires`
        );
    },

    setPatientPhone: async (device_index,patientPhone) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/devicesDashboard/${device_index}/setPatientPhone`,
            patientPhone
        );
    },

    getDevicesTrackByIndex: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/devicesTrack/${index}`
        );
    },

    getUserByIndex: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserID']}/users/${index}`
        );
    },

    editUser: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserID']}/users/${entry['UserID']}`,
            entry
        );
    },


}
export default AdminApi