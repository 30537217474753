import React from "react";
import SVGraphics from "../../../assets/SVGraphics";


const TreatmentHistoryNum = (props) => {

    return (


        <div className={"p-[1.5rem] pr-[0.5rem] rounded-[0.625rem] bg-white shadow-custom w-[23rem] flex h-[9.563rem] gap-x-[1.125rem] " + props.marginLeft}>

            <div className={"bg-[#94D6DC66] rounded-full w-[6rem] h-[6rem] p-[1.5rem]"}>
                <SVGraphics
                    fill={'#94D6DC66'}
                    className={"w-[3rem] h-[3rem]"}
                    svgname={props.svgName}/>
            </div>


            <div className={'block mt-[0.75rem]'}>
                <div className={"text-[#06135A] font-Poppins text-[2.5rem] font-bold leading-7"}> {props.number}</div>
                <div className={"mt-[2.125rem] text-[#72777B] font-Poppins text-lg font-bold leading-7"}> {props.text}</div>

            </div>
        </div>
    )

}

export default TreatmentHistoryNum



