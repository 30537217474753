import React, {useState, useEffect} from "react";
import {useTable, useSortBy, useRowSelect, usePagination} from 'react-table'
import frame from "../../assets/arrows/frame.svg"
import TablePagination from "./Pagination";
import {NoData} from "./NoData";
import {LoadingComponent} from "../input/LoadingComponent";


export function Table(props) {

    let {data, columns, onClick, subHeaders} = props
    let subHeadersArr = subHeaders && subHeaders.length > 0 ? subHeaders : []
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: {pageIndex, pageSize},
        selectedFlatRows,
    } = useTable({columns, data, initialState: {pageIndex: 0, pageSize: 7}},
        useSortBy,
        usePagination,
        useRowSelect,
    )

    return (
        <>

            {props.data.length === 0 && !props.isLoading ?
                <NoData/>
                :
                <>
                    <table {...getTableProps()} id="myTable"
                           className={"shadow-md rounded-[0.5rem] w-full h-auto text-left text-gray-500 rounded-b-md mt-5 font-Poppins leading-5 overflow-y-auto"}
                           style={{borderCollapse: 'collapse  !important'}}>

                        <thead
                            className="text-base text-[#000] sticky font-bold border-b border-[#E0E0D7] h-[4.25rem]"
                            style={{borderRadius: '50px!important', borderCollapse: 'collapse  !important'}}>
                        <tr>
                            {subHeadersArr.map((subHeader,index) => {
                                let headerStyle = !subHeader.rows ?
                                    'bg-transparent' :
                                    subHeader.className
                                return (<th
                                    key={subHeader.text?subHeader.text+index:index}
                                    colSpan={subHeader.rows ? subHeader.rows : 1}
                                    className={headerStyle}>
                                    {subHeader.text ? subHeader.text : ""}</th>)

                            })}
                        </tr>


                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={"header-" + index}>
                                {headerGroup.headers.map(column => {
                                    let bgColor = column.backGroundColor ? column.backGroundColor : 'bg-white'
                                    return <th
                                        key={column.id}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={` #FAFAFA pt-[0.8125rem] pr-[0.5rem] pb-[0.8125rem] pl-[1.5rem]
                                             font-Poppins-bold font-bold text-lg first:rounded-t-md last:rounded-t-md max-h-[4.25rem] leading-5 ${column.headerClassName} ${bgColor}`}
                                        // onClick={(prev) => {
                                        //     props.sortHandler(column.id, column.isSorted,column.isSortedDesc );
                                        // }}
                                    >
                                        {column.render('Header')}
                                        <span className={'font-normal text-xs'}>{column.additionalText} </span>
                                        <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? <img className={'inline-block ml-[7px]'} alt={'frame'} key={'sortDown'} src={frame}/>
                            : <img className={'inline-block ml-[7px]'} alt={'up'} key={'sortUp'} src={frame}/>
                        : ''}
                  </span>
                                    </th>
                                })}
                            </tr>
                        ))}
                        </thead>


                        <tbody {...getTableBodyProps()} className={"max-h-[674px]"}>
                        {page.map((row, pageIndex) => {
                            prepareRow(row)
                            const bgColor = pageIndex % 2 !== 0 ? "bg-[#FAFAFA]" : "bg-white"
                            return (
                                <tr key={row.id + "-" + pageIndex}
                                    className={`${bgColor}`}

                                >
                                    {row.cells.map((cell, index) => {
                                        let hasBg = cell.column.backGroundColor? cell.column.backGroundColor:''
                                        return (
                                            <td key={cell.column.id + "-" + pageIndex}
                                                className={hasBg+` py-[0.813rem] pl-[1.5rem] pr-[0.5rem] h-[4rem]  max-h-[4rem] text-[#000] font-normal font-Poppins text-lg ${onClick ? "cursor-pointer" : ""}`}
                                                onClick={cell.column.id !== '[editButton]' ? onClick ? () => onClick(row) : null : null}
                                            >
                                                {props.rowInEdit!==cell.row.index ?
                                                    cell.render('Cell'):
                                                    props.editCellsArr[index]
                                                }

                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>


                    {data.length > 7 &&
                        <div className={"w-full"} key={"TaleBody"}>
                            <TablePagination
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                previousPage={previousPage}
                                canPreviousPage={canPreviousPage}
                                nextPage={nextPage}
                                canNextPage={canNextPage}
                            />
                        </div>
                    }

                </>

            }
            {props.isLoading &&
                <LoadingComponent
                    style={{marginLeft: '15%'}}
                />
            }
        </>
    )
}
