import React from "react";
import {TextInput} from "./TextInput";

export function EmailInput(props) {
    const borderColor= 'border-[#E9E9EB]'
    const hasLabel = props.labelComp?props.labelComp:<div>
        <span className={'font-Poppins text-base '}>{props.label}</span>
    </div>
    return (
        <div className={props.containerClassName}>
            {hasLabel}
            <input type={"email"} disabled={props.disabled} maxLength={props.maxLength}
                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                   name={props.name} className={props.inputClassName + ` border border-solid ${borderColor}  pl-[0.75rem] rounded-lg`}
                   placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>

        </div>
    )
}
