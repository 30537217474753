import React from "react";
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
export function SlideInput(props) {

    const StyledSlider = styled(Slider)`
      color: ${props => props.value ?  '#54BF81':'#E0E0D7' };
      max-width: 51px;
      min-width: 51px;
      height: 31px;
      margin-top: 10px;
      padding: 0;
      
      & .MuiSlider-track {
        color: ${props => props.value ?  '#54BF81':'#E0E0D7' };
        max-width: 51px;
        min-width: 51px;
        border-radius: 16px;
      }

      & .MuiSlider-thumb {
        background-color: white; 
        width: 24px;
        height: 24px;
        left:${props => props.value ?  '68% !important':'33% !important' };
        &:hover {
        }
        
      }
     
   
    `;




    return (
        <div className={`${props.marginTop?props.marginTop:'mt-[1.5rem]'}`}>
            {props.leftLabel&&<label className={"mr-5"}>{props.leftLabel}</label>}
            <StyledSlider
                value={props.value}
                name={props.name}
                disabled={props.disabled}
                onChange={props.onChange}
                step={props.step}
                min={props.min}
                max={props.max}
            />

           {/* <Switch
                checked={props.value}
                disabled ={props.disabled}
                onChange={(event)=>props.onChange(event)}
                inputProps={{ 'aria-label': 'controlled' }}
            />*/}
            {props.rightLabel&&<label className={"ml-5"}>{props.rightLabel}</label>}


        </div>
    );
}
