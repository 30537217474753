import React from "react";
import SVGraphics from "../../../assets/SVGraphics";
import utils from "../../../utils/utils";


const LastTreatmentRing = (props) => {


    const TreatmentHeaderValues = [
        {
            name: "Time", svgName: "Time", additionalText: "", secondValue: "min", accessor: "time"
        },
        {
            name: "Quality", svgName: "Quality", additionalText: "%", accessor: "quality"
        },
        {
            name: "Energy Level", svgName: "EnergyLevel", additionalText: "", accessor: "energyLevel"
        },
        {
            name: "Battery Level", svgName: "BatteryLevel", additionalText: "%", accessor: "power"
        }
    ]

    const photoWidth= props.hasAdditionalPhoto?'w-[289px] ml-[-20px]':'w-[325px]'
    return (


        <div className={"p-[1.5rem] rounded-[0.625rem] bg-white shadow-custom w-[46.313rem] h-[38.813rem]"}>
            <div className={"text-black font-Poppins text-lg font-bold leading-7"}> {props.text} </div>

            <div className={' mt-[1.5rem] grid grid-cols-4 gap-x-[3.125rem] '}>

                {TreatmentHeaderValues.map(item => {
                    return <div className={"w-[7.688rem]"} key={item.accessor}>
                        <div className={"flex"}>
                            <SVGraphics fill={'none'} className={"w-[2.625rem] h-[2.625rem]"} svgname={item.svgName}/>
                            <label
                                className={"mt-[0.5rem] text-black font-Poppins text-lg font-bold leading-6 ml-[0.438rem]"}>
                                {props[item.accessor]?props[item.accessor]:''}{item.additionalText}
                            </label>
                            <label
                                className={"mt-[0.7rem] text-black font-Poppins text-font10 font-bold leading-6 ml-[0.38rem]"}>
                                {item.secondValue}
                            </label>
                        </div>

                        <div
                            className={"w-full text-center text-[#999996] font-Poppins text-sm font-bold "}> {item.name}</div>
                    </div>
                })}
            </div>
            <div className={"flex mt-[2rem] min-w-full"}>
                <div className={'ml-[1.5rem]'}>
                    <img className={`${props.hasAdditionalPhoto?'w-[300px] h-[279px]':'w-[400px] h-[282px]'} h-[282px] ` + photoWidth} src={props.photo} alt={""}/>
                    {props.hasAdditionalPhoto &&
                        <img className={'w-[300px] h-[125px]'} src={props.additionalPhoto} alt={""}/>}
                </div>


                <div className={'w-[50%] ml-[3rem]'}>
                    <div className={'w-[18.625rem] h-[7.625rem] bg-[#D4EFF1] px-[1.5rem] py-[0.5rem] flex gap-x-[0.625rem] rounded'}>
                        <div className={'pr-[0.75rem] border-r border-[#999996]'}>
                            <div className={'mt-[1.5rem] text-[#06135A] font-Poppins text-lg font-bold leading-7'}>
                                RF1
                            </div>
                            {(props.avgTempRF1<37 || props.avgTempRF1>40) && <SVGraphics className={'w-[1.5rem] h-[1.5rem] mt-[0.625rem]'}
                                         fill="none"
                                         svgname={props.avgTempRF1 < 37 ? 'ResultNotOk' : 'ResultOk'}/>}

                        </div>

                        <div className={'w-full'}>
                            <div className={'flex w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Time:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{utils.secondsToMMSS(props.totalTimeRF1)} min
                                {/*<div className={'ml-auto mt-[0.25rem]'}> m</div>*/}
                                </div>
                            </div>
                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Temperature:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgTempRF1}°C</div>
                            </div>

                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Impedance:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgImpedanceRF1}Ω</div>
                            </div>

                        </div>
                    </div>

                    <div className={'mt-[1.875rem] w-[18.625rem] h-[7.625rem] bg-[#E5DDE9] px-[1.5rem] py-[0.5rem] flex gap-x-[0.625rem] rounded'}>
                        <div className={'pr-[0.75rem] border-r border-[#999996]'}>
                            <div className={'mt-[1.5rem] text-[#06135A] font-Poppins text-lg font-bold leading-7'}>
                                RF2
                            </div>
                            {(props.avgTempRF2<37 || props.avgTempRF2>40) && <SVGraphics className={'w-[1.5rem] h-[1.5rem] mt-[0.625rem]'}
                                                                                         fill="none"
                                                                                         svgname={props.avgTempRF2 < 37 ? 'ResultNotOk' : 'ResultOk'}/>}

                        </div>

                        <div className={'w-full'}>
                            <div className={'flex w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Time:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{utils.secondsToMMSS(props.totalTimeRF2)} min
                                    {/*<label className={'text-font10'}> m</label>*/}
                                </div>
                            </div>
                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Temperature:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgTempRF2}°C</div>
                            </div>

                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Impedance:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgImpedanceRF2}Ω</div>
                            </div>

                        </div>
                    </div>

                    <div className={'mt-[1.875rem] w-[18.625rem] h-[7.625rem] bg-[#99999626] px-[1.5rem] py-[0.5rem] flex gap-x-[0.625rem] rounded'}>
                        <div className={'pr-[0.75rem] border-r border-[#999996]'}>
                            <div className={'mt-[1.5rem] text-[#06135A] font-Poppins text-lg font-bold leading-7'}>
                                RF3
                            </div>
                            {(props.avgTempRF3<37 || props.avgTempRF3>40) && <SVGraphics className={'w-[1.5rem] h-[1.5rem] mt-[0.625rem]'}
                                                                                         fill="none"
                                                                                         svgname={props.avgTempRF3 < 37 ? 'ResultNotOk' : 'ResultOk'}/>}

                        </div>

                        <div className={'w-full'}>
                            <div className={'flex w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Time:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{utils.secondsToMMSS(props.totalTimeRF3)} min
                                    {/*<label className={'text-font10'}> m</label>*/}
                                </div>
                            </div>
                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Temperature:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgTempRF3}°C</div>
                            </div>

                            <div className={'flex mt-[0.75rem] w-full'}>
                                <div className={'w-[7rem] h-[0.813rem] text-[#06135A] font-Poppins text-lg font-bold leading-0'}>Impedance:</div>
                                <div className={'ml-auto mt-[0.25rem]'}>{props.avgImpedanceRF3}Ω</div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
    )

}

export default LastTreatmentRing



