import React from "react";
import Select, {components} from 'react-select';
import {InputLabel} from "@mui/material";
import SVGraphics from "../../assets/SVGraphics";


export function SelectInput(props) {
    let value = props.data?.find(option => option.value === props.value)
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };

    const borderColor= props.value ?"1px solid #00A4BD":"1px solid #E9E9EB"
    const hasLabel = props.labelComp?props.labelComp:<div>
        <span className={'font-Poppins text-base '}>{props.label}</span>
    </div>

    return (
        <div className={props.containerClassName}>
            {/*<InputLabel className={props.labelClassName}>{props.label}</InputLabel>*/}
            {hasLabel}

            <Select
                className={`${props.inputClassName}`}
                classNamePrefix="select"
                isSearchable={false}
                onChange={props.onChange}
                placeholder={props.placeholder}
                isDisabled ={props.disabled}
                disabled={props.disabled}
                selected={props.selected}
                // menuIsOpen={true}
                value={value?value:null}
                components={{ NoOptionsMessage }}
                // name="color"
                options={props.data}
                styles={{
                    control: (base, state) => ({
                        ...base,

                        width: "100%",
                        height: "100%",
                        maxHeight: props.maxHeight?props.maxHeight:"48px",
                        minHeight: props.maxHeight?props.maxHeight:"48px",
                        color: "#E9E9EB",
                        textTransform: "capitalize",
                        boxShadow: 'unset',
                        fontSize: "16px",
                        outline: "unset",
                        paddingLeft:'1rem',
                        borderRadius:"0.5rem",
                        borderColor:'#E9E9EB',
                    })
                }}
            />
            {props.isIcon &&
                <SVGraphics className={'select-input-icon'} style={{position:'relative'}} svgname={props.svgName}/>}
        </div>

    )
}
