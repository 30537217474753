import React from "react";

class SVGraphics extends React.Component {

    getViewBox = svgname => {
        switch (svgname) {
            case "EnergyMode":
            case "OperationMode":
            case "ProgressTimer":
            case "Time":
            case "Quality":
            case "EnergyLevel":
            case "BatteryLevel":
                return "0 0 42 42";
            case "ResultOk":
            case "ResultNotOk":
                return "0 0 36 36";
            case "filter":
            case "backArrow":
                return "0 0 17 16";
            case "fail":
            case "play":
            case "pause":
            case "arrowBlackUp":
            case "arrowBlackDown":
            case "note":
                return "0 0 35 35";
            case "download":
            case "downloadSelected":
                return "0 0 37 36";
            case "update":
            case "blackEye":
            case "logOut":
            case "trash":
            case "email":
            case "devices":
            case "sitePlace":
            case "clinicians":
            case 'gantt':
            case 'sites':
            case 'chambers':
            case 'orders':
            case 'users':
            case 'configurations':
            case "organizations":
            case "gloveBox":
            case "power":
            case "cows":
            case "cowsSelected":
            case "notification":
            case "fillCRF":
            case "global":
            case "globalSelected":
            case "companySelected":
            case "company":
            case "profileAdminSelected":
            case "profileAdmin":
            case "SNManagement":
            case "SNManagementSelected":
            case "dashboard":
            case "dashboardSelected":
            case "deleteIcon":
            case "deleteIconHovered":
            case "keyLock":
            case "siteIcon":
            case "siteIconSelected":
            case "saveRow":
            case "cancelRow":
            case "cumulativeTreatment":
            case "normalTreatment":
            case "greenOk":
            case "aboutIcon":

                return "0 0 24 24";
            case "farms":
            case "profile":
            case "navAfter1":
            case "navAfter2":
            case 'logout':
                return "0 0 22 24";
            case "menuBar":
                return "0 0 26 24";
            case "monitor":
                return "0 0 18 20";
            case "DeviceManagement":
                return "0 0 20 20";
            case "line":
                return "0 0 1 42";
            case "activityLevel":
                return "0 0 23 20";
            case "toogleRight":
                return "0 0 24 16";
            case "eye":
            case "wrongInput":
            case "calendar":
            case "glass":

                return "0 0 16 16";
            case "alert-triangle":
            case "link":
            case "exclamation":
            case "notificationCRF":
            case "eligible":
            case "alert-triangle-yellow":
            case "NotEligible":
                return "0 0 32 32";
            case "files":
                return "0 0 72 72";
            case "back":
                return "0 0 50 50";
            case "alert":
                return "0 0 14 14";

            case "backGround":
                return "0 0 1920 950";
            case "downArrow":
            case "upArrow":
            case "saveIcon":
            case "plusIconPurple":
            case "plusIcon":
            case "infoIcon":
                return "0 0 16 16";
            case "xAxis":
                return "0 0 369 3";
            case "yAxis":
                return "0 0 29 370";
            case "unlockDeviceDashboard":
            case "avgAge":
                return "0 0 48 48";
            case "treatmentCalendarWeek":
            case "treatmentCalendarMonth":
                return "0 0 48 49";
            case "ErrorDashboard":
                return "0 0 44 44";
            case "BaseLine":
            case "Actual":
                return "0 0 59 60";
            case "close":
                return "0 0 24 24";
            case "deviceDashboard":
                return "0 0 48 22";

            case "verticaPlus":
                return "0 0 1133.86 184.25";
            default:
                return "0 0 24 24";

        }
    }


    getPath = (svgname) => {
        switch (svgname) {
            case "verticaPlus":
                return (
                    <g>
                        <g>
                            <path
                                d="m889.31,93.03c0,27.55-19.1,45.8-45.29,45.8-12.68,0-23.83-4.9-31.27-14.53v46.31h-16.22V48.24h15.55v14.2c7.27-9.97,18.76-15.04,31.94-15.04,26.2,0,45.29,18.25,45.29,45.63Zm-16.39,0c0-18.93-13.01-31.43-30.25-31.43s-30.08,12.51-30.08,31.43,13.01,31.6,30.08,31.6,30.25-12.51,30.25-31.6Z"
                                fill="#a4d8e0"/>
                            <path d="m902.31,12.41h16.22v125.4h-16.22V12.41Z" fill="#a4d8e0"/>
                            <path
                                d="m1023.49,48.24v89.57h-15.38v-13.52c-6.59,9.29-17.75,14.53-30.42,14.53-23.15,0-38.53-12.67-38.53-39.04v-51.55h16.22v49.69c0,17.58,8.79,26.37,24.17,26.37,16.9,0,27.72-10.48,27.72-29.75v-46.31h16.22Z"
                                fill="#a4d8e0"/>
                            <path
                                d="m1033.62,128.35l6.76-12.84c7.61,5.41,19.77,9.29,31.44,9.29,15.04,0,21.29-4.56,21.29-12.17,0-20.11-56.62-2.7-56.62-38.36,0-16.06,14.37-26.87,37.35-26.87,11.66,0,24.84,3.04,32.62,8.11l-6.93,12.84c-8.11-5.24-17.07-7.1-25.86-7.1-14.2,0-21.12,5.24-21.12,12.34,0,21.13,56.79,3.89,56.79,38.7,0,16.22-14.87,26.53-38.7,26.53-14.87,0-29.58-4.56-37.01-10.48Z"
                                fill="#a4d8e0"/>
                        </g>
                        <g>
                            <path
                                d="m410.79,138.2c-5.98,0-11.78-1.17-17.24-3.48-5.28-2.23-10.01-5.42-14.08-9.49s-7.26-8.8-9.49-14.08c-2.31-5.47-3.48-11.27-3.48-17.24s1.17-11.78,3.48-17.24c2.23-5.28,5.42-10.01,9.49-14.08,4.07-4.07,8.8-7.26,14.08-9.49,5.47-2.31,11.27-3.48,17.24-3.48s11.78,1.17,17.24,3.48c5.28,2.23,10.01,5.42,14.08,9.49,4.07,4.07,7.26,8.8,9.49,14.08,2.31,5.47,3.48,11.27,3.48,17.24v7.97h-71.52c.3,1.04.67,2.06,1.09,3.06,1.43,3.38,3.47,6.41,6.08,9.02,2.61,2.61,5.64,4.65,9.02,6.08,3.49,1.48,7.2,2.23,11.03,2.23s7.8-.8,11.39-2.38c3.48-1.53,6.57-3.71,9.2-6.48l11.57,10.96c-4.09,4.32-8.92,7.72-14.36,10.11-5.63,2.47-11.62,3.73-17.81,3.73Zm-27.22-52.27h54.45c-.3-1.04-.67-2.06-1.09-3.06-1.43-3.38-3.47-6.41-6.08-9.02-2.61-2.61-5.64-4.65-9.02-6.08-3.49-1.48-7.2-2.23-11.03-2.23s-7.54.75-11.03,2.23c-3.38,1.43-6.41,3.47-9.02,6.08-2.61,2.61-4.65,5.64-6.08,9.02-.42,1-.79,2.03-1.09,3.06Z"
                                fill="#4d2775"/>
                            <path
                                d="m656.57,119.79c-3.59,1.58-7.43,2.38-11.39,2.38s-7.54-.75-11.03-2.23c-3.38-1.43-6.41-3.47-9.02-6.08-2.61-2.61-4.65-5.64-6.08-9.02-1.48-3.49-2.22-7.2-2.22-11.03s.75-7.54,2.22-11.03c1.43-3.38,3.47-6.41,6.08-9.02,2.61-2.61,5.64-4.65,9.02-6.08,3.49-1.48,7.2-2.22,11.03-2.22s7.8.8,11.39,2.38c3.48,1.53,6.57,3.71,9.2,6.48l11.57-10.96c-4.09-4.32-8.92-7.72-14.35-10.11-5.63-2.47-11.62-3.73-17.81-3.73s-11.78,1.17-17.25,3.48c-5.28,2.23-10.01,5.42-14.08,9.49-4.07,4.07-7.26,8.8-9.49,14.08-2.31,5.47-3.48,11.27-3.48,17.24s1.17,11.78,3.48,17.24c2.23,5.28,5.42,10.01,9.49,14.08,4.07,4.07,8.8,7.26,14.08,9.49,5.47,2.31,11.27,3.48,17.25,3.48s12.18-1.25,17.81-3.73c5.43-2.39,10.26-5.79,14.35-10.11l-11.57-10.96c-2.63,2.77-5.72,4.95-9.2,6.48Z"
                                fill="#4d2775"/>
                            <path
                                d="m758.58,48.24v13c-3.74-3.43-7.99-6.17-12.67-8.15-5.47-2.31-11.27-3.48-17.24-3.48s-11.78,1.17-17.25,3.48c-5.28,2.23-10.01,5.42-14.08,9.49-4.07,4.07-7.26,8.8-9.49,14.08-2.31,5.47-3.48,11.27-3.48,17.24s1.17,11.78,3.48,17.24c2.23,5.28,5.42,10.01,9.49,14.08,4.07,4.07,8.8,7.26,14.08,9.49,5.47,2.31,11.27,3.48,17.25,3.48s11.78-1.17,17.24-3.48c4.68-1.98,8.93-4.72,12.67-8.15v12.3h15.94V48.24h-15.94Zm-3.79,56.7c-1.43,3.38-3.47,6.41-6.08,9.02-2.61,2.61-5.64,4.65-9.02,6.08-3.49,1.48-7.2,2.22-11.03,2.22s-7.54-.75-11.03-2.22c-3.38-1.43-6.41-3.47-9.02-6.08-2.61-2.61-4.65-5.64-6.08-9.02-1.48-3.49-2.22-7.2-2.22-11.03s.75-7.54,2.22-11.03c1.43-3.38,3.47-6.41,6.08-9.02s5.64-4.65,9.02-6.08c3.49-1.48,7.2-2.23,11.03-2.23s7.54.75,11.03,2.23c3.38,1.43,6.41,3.47,9.02,6.08s4.65,5.64,6.08,9.02c1.48,3.49,2.23,7.2,2.23,11.03s-.75,7.54-2.23,11.03Z"
                                fill="#4d2775"/>
                            <path
                                d="m491.09,51.31c-2.83,1.2-5.49,2.71-7.94,4.52v-7.77h-15.94v90.82h15.94v-51.54c0-6.19,2.41-12.01,6.79-16.38,4.38-4.38,10.19-6.79,16.38-6.79v-15.94c-5.28,0-10.4,1.03-15.22,3.08Z"
                                fill="#4d2775"/>
                            <rect x="572.97" y="48.24" width="15.94" height="90.64" fill="#4d2775"/>
                            <circle cx="580.94" cy="34.56" r="8.55" fill="#a4d8e0"/>
                            <polygon
                                points="348.46 48.06 320.9 117.82 293.34 48.06 276.19 48.06 312.08 138.88 312.58 138.88 329.22 138.88 329.72 138.88 365.6 48.06 348.46 48.06"
                                fill="#4d2775"/>
                            <path
                                d="m558.18,64.18v-15.94h-16.22v-22.23h-15.94v22.23h-9.58v15.94h9.58v34.92c0,5.28,1.03,10.4,3.08,15.22,1.97,4.66,4.79,8.84,8.38,12.43,3.59,3.59,7.77,6.41,12.43,8.38,2.67,1.13,5.44,1.94,8.27,2.45v-16.38c-3.52-1.1-6.75-3.04-9.43-5.72-4.38-4.38-6.79-10.19-6.79-16.38v-34.92h16.22Z"
                                fill="#4d2775"/>
                            <path
                                d="m233.34,48.06c-1.87.38-14.24,3.09-28.3,11.46-.9.53-12.25,7.3-21.9,17.42-9.22,9.67-15.56,17.63-15.82,17.97l-.05.06c-3.77,4.83-11.42,13.69-15.89,16.93l-.33.22-.37.34c-.55.46-9.43,7.67-21.76,7.67s-21.2-7.2-21.76-7.67l-.26-.24-.43-.31c-4.47-3.24-12.12-12.1-15.88-16.93l-.05-.06c-.27-.33-6.6-8.3-15.82-17.97-9.65-10.12-21-16.9-21.9-17.42-14.06-8.37-26.43-11.08-28.3-11.46v19.61c4.08,1.21,11.02,3.69,18.6,8.2l.06.03c.1.06,9.85,5.84,17.79,14.16,8.35,8.76,14.29,16.17,14.68,16.65,1.29,1.65,11.4,14.44,19.39,20.37,2.51,2.08,15.54,12.05,33.91,12.05s31.39-9.97,33.91-12.05c7.99-5.92,18.1-18.71,19.39-20.37.39-.49,6.33-7.89,14.68-16.65,7.94-8.32,17.69-14.1,17.78-14.16l.07-.04c7.57-4.51,14.52-6.99,18.6-8.2v-19.61Z"
                                fill="#4d2775"/>
                        </g>
                    </g>
                );
            case "EnergyMode":
                return (
                    <g>
                        <path
                            d="M15.19 22.2601H18.235V29.3476C18.235 30.3976 19.53 30.8876 20.23 30.1001L27.685 21.6301C28.3325 20.8951 27.8075 19.7401 26.8275 19.7401H23.7825V12.6526C23.7825 11.6026 22.4875 11.1126 21.7875 11.9001L14.3325 20.3701C13.685 21.1051 14.21 22.2601 15.19 22.2601Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M20.9475 38.5C30.6125 38.5 38.4475 30.665 38.4475 21C38.4475 11.335 30.6125 3.5 20.9475 3.5C11.2825 3.5 3.4475 11.335 3.4475 21C3.4475 30.665 11.2825 38.5 20.9475 38.5Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "aboutIcon":
                return (
                    <g>
                        <path stroke="#52007E" d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"/>

                    </g>
                );
            case "DeviceManagement":
                return (
                    <g>
                        <path
                            d="M14 1.02002C17.33 1.20002 19 2.43002 19 7.00002V13C19 17 18 19 13 19H7C2 19 1 17 1 13V7.00002C1 2.44002 2.67 1.20002 6 1.02002"
                            stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "backArrow":
                return (
                    <g>
                        <path d="M6.87998 3.95312L2.83331 7.99979L6.87998 12.0465" stroke="#292D32" strokeWidth="1.5"
                              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.1667 8H2.94666" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "Time":
                return (
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M37.1871 21.001C37.1871 29.9417 29.9404 37.1885 20.9996 37.1885C12.0589 37.1885 4.81213 29.9417 4.81213 21.001C4.81213 12.0602 12.0589 4.81348 20.9996 4.81348C29.9404 4.81348 37.1871 12.0602 37.1871 21.001Z"
                              stroke="#52007E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M27.0049 26.1504L20.4074 22.2147V13.7324" stroke="#52007E" strokeWidth="2.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "ResultOk":
                return (
                    <g>
                        <path
                            d="M11.22 27.5247L15.87 31.1247C16.47 31.7247 17.82 32.0247 18.72 32.0247H24.42C26.22 32.0247 28.17 30.6747 28.62 28.8747L32.22 17.9247C32.97 15.8247 31.62 14.0247 29.37 14.0247H23.37C22.47 14.0247 21.72 13.2747 21.87 12.2247L22.62 7.42471C22.92 6.07471 22.02 4.57471 20.67 4.12471C19.47 3.67471 17.97 4.27471 17.37 5.17471L11.22 14.3247"
                            stroke="#54BF81" strokeWidth="1.5" strokeMiterlimit="10"/>
                        <path
                            d="M3.57001 27.5246V12.8246C3.57001 10.7246 4.47001 9.97461 6.57001 9.97461H8.07001C10.17 9.97461 11.07 10.7246 11.07 12.8246V27.5246C11.07 29.6246 10.17 30.3746 8.07001 30.3746H6.57001C4.47001 30.3746 3.57001 29.6246 3.57001 27.5246Z"
                            stroke="#54BF81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "cumulativeTreatment":
                return (
                    <g>
                        <path
                            d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="#52007E"
                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.6 2H15.6" stroke="#52007E" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="#52007E" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M22 8V14.19C22 15.74 20.74 17 19.19 17" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "normalTreatment":
                return (
                    <g>
                        <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#52007E"
                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "ResultNotOk":
                return (
                    <g>
                        <path
                            d="M24.78 8.47461L20.13 4.87461C19.53 4.27461 18.18 3.97461 17.28 3.97461H11.58C9.77997 3.97461 7.82997 5.32461 7.37997 7.12461L3.77996 18.0746C3.02996 20.1746 4.37997 21.9746 6.62997 21.9746H12.63C13.53 21.9746 14.28 22.7246 14.13 23.7746L13.38 28.5746C13.08 29.9246 13.98 31.4246 15.33 31.8746C16.53 32.3246 18.03 31.7246 18.63 30.8246L24.78 21.6746"
                            stroke="#E0345D" strokeWidth="1.5" strokeMiterlimit="10"/>
                        <path
                            d="M32.43 8.475V23.175C32.43 25.275 31.53 26.025 29.43 26.025H27.93C25.83 26.025 24.93 25.275 24.93 23.175V8.475C24.93 6.375 25.83 5.625 27.93 5.625H29.43C31.53 5.625 32.43 6.375 32.43 8.475Z"
                            stroke="#E0345D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "Quality":
                return (
                    <g>
                        <path
                            d="M24.9921 11.1387C23.9408 10.8246 22.7808 10.6191 21.5 10.6191C15.7121 10.6191 11.0237 15.3075 11.0237 21.0954C11.0237 26.8954 15.7121 31.5837 21.5 31.5837C27.2879 31.5837 31.9762 26.8954 31.9762 21.1075C31.9762 18.9566 31.3237 16.9508 30.2121 15.2833"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M26.4904 11.4277L22.9984 7.41602" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M26.4904 11.4287L22.4183 14.4012" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M19.2644 19.66C19.2644 19.2707 19.3764 18.9667 19.6004 18.748C19.8297 18.524 20.1231 18.412 20.4804 18.412C20.8377 18.412 21.1284 18.524 21.3524 18.748C21.5817 18.9667 21.6964 19.2707 21.6964 19.66C21.6964 20.0493 21.5817 20.356 21.3524 20.58C21.1284 20.804 20.8377 20.916 20.4804 20.916C20.1231 20.916 19.8297 20.804 19.6004 20.58C19.3764 20.356 19.2644 20.0493 19.2644 19.66ZM24.1444 18.516L20.9524 24.084H20.0564L23.2484 18.516H24.1444ZM20.4804 18.964C20.1444 18.964 19.9764 19.196 19.9764 19.66C19.9764 20.1293 20.1444 20.364 20.4804 20.364C20.6404 20.364 20.7631 20.308 20.8484 20.196C20.9391 20.0787 20.9844 19.9 20.9844 19.66C20.9844 19.196 20.8164 18.964 20.4804 18.964ZM22.5204 22.932C22.5204 22.5427 22.6324 22.2387 22.8564 22.02C23.0857 21.796 23.3791 21.684 23.7364 21.684C24.0884 21.684 24.3764 21.796 24.6004 22.02C24.8297 22.2387 24.9444 22.5427 24.9444 22.932C24.9444 23.3213 24.8297 23.628 24.6004 23.852C24.3764 24.076 24.0884 24.188 23.7364 24.188C23.3791 24.188 23.0857 24.076 22.8564 23.852C22.6324 23.628 22.5204 23.3213 22.5204 22.932ZM23.7284 22.236C23.3924 22.236 23.2244 22.468 23.2244 22.932C23.2244 23.396 23.3924 23.628 23.7284 23.628C24.0644 23.628 24.2324 23.396 24.2324 22.932C24.2324 22.468 24.0644 22.236 23.7284 22.236Z"
                            fill="#52007E"/>
                        <path
                            d="M21.4475 38.5C31.1125 38.5 38.9475 30.665 38.9475 21C38.9475 11.335 31.1125 3.5 21.4475 3.5C11.7825 3.5 3.94751 11.335 3.94751 21C3.94751 30.665 11.7825 38.5 21.4475 38.5Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "EnergyLevel":
                return (
                    <g>
                        <path
                            d="M13.5 26L18.09 20.64C18.85 19.76 20.2 19.7 21.02 20.53L21.97 21.48C22.79 22.3 24.14 22.25 24.9 21.37L29.5 16"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M21.4475 38.5C31.1125 38.5 38.9475 30.665 38.9475 21C38.9475 11.335 31.1125 3.5 21.4475 3.5C11.7825 3.5 3.94751 11.335 3.94751 21C3.94751 30.665 11.7825 38.5 21.4475 38.5Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "BatteryLevel":
                return (
                    <g>
                        <path
                            d="M31.1512 18.7207C32.8256 18.7207 32.8256 19.1672 32.8256 20.0602V21.8463C32.8256 22.7393 32.8256 23.1858 31.1512 23.1858"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.6395 19.2793C16.9695 20.376 16.9695 21.5314 16.6395 22.6281" stroke="#52007E"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5465 19.2793C20.8765 20.376 20.8765 21.5314 20.5465 22.6281" stroke="#52007E"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23.8954 19.2793C24.2254 20.376 24.2254 21.5314 23.8954 22.6281" stroke="#52007E"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M23.8604 27.093H17.7906C13.7441 27.093 12.7325 26.2558 12.7325 22.907V19.5581C12.7325 16.2093 13.7441 15.3721 17.7906 15.3721H23.8604C27.9069 15.3721 28.9185 16.2093 28.9185 19.5581V22.907C28.9185 26.2558 27.9069 27.093 23.8604 27.093Z"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M21.4475 38.5C31.1125 38.5 38.9475 30.665 38.9475 21C38.9475 11.335 31.1125 3.5 21.4475 3.5C11.7825 3.5 3.94751 11.335 3.94751 21C3.94751 30.665 11.7825 38.5 21.4475 38.5Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "ProgressTimer":
                return (
                    <g>
                        <path
                            d="M20.1249 37.626C29.7899 37.626 37.6249 29.791 37.6249 20.126C37.6249 10.461 29.7899 2.62598 20.1249 2.62598C10.46 2.62598 2.62494 10.461 2.62494 20.126C2.62494 29.791 10.46 37.626 20.1249 37.626Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M11.1562 17.7191V21.6566C11.1562 23.6253 12.1406 24.6097 14.1094 24.6097H15.517C15.8812 24.6097 16.2455 24.718 16.5605 24.905L19.4348 26.7064C21.9155 28.2617 23.9531 27.1297 23.9531 24.2061V15.1695C23.9531 12.2361 21.9155 11.1139 19.4348 12.6692L16.5605 14.4706C16.2455 14.6577 15.8812 14.7659 15.517 14.7659H14.1094C12.1406 14.7659 11.1562 15.7503 11.1562 17.7191Z"
                            stroke="#52007E" strokeWidth="2"/>
                        <path d="M26.9062 15.75C28.6584 18.083 28.6584 21.292 26.9062 23.625" stroke="#52007E"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M28.7077 13.2891C31.5526 17.0789 31.5526 22.2961 28.7077 26.0859" stroke="#52007E"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "OperationMode":
                return (
                    <g>
                        <path d="M28.5 21L18 27.0622L18 14.9378L28.5 21Z" stroke="#52007E" strokeWidth="2"/>
                        <path
                            d="M22 39C31.665 39 39.5 31.165 39.5 21.5C39.5 11.835 31.665 4 22 4C12.335 4 4.49998 11.835 4.49998 21.5C4.49998 31.165 12.335 39 22 39Z"
                            stroke="#52007E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                );
            case "treatmentCalendarWeek":
                return (
                    <g>
                        <path d="M16 4.5V10.5" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32 4.5V10.5" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 18.6797H41" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M42 17.5V34.5C42 40.5 39 44.5 32 44.5H16C9 44.5 6 40.5 6 34.5V17.5C6 11.5 9 7.5 16 7.5H32C39 7.5 42 11.5 42 17.5Z"
                            stroke="#292D32" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M31.3894 27.9004H31.4074" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M23.991 27.9004H24.0089" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M16.5886 27.9004H16.6066" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "treatmentCalendarMonth":
                return (
                    <g>
                        <path d="M16 4.5V10.5" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32 4.5V10.5" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 18.6797H41" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M42 17.5V34.5C42 40.5 39 44.5 32 44.5H16C9 44.5 6 40.5 6 34.5V17.5C6 11.5 9 7.5 16 7.5H32C39 7.5 42 11.5 42 17.5Z"
                            stroke="#292D32" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M31.3894 27.9004H31.4074" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M31.3894 33.9004H31.4074" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M23.991 27.9004H24.0089" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M23.991 33.9004H24.0089" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M16.5886 27.9004H16.6066" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M16.5886 33.9004H16.6066" stroke="#292D32" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "saveRow":
                return (
                    <g>
                        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                              fill="#54BF81" fillOpacity="0.05" stroke="#54BF81" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#54BF81" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "cancelRow":
                return (
                    <g>
                        <path d="M9.17001 14.8299L14.83 9.16992" stroke="#E0345D" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.83 14.8299L9.17001 9.16992" stroke="#E0345D" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                              fill="#E0345D" fillOpacity="0.05" stroke="#E0345D" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "siteIcon":
                return (
                    <g>
                        <path
                            d="M11.9999 13.43C13.723 13.43 15.1199 12.0331 15.1199 10.31C15.1199 8.58687 13.723 7.19 11.9999 7.19C10.2768 7.19 8.87988 8.58687 8.87988 10.31C8.87988 12.0331 10.2768 13.43 11.9999 13.43Z"
                            stroke="#999996" strokeWidth="1.5"/>
                        <path
                            d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z"
                            stroke="#999996" strokeWidth="1.5"/>
                    </g>
                );
            case "keyLock":
                return (
                    <g>
                        <path
                            d="M19.79 14.9301C17.73 16.9801 14.78 17.6101 12.19 16.8001L7.48002 21.5001C7.14002 21.8501 6.47002 22.0601 5.99002 21.9901L3.81002 21.6901C3.09002 21.5901 2.42002 20.9101 2.31002 20.1901L2.01002 18.0101C1.94002 17.5301 2.17002 16.8601 2.50002 16.5201L7.20002 11.8201C6.40002 9.22007 7.02002 6.27007 9.08002 4.22007C12.03 1.27007 16.82 1.27007 19.78 4.22007C22.74 7.17007 22.74 11.9801 19.79 14.9301Z"
                            stroke="#52007E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M6.89001 17.49L9.19001 19.79" stroke="#52007E" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "siteIconSelected":
                return (
                    <g>
                        <path
                            d="M11.9999 13.43C13.723 13.43 15.1199 12.0331 15.1199 10.31C15.1199 8.58687 13.723 7.19 11.9999 7.19C10.2768 7.19 8.87988 8.58687 8.87988 10.31C8.87988 12.0331 10.2768 13.43 11.9999 13.43Z"
                            stroke="#06135A" strokeWidth="1.5"/>
                        <path
                            d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z"
                            stroke="#06135A" strokeWidth="1.5"/>
                    </g>
                );
            case "deviceDashboard":
                return (
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M0.0917364 0.355846C0.0412607 0.407698 0 1.46161 0 2.69785C0 4.71187 0.0250315 4.95427 0.240688 5.02979C4.8623 6.64925 7.61976 8.88599 12.2227 14.7488C15.1888 18.5268 17.6992 20.4617 20.8539 21.401C21.7661 21.6726 22.2624 21.7234 24 21.7234C26.3611 21.7234 27.3817 21.4952 29.3532 20.5259C31.5792 19.4316 33.0346 18.1008 36.0166 14.4338C40.8132 8.53563 43.1927 6.62998 47.7593 5.02979C47.9751 4.95413 48 4.71116 48 2.68254C48 1.43809 47.9471 0.386022 47.8821 0.344796C47.5757 0.14957 44.0167 1.48682 42.3694 2.41606C38.9104 4.36746 36.738 6.38744 32.6503 11.4535C29.8469 14.9279 28.0966 16.3699 25.9255 16.9937C25.0398 17.2482 22.9573 17.2477 22.0745 16.9927C21.1677 16.7309 19.9196 16.0977 19.1238 15.496C18.3129 14.8827 16.5727 13.0169 15.3537 11.4535C13.6576 9.27814 11.1327 6.49469 9.88676 5.42647C7.09355 3.03177 4.31106 1.48002 1.1007 0.526704C0.0519886 0.215306 0.203278 0.240949 0.0917364 0.355846Z"
                              fill="black"/>
                    </g>
                );
            case "BaseLine":
                return (
                    <g>
                        <path
                            d="M23 40.5625C25.2437 40.5625 27.0625 38.7437 27.0625 36.5C27.0625 34.2563 25.2437 32.4375 23 32.4375C20.7563 32.4375 18.9375 34.2563 18.9375 36.5C18.9375 38.7437 20.7563 40.5625 23 40.5625Z"
                            stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M23 38.125C23.8975 38.125 24.625 37.3975 24.625 36.5C24.625 35.6025 23.8975 34.875 23 34.875C22.1025 34.875 21.375 35.6025 21.375 36.5C21.375 37.3975 22.1025 38.125 23 38.125Z"
                            stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23 32.1667V31.0833" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.6666 36.5H17.5833" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23 40.8333V41.9167" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M27.3333 36.5H28.4166" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round"/>
                        <rect width="32.0377" height="32.0377" rx="16.0189"
                              transform="matrix(0.704198 0.710004 -0.704198 0.710004 23.0234 13.7298)" stroke="black"
                              strokeWidth="2"/>
                        <path d="M46.7417 11.5414L34.6346 23.849" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M36.1307 9.54724L46.7418 11.5414L48.8067 22.2232" stroke="black" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "greenOk":
                return (
                    <g>
                        <path
                            d="M23.0249 11.9996C23.0249 5.91067 18.0888 0.974609 11.9999 0.974609C5.91091 0.974609 0.974854 5.91067 0.974854 11.9996C0.974854 18.0885 5.91091 23.0246 11.9999 23.0246C18.0888 23.0246 23.0249 18.0885 23.0249 11.9996Z"
                            fill="#FEFEFE" stroke="#54BF81" strokeWidth="1.5" strokeMiterlimit="10"/>
                        <path d="M16.0548 9.34835L10.7515 14.6517L8.09985 12" stroke="#54BF81" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "Actual":
                return (
                    <g>
                        <rect x="6.41309" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="6.41309" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="8.87451" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="13.7976" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="18.7207" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="23.6438" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="28.5669" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="33.49" y="35.7656" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="8.87451" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="8.87451" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="6.41309" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="11.3362" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="16.2593" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="21.1824" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="26.1055" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="31.0286" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="35.9517" y="35.7656" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="11.3362" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="11.3362" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="13.7976" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="13.7976" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="16.2593" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="16.2593" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="18.7207" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="18.7207" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="21.1824" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="21.1824" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="23.6438" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="23.6438" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="26.1055" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="26.1055" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="28.5669" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="28.5669" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="31.0286" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="31.0286" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="33.49" y="33.304" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="33.49" y="38.2271" width="2.46154" height="2.46154" fill="#D4EFF1"/>
                        <rect x="35.9517" y="33.304" width="2.46154" height="2.46154" fill="black"/>
                        <rect x="35.9517" y="38.2271" width="2.46154" height="2.46154" fill="black"/>
                        <rect width="32.0377" height="32.0377" rx="16.0189"
                              transform="matrix(0.704198 0.710004 -0.704198 0.710004 23.0234 13.7298)" stroke="black"
                              strokeWidth="2"/>
                        <path d="M46.7417 11.5414L34.6346 23.849" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M36.1307 9.54724L46.7418 11.5414L48.8067 22.2232" stroke="black" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "unlockDeviceDashboard":
                return (
                    <g>
                        <path d="M34 44H14C6 44 4 42 4 34V30C4 22 6 20 14 20H34C42 20 44 22 44 30V34C44 42 42 44 34 44Z"
                              stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 20V16C12 9.38 14 4 24 4C33 4 36 8 36 14" stroke="#292D32" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M12.044 27.0369C12.0198 27.0611 12 27.5525 12 28.1289C12 29.068 12.012 29.1811 12.1153 29.2163C14.3299 29.9714 15.6511 31.0143 17.8567 33.748C19.278 35.5095 20.4809 36.4117 21.9925 36.8497C22.4296 36.9763 22.6674 37 23.5 37C24.6313 37 25.1204 36.8936 26.0651 36.4416C27.1317 35.9314 27.8291 35.3109 29.258 33.6011C31.5563 30.8509 32.6965 29.9624 34.8847 29.2163C34.9881 29.181 35 29.0677 35 28.1218C35 27.5416 34.9746 27.051 34.9435 27.0318C34.7967 26.9408 33.0913 27.5643 32.302 27.9976C30.6446 28.9074 29.6036 29.8493 27.6449 32.2115C26.3017 33.8315 25.463 34.5038 24.4226 34.7947C23.9982 34.9134 23.0004 34.9131 22.5774 34.7942C22.1429 34.6721 21.5448 34.3769 21.1635 34.0964C20.7749 33.8104 19.9411 32.9404 19.357 32.2115C18.5443 31.1971 17.3344 29.8993 16.7374 29.4012C15.399 28.2846 14.0657 27.5611 12.5274 27.1166C12.0249 26.9714 12.0974 26.9834 12.044 27.0369Z"
                              fill="black"/>
                    </g>
                );
            case "ErrorDashboard":
                return (
                    <g>
                        <path
                            d="M22 27.6719C21.1544 27.6719 20.4531 26.9706 20.4531 26.125V15.8125C20.4531 14.9669 21.1544 14.2656 22 14.2656C22.8456 14.2656 23.5469 14.9669 23.5469 15.8125V26.125C23.5469 26.9706 22.8456 27.6719 22 27.6719Z"
                            fill="black"/>
                        <path
                            d="M22 34.3751C21.8763 34.3751 21.7319 34.3545 21.5875 34.3339C21.4638 34.3132 21.34 34.272 21.2162 34.2101C21.0925 34.1689 20.9687 34.107 20.845 34.0245C20.7419 33.942 20.6387 33.8595 20.5356 33.777C20.1644 33.3851 19.9375 32.8489 19.9375 32.3126C19.9375 31.7764 20.1644 31.2401 20.5356 30.8482C20.6387 30.7657 20.7419 30.6832 20.845 30.6007C20.9687 30.5182 21.0925 30.4564 21.2162 30.4151C21.34 30.3532 21.4638 30.312 21.5875 30.2914C21.8556 30.2295 22.1444 30.2295 22.3919 30.2914C22.5362 30.312 22.66 30.3532 22.7838 30.4151C22.9075 30.4564 23.0313 30.5182 23.155 30.6007C23.2581 30.6832 23.3613 30.7657 23.4644 30.8482C23.8356 31.2401 24.0625 31.7764 24.0625 32.3126C24.0625 32.8489 23.8356 33.3851 23.4644 33.777C23.3613 33.8595 23.2581 33.942 23.155 34.0245C23.0313 34.107 22.9075 34.1689 22.7838 34.2101C22.66 34.272 22.5362 34.3132 22.3919 34.3339C22.2681 34.3545 22.1237 34.3751 22 34.3751Z"
                            fill="black"/>
                        <path
                            d="M34.388 44H9.60358C5.616 44 2.56908 42.4626 1.01494 39.6909C-0.518747 36.9193 -0.314255 33.3465 1.62841 29.6437L14.0206 6.04134C16.0655 2.1437 18.8875 0 21.9958 0C25.104 0 27.926 2.1437 29.9709 6.04134L42.3631 29.6654C44.3058 33.3681 44.5307 36.9193 42.9766 39.7126C41.4225 42.4626 38.3755 44 34.388 44ZM21.9958 3.24803C20.0736 3.24803 18.1922 4.80709 16.6994 7.62205L4.3277 31.2461C2.93716 33.8878 2.71222 36.313 3.67333 38.0669C4.63444 39.8209 6.76115 40.7736 9.62403 40.7736H34.4084C37.2713 40.7736 39.3776 39.8209 40.3591 38.0669C41.3407 36.313 41.0953 33.9094 39.7047 31.2461L27.2921 7.62205C25.7993 4.80709 23.918 3.24803 21.9958 3.24803Z"
                            fill="black"/>
                    </g>
                );
            case "avgAge":
                return (
                    <g>
                        <path
                            d="M40.1671 18.0207C40.0738 18.0051 39.965 18.0051 39.8717 18.0207C37.7267 17.9429 36.0168 16.1865 36.0168 14.0103C36.0168 11.7875 37.8044 10 40.0272 10C42.2499 10 44.0375 11.8031 44.0375 14.0103C44.0219 16.1865 42.3121 17.9585 40.1671 18.0207Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M35.6438 22.2564C33.4521 23.7175 33.4521 26.1113 35.6438 27.5724C38.1308 29.2356 42.2033 29.2356 44.6903 27.5724C46.882 26.1113 46.882 23.7175 44.6903 22.2564C42.2188 20.6087 38.1308 20.6087 35.6438 22.2564Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M8.16706 18.0207C8.0738 18.0051 7.96499 18.0051 7.87173 18.0207C5.72667 17.9429 4.01685 16.1865 4.01685 14.0103C4.01685 11.7875 5.80439 10 8.02716 10C10.2499 10 12.0375 11.8031 12.0375 14.0103C12.0219 16.1865 10.3121 17.9585 8.16706 18.0207Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M3.64377 22.2564C1.45208 23.7175 1.45208 26.1113 3.64377 27.5724C6.13079 29.2356 10.2033 29.2356 12.6903 27.5724C14.882 26.1113 14.882 23.7175 12.6903 22.2564C10.2188 20.6087 6.13079 20.6087 3.64377 22.2564Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M23.9675 25.4831C23.8167 25.458 23.6409 25.458 23.4901 25.4831C20.0232 25.3575 17.2596 22.5186 17.2596 19.0014C17.2596 15.4088 20.1488 12.5197 23.7414 12.5197C27.3339 12.5197 30.2231 15.4339 30.2231 19.0014C30.198 22.5186 27.4344 25.3826 23.9675 25.4831Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M16.6568 31.4804C13.1144 33.8419 13.1144 37.7109 16.6568 40.0724C20.6764 42.7606 27.2586 42.7606 31.2783 40.0724C34.8206 37.7109 34.8206 33.8419 31.2783 31.4804C27.2838 28.8173 20.6764 28.8173 16.6568 31.4804Z"
                            stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "download":
                return (
                    <g>
                        <path d="M19 16.5V25.5L22 22.5" stroke="#999996" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M19 25.5L16 22.5" stroke="#999996" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M33.5 15V22.5C33.5 30 30.5 33 23 33H14C6.5 33 3.5 30 3.5 22.5V13.5C3.5 6 6.5 3 14 3H21.5"
                            stroke="#999996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33.5 15H27.5C23 15 21.5 13.5 21.5 9V3L33.5 15Z" stroke="#999996" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "filter":
                return (
                    <g>
                        <path fill='none'
                              d="M4.10006 1.40002H12.9001C13.6334 1.40002 14.2334 2.00002 14.2334 2.73336V4.20002C14.2334 4.73336 13.9001 5.40002 13.5667 5.73336L10.7001 8.26669C10.3001 8.60002 10.0334 9.26669 10.0334 9.80002V12.6667C10.0334 13.0667 9.76672 13.6 9.43339 13.8L8.50006 14.4C7.63339 14.9334 6.43339 14.3334 6.43339 13.2667V9.73336C6.43339 9.26669 6.16672 8.66669 5.90006 8.33336L3.36672 5.66669C3.03339 5.33336 2.76672 4.73336 2.76672 4.33336V2.80002C2.76672 2.00002 3.36672 1.40002 4.10006 1.40002Z"
                              stroke="#52007E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "downloadSelected":
                return (
                    <g>
                        <path d="M19 16.5V25.5L22 22.5" stroke="#52007E" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M19 25.5L16 22.5" stroke="#52007E" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M33.5 15V22.5C33.5 30 30.5 33 23 33H14C6.5 33 3.5 30 3.5 22.5V13.5C3.5 6 6.5 3 14 3H21.5"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33.5 15H27.5C23 15 21.5 13.5 21.5 9V3L33.5 15Z" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "close":
                return (
                    <g>
                        <path d="M18 6L6 18" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "infoIcon":
                return (
                    <g>
                        <path
                            d="M15.3504 8.00039C15.3504 3.9411 12.0597 0.650391 8.00039 0.650391C3.9411 0.650391 0.650391 3.9411 0.650391 8.00039C0.650391 12.0597 3.9411 15.3504 8.00039 15.3504C12.0597 15.3504 15.3504 12.0597 15.3504 8.00039Z"
                            stroke="#75797A" strokeWidth="1.2" strokeMiterlimit="10"/>
                        <circle cx="8.00586" cy="11.8906" r="0.75" fill="#75797A"/>
                        <path
                            d="M5.75781 6.22726C5.75769 5.03334 6.56292 3.8672 8.14556 3.86719C9.72819 3.86717 10.7469 5.4453 9.97808 6.92138C9.28394 8.25412 7.97896 8.64218 7.97896 10.1422"
                            stroke="#75797A" strokeWidth="1.2" strokeLinecap="round"/>
                    </g>
                );
            case "eligible":
                return (
                    <g>
                        <path
                            d="M30.7008 16.0008C30.7008 7.8822 24.1194 1.30078 16.0008 1.30078C7.8822 1.30078 1.30078 7.8822 1.30078 16.0008C1.30078 24.1194 7.8822 30.7008 16.0008 30.7008C24.1194 30.7008 30.7008 24.1194 30.7008 16.0008Z"
                            stroke="#00A4BD" strokeWidth="1.2" strokeMiterlimit="10"/>
                        <path d="M21.4074 12.4645L14.3363 19.5355L10.8008 16" stroke="#00A4BD" strokeWidth="1.2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "global":
                return (
                    <g>
                        <circle cx="12.0001" cy="12" r="11.1" stroke="#999996" strokeWidth="1.2"/>
                        <path
                            d="M11.3572 0.899777C8.72994 0.899777 6.6001 5.86942 6.6001 11.9998C6.6001 18.1301 8.72994 23.0998 11.3572 23.0998"
                            stroke="#999996" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M12.6 0.899777C15.2273 0.899777 17.3572 5.86942 17.3572 11.9998C17.3572 18.1301 15.2273 23.0998 12.6 23.0998"
                            stroke="#999996" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M0.900097 12.6428C0.900097 15.2701 5.86974 17.4 12.0001 17.4C18.1305 17.4 23.1001 15.2701 23.1001 12.6428"
                            stroke="#999996" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M0.900097 11.4001C0.900097 8.77276 5.86974 6.64291 12.0001 6.64291C18.1305 6.64291 23.1001 8.77276 23.1001 11.4001"
                            stroke="#999996" strokeWidth="1.2" strokeLinecap="round"/>
                    </g>
                );
            case "globalSelected":
                return (
                    <g>
                        <circle cx="12.0001" cy="12" r="11.1" stroke="#06135A" strokeWidth="1.2"/>
                        <path
                            d="M11.3572 0.899777C8.72994 0.899777 6.6001 5.86942 6.6001 11.9998C6.6001 18.1301 8.72994 23.0998 11.3572 23.0998"
                            stroke="#06135A" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M12.6 0.899777C15.2273 0.899777 17.3572 5.86942 17.3572 11.9998C17.3572 18.1301 15.2273 23.0998 12.6 23.0998"
                            stroke="#06135A" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M0.900097 12.6428C0.900097 15.2701 5.86974 17.4 12.0001 17.4C18.1305 17.4 23.1001 15.2701 23.1001 12.6428"
                            stroke="#06135A" strokeWidth="1.2" strokeLinecap="round"/>
                        <path
                            d="M0.900097 11.4001C0.900097 8.77276 5.86974 6.64291 12.0001 6.64291C18.1305 6.64291 23.1001 8.77276 23.1001 11.4001"
                            stroke="#06135A" strokeWidth="1.2" strokeLinecap="round"/>
                    </g>
                );
            case "dashboard":
                return (
                    <g id="Category_Dashboard_Icon_UIA">
                        <g id="Group 6241">
                            <rect id="Rectangle 3657" width="11.0769" height="11.0769" rx="2" fill="#999996"/>
                            <rect id="Rectangle 3659" y="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#999996"/>
                            <rect id="Rectangle 3658" x="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#999996"/>
                            <rect id="Rectangle 3660" x="12.9231" y="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#999996"/>
                        </g>
                    </g>
                );
            case "dashboardSelected":
                return (
                    <g id="Category_Dashboard_Icon_UIA">
                        <g id="Group 6241">
                            <rect id="Rectangle 3657" width="11.0769" height="11.0769" rx="2" fill="#06135A"/>
                            <rect id="Rectangle 3659" y="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#06135A"/>
                            <rect id="Rectangle 3658" x="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#06135A"/>
                            <rect id="Rectangle 3660" x="12.9231" y="12.9231" width="11.0769" height="11.0769" rx="2"
                                  fill="#06135A"/>
                        </g>
                    </g>
                );
            case "SNManagement":
                return (
                    <g id="vuesax/linear/clipboard-text">
                        <g id="clipboard-text">
                            <path id="Vector" d="M8 12.2H15" stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_2" d="M8 16.2H12.38" stroke="#999996" strokeWidth="1.5"
                                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_3" d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"
                                  stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path id="Vector_4"
                                  d="M16 4.01999C19.33 4.19999 21 5.42999 21 9.99999V16C21 20 20 22 15 22H9C4 22 3 20 3 16V9.99999C3 5.43999 4.67 4.19999 8 4.01999"
                                  stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </g>
                    </g>
                );
            case "SNManagementSelected":
                return (
                    <g id="vuesax/linear/clipboard-text">
                        <g id="clipboard-text">
                            <path id="Vector" d="M8 12.2H15" stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_2" d="M8 16.2H12.38" stroke="#06135A" strokeWidth="1.5"
                                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_3" d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"
                                  stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path id="Vector_4"
                                  d="M16 4.01999C19.33 4.19999 21 5.42999 21 9.99999V16C21 20 20 22 15 22H9C4 22 3 20 3 16V9.99999C3 5.43999 4.67 4.19999 8 4.01999"
                                  stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </g>
                    </g>
                );
            case "profileAdmin":
                return (
                    <g>
                        <path
                            d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.51001C8.71997 7.70001 10.18 6.23001 12 6.23001C13.81 6.23001 15.28 7.70001 15.28 9.51001C15.27 11.28 13.88 12.72 12.12 12.78Z"
                            stroke="#999996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M18.74 19.38C16.96 21.01 14.6 22 12 22C9.40001 22 7.04001 21.01 5.26001 19.38C5.36001 18.44 5.96001 17.52 7.03001 16.8C9.77001 14.98 14.25 14.98 16.97 16.8C18.04 17.52 18.64 18.44 18.74 19.38Z"
                            stroke="#999996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#999996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "profileAdminSelected":
                return (
                    <g>
                        <path
                            d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.51001C8.71997 7.70001 10.18 6.23001 12 6.23001C13.81 6.23001 15.28 7.70001 15.28 9.51001C15.27 11.28 13.88 12.72 12.12 12.78Z"
                            stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M18.74 19.38C16.96 21.01 14.6 22 12 22C9.40001 22 7.04001 21.01 5.26001 19.38C5.36001 18.44 5.96001 17.52 7.03001 16.8C9.77001 14.98 14.25 14.98 16.97 16.8C18.04 17.52 18.64 18.44 18.74 19.38Z"
                            stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "company":
                return (
                    <g id="vuesax/linear/house-2">
                        <g id="house-2">
                            <path id="Vector" d="M2 22H22" stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_2"
                                  d="M2.94995 22L2.99995 9.96999C2.99995 9.35999 3.28995 8.78004 3.76995 8.40004L10.77 2.95003C11.49 2.39003 12.5 2.39003 13.23 2.95003L20.23 8.39003C20.72 8.77003 21 9.34999 21 9.96999V22"
                                  stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
                            <path id="Vector_3"
                                  d="M15.5 11H8.5C7.67 11 7 11.67 7 12.5V22H17V12.5C17 11.67 16.33 11 15.5 11Z"
                                  stroke="#999996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </g>
                    </g>
                );
            case "companySelected":
                return (
                    <g id="vuesax/linear/house-2">
                        <g id="house-2">
                            <path id="Vector" d="M2 22H22" stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_2"
                                  d="M2.94995 22L2.99995 9.96999C2.99995 9.35999 3.28995 8.78004 3.76995 8.40004L10.77 2.95003C11.49 2.39003 12.5 2.39003 13.23 2.95003L20.23 8.39003C20.72 8.77003 21 9.34999 21 9.96999V22"
                                  stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
                            <path id="Vector_3"
                                  d="M15.5 11H8.5C7.67 11 7 11.67 7 12.5V22H17V12.5C17 11.67 16.33 11 15.5 11Z"
                                  stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </g>
                    </g>
                );
            case "NotEligible":
                return (
                    <g>
                        <path
                            d="M30.7008 16.0008C30.7008 7.8822 24.1194 1.30078 16.0008 1.30078C7.8822 1.30078 1.30078 7.8822 1.30078 16.0008C1.30078 24.1194 7.8822 30.7008 16.0008 30.7008C24.1194 30.7008 30.7008 24.1194 30.7008 16.0008Z"
                            stroke="#F93232" strokeWidth="1.2" strokeMiterlimit="10"/>
                        <path d="M11 11L21 21" stroke="#F93232" strokeWidth="1.2" strokeLinecap="round"/>
                        <path d="M21 11L11 21" stroke="#F93232" strokeWidth="1.2" strokeLinecap="round"/>
                    </g>
                );
            case "xAxis":
                return (
                    <g>
                        <line y1="1.99219" x2="369" y2="1.99219" stroke="#AAAAAA" strokeWidth="2"/>
                    </g>
                );
            case "yAxis":
                return (
                    <g>
                        <mask id="path-1-inside-1_1141_5566" fill="white">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M28.6566 56.0883L14.4987 0L0.34082 56.0883H13.0996V369.001H15.0996V56.0883H28.6566Z"/>
                        </mask>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M28.6566 56.0883L14.4987 0L0.34082 56.0883H13.0996V369.001H15.0996V56.0883H28.6566Z"
                              fill="#00A4BD"/>
                        <path
                            d="M14.4987 0L15.4683 -0.244745L13.5291 -0.244745L14.4987 0ZM28.6566 56.0883V57.0883H29.9403L29.6262 55.8435L28.6566 56.0883ZM0.34082 56.0883L-0.628767 55.8435L-0.942967 57.0883H0.34082V56.0883ZM13.0996 56.0883H14.0996V55.0883H13.0996V56.0883ZM13.0996 369.001H12.0996V370.001H13.0996V369.001ZM15.0996 369.001V370.001H16.0996V369.001H15.0996ZM15.0996 56.0883V55.0883H14.0996V56.0883H15.0996ZM13.5291 0.244745L27.687 56.333L29.6262 55.8435L15.4683 -0.244745L13.5291 0.244745ZM1.31041 56.333L15.4683 0.244745L13.5291 -0.244745L-0.628767 55.8435L1.31041 56.333ZM13.0996 55.0883H0.34082V57.0883H13.0996V55.0883ZM14.0996 369.001V56.0883H12.0996V369.001H14.0996ZM15.0996 368.001H13.0996V370.001H15.0996V368.001ZM14.0996 56.0883V369.001H16.0996V56.0883H14.0996ZM28.6566 55.0883H15.0996V57.0883H28.6566V55.0883Z"
                            fill="#AAAAAA" mask="url(#path-1-inside-1_1141_5566)"/>
                    </g>
                );
            case "alert-triangle-yellow":
                return (
                    <g>
                        <path
                            d="M16 20.125C15.385 20.125 14.875 19.615 14.875 19V11.5C14.875 10.885 15.385 10.375 16 10.375C16.615 10.375 17.125 10.885 17.125 11.5V19C17.125 19.615 16.615 20.125 16 20.125Z"
                            fill="#FFB82E"/>
                        <path
                            d="M16 24.9998C15.91 24.9998 15.805 24.9848 15.7 24.9698C15.61 24.9548 15.52 24.9248 15.43 24.8798C15.34 24.8498 15.25 24.8048 15.16 24.7448C15.085 24.6848 15.01 24.6248 14.935 24.5648C14.665 24.2798 14.5 23.8898 14.5 23.4998C14.5 23.1098 14.665 22.7198 14.935 22.4348C15.01 22.3748 15.085 22.3148 15.16 22.2548C15.25 22.1948 15.34 22.1498 15.43 22.1198C15.52 22.0748 15.61 22.0448 15.7 22.0298C15.895 21.9848 16.105 21.9848 16.285 22.0298C16.39 22.0448 16.48 22.0748 16.57 22.1198C16.66 22.1498 16.75 22.1948 16.84 22.2548C16.915 22.3148 16.99 22.3748 17.065 22.4348C17.335 22.7198 17.5 23.1098 17.5 23.4998C17.5 23.8898 17.335 24.2798 17.065 24.5648C16.99 24.6248 16.915 24.6848 16.84 24.7448C16.75 24.8048 16.66 24.8498 16.57 24.8798C16.48 24.9248 16.39 24.9548 16.285 24.9698C16.195 24.9848 16.09 24.9998 16 24.9998Z"
                            fill="#FFB82E"/>
                        <path
                            d="M25.0094 32H6.98443C4.08436 32 1.86842 30.8819 0.738138 28.8661C-0.37727 26.8504 -0.228549 24.252 1.1843 21.5591L10.1968 4.3937C11.684 1.55906 13.7364 0 15.9969 0C18.2575 0 20.3098 1.55906 21.797 4.3937L30.8095 21.5748C32.2224 24.2677 32.386 26.8504 31.2557 28.8819C30.1254 30.8819 27.9095 32 25.0094 32ZM15.9969 2.3622C14.5989 2.3622 13.2307 3.49606 12.145 5.54331L3.14742 22.7244C2.13612 24.6457 1.97252 26.4094 2.67151 27.685C3.3705 28.9606 4.9172 29.6535 6.9993 29.6535H25.0243C27.1064 29.6535 28.6382 28.9606 29.3521 27.685C30.0659 26.4094 29.8875 24.6614 28.8762 22.7244L19.8488 5.54331C18.7631 3.49606 17.3949 2.3622 15.9969 2.3622Z"
                            fill="#FFB82E"/>
                    </g>
                );
            case "wrongInput":
                return (<g>
                    <path fill="#FFFFFF"
                          d="M15.3499 7.9999C15.3499 3.94061 12.0592 0.649902 7.9999 0.649902C3.94061 0.649902 0.649902 3.94061 0.649902 7.9999C0.649902 12.0592 3.94061 15.3499 7.9999 15.3499C12.0592 15.3499 15.3499 12.0592 15.3499 7.9999Z"
                          stroke="#F93232" strokeWidth="1.2" strokeMiterlimit="10"/>
                    <path d="M5.5 5.5L10.5 10.5" stroke="#F93232" strokeWidth="1.2" strokeLinecap="round"/>
                    <path d="M10.5 5.5L5.5 10.5" stroke="#F93232" strokeWidth="1.2" strokeLinecap="round"/>
                </g>)
            case "plusIcon":
                return (<g>
                    <path d="M1.5 8H15.5" stroke="white" strokeWidth="1.2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M8.5 15L8.5 1" stroke="white" strokeWidth="1.2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>)
            case "plusIconPurple":
                return (<g>
                    <path d="M1 8H15" stroke="#52007E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 15L8 1" stroke="#52007E" strokeWidth="1.2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>)
            case "saveIcon":
                return (<g>
                    <path
                        d="M13.0267 4.36016L11.6667 2.00016C11.04 1.3735 9.50667 1.3335 8.62 1.3335H5.33333C3.33333 1.3335 2 2.66683 2 4.66683V11.3335C2 13.3335 3.33333 14.6668 5.33333 14.6668H10.6667C12.6667 14.6668 14 13.3335 14 11.3335V6.7135C14 5.82683 13.3333 5.3335 13.0267 4.36016Z"
                        stroke="#52007E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M11.3337 14.0002V10.6668C11.3337 8.8335 10.4337 7.3335 9.33366 7.3335H6.66699C5.56699 7.3335 4.66699 8.8335 4.66699 10.6668V14.0002"
                        stroke="#52007E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M4.66634 1.99984V3.33317C4.66634 4.0665 5.56634 4.6665 6.66634 4.6665L9.33301 4.6665C10.433 4.6665 11.333 4.0665 11.333 3.33317V1.99984"
                        stroke="#52007E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>)
            case "deleteIcon":
                return (<g fill={'#FEFEFE'}>
                    <path
                        d="M21.5 5.98047C18.17 5.65047 14.82 5.48047 11.48 5.48047C9.5 5.48047 7.52 5.58047 5.54 5.78047L3.5 5.98047"
                        stroke="#B5B5AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97"
                          stroke="#B5B5AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M19.35 9.13965L18.7 19.2096C18.59 20.7796 18.5 21.9996 15.71 21.9996H9.29002C6.50002 21.9996 6.41002 20.7796 6.30002 19.2096L5.65002 9.13965"
                        stroke="#B5B5AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.83 16.5H14.16" stroke="#B5B5AC" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M10 12.5H15" stroke="#B5B5AC" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>)
            case "deleteIconHovered":
                return (<g fill={'#FEFEFE'}>
                    <path
                        d="M21.5 5.98047C18.17 5.65047 14.82 5.48047 11.48 5.48047C9.5 5.48047 7.52 5.58047 5.54 5.78047L3.5 5.98047"
                        stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97"
                          stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M19.35 9.13965L18.7 19.2096C18.59 20.7796 18.5 21.9996 15.71 21.9996H9.29002C6.50002 21.9996 6.41002 20.7796 6.30002 19.2096L5.65002 9.13965"
                        stroke="#06135A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.83 16.5H14.16" stroke="#06135A" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M10 12.5H15" stroke="#06135A" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>)
            case "fillCRF":
                return (<g fill={'#FEFEFE'}>
                    <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#4C488F"
                          strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M16.0399 3.02025L8.15988 10.9003C7.85988 11.2003 7.55988 11.7903 7.49988 12.2203L7.06988 15.2303C6.90988 16.3203 7.67988 17.0803 8.76988 16.9303L11.7799 16.5003C12.1999 16.4403 12.7899 16.1403 13.0999 15.8403L20.9799 7.96025C22.3399 6.60025 22.9799 5.02025 20.9799 3.02025C18.9799 1.02025 17.3999 1.66025 16.0399 3.02025Z"
                        stroke="#4C488F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path d="M14.9102 4.15039C15.5802 6.54039 17.4502 8.41039 19.8502 9.09039" stroke="#4C488F"
                          strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>)
            case "exclamation":
                return (<g>
                    <path d="M16 9V18" stroke="#4C488F" strokeWidth="1.2" strokeLinecap="round"/>
                    <circle cx="16" cy="22.5" r="1.5" fill="#4C488F"/>
                    <path
                        d="M30.6998 15.9998C30.6998 7.88122 24.1184 1.2998 15.9998 1.2998C7.88122 1.2998 1.2998 7.88122 1.2998 15.9998C1.2998 24.1184 7.88122 30.6998 15.9998 30.6998C24.1184 30.6998 30.6998 24.1184 30.6998 15.9998Z"
                        stroke="#4C488F" strokeWidth="1.2" strokeMiterlimit="10"/>
                </g>)
            case "notificationCRF":
                return (<g>
                    <path
                        d="M16.0268 3.87988C11.6135 3.87988 8.02678 7.46655 8.02678 11.8799V15.7332C8.02678 16.5466 7.68012 17.7865 7.26678 18.4799L5.73345 21.0266C4.78678 22.5999 5.44012 24.3466 7.17345 24.9332C12.9201 26.8532 19.1201 26.8532 24.8668 24.9332C26.4801 24.3999 27.1868 22.4932 26.3068 21.0266L24.7734 18.4799C24.3734 17.7865 24.0268 16.5466 24.0268 15.7332V11.8799C24.0268 7.47988 20.4268 3.87988 16.0268 3.87988Z"
                        stroke="#00A4BD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    <path
                        d="M18.4934 4.26691C18.0801 4.14691 17.6534 4.05358 17.2134 4.00025C15.9334 3.84025 14.7067 3.93358 13.5601 4.26691C13.9467 3.28025 14.9067 2.58691 16.0267 2.58691C17.1467 2.58691 18.1067 3.28025 18.4934 4.26691Z"
                        stroke="#00A4BD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M20.0269 25.4131C20.0269 27.6131 18.2269 29.4131 16.0269 29.4131C14.9335 29.4131 13.9202 28.9598 13.2002 28.2398C12.4802 27.5198 12.0269 26.5064 12.0269 25.4131"
                        stroke="#00A4BD" strokeWidth="1.5" strokeMiterlimit="10"/>
                </g>)
            case "navAfter1":
                return (<g>
                    <rect width="22" height="24" fill="#FEFEFE"/>
                    <path d="M0 0H22V16C22 20.4183 18.4183 24 14 24H0V0Z" fill="#00A4BD"/>
                </g>)
            case "navAfter2":
                return (<g>
                    <rect y="24" width="24" height="22" transform="rotate(-90 0 24)" fill="#FEFEFE"/>
                    <path d="M0 24L0 0H14C18.4183 0 22 3.58172 22 8V24H0Z" fill="#00A4BD"/>
                </g>)
            case "downArrow":
                return (<g>
                    <path d="M3 6L8 11L13 6" stroke="#52007E" strokeWidth="1.2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>)
            case "upArrow":
                return (<g>
                    <path d="M3 6L8 11L13 6" stroke="#52007E" strokeWidth="1.2" strokeLinecap="round"
                          strokeLinejoin="round" transform="rotate(180)"/>
                </g>)
            case "notification":
                return (<g>
                    <path
                        d="M12.0201 2.91016C8.71009 2.91016 6.02009 5.60016 6.02009 8.91016V11.8002C6.02009 12.4102 5.76009 13.3402 5.45009 13.8602L4.30009 15.7702C3.59009 16.9502 4.08009 18.2602 5.38009 18.7002C9.69009 20.1402 14.3401 20.1402 18.6501 18.7002C19.8601 18.3002 20.3901 16.8702 19.7301 15.7702L18.5801 13.8602C18.2801 13.3402 18.0201 12.4102 18.0201 11.8002V8.91016C18.0201 5.61016 15.3201 2.91016 12.0201 2.91016Z"
                        stroke="#4C488F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    <path
                        d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z"
                        stroke="#4C488F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
                        stroke="#4C488F" strokeWidth="1.5" strokeMiterlimit="10"/>
                </g>)
            case "menuBar":
                return (<g>
                    <path d="M1 12H25" stroke="#4C488F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 21H25" stroke="#4C488F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 3H25" stroke="#4C488F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>)

            case "alert-triangle":
                return (
                    <g>
                        <path
                            d="M30.6998 16C30.6998 7.88146 24.1184 1.30005 15.9998 1.30005C7.88122 1.30005 1.2998 7.88146 1.2998 16C1.2998 24.1186 7.88122 30.7001 15.9998 30.7001C24.1184 30.7001 30.6998 24.1186 30.6998 16Z"
                            fill="#FEFEFE" stroke="#F93232" strokeWidth="1.2" strokeMiterlimit="10"/>
                        <path d="M16 9V18" stroke="#F93232" strokeWidth="1.2" strokeLinecap="round"/>
                        <circle cx="16" cy="22.5" r="1.5" fill="#F93232"/>
                    </g>

                )
            case "backGround":
                return (
                    <g>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M159.053 -29.5372C247.557 -32.327 357.931 -38.1915 387.898 34.1167C419.273 109.821 341.618 179.922 264.223 224.226C198.975 261.577 115.894 265.553 52.123 226.329C-14.0412 185.633 -51.41 113.247 -23.7634 47.0928C1.7424 -13.9383 84.2801 -27.1803 159.053 -29.5372Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1762.68 812.419C1692.16 865.974 1605.6 934.706 1539.28 893.136C1469.84 849.614 1492.52 747.485 1529.93 666.531C1561.46 598.284 1626.87 546.904 1701.58 541.923C1779.08 536.755 1851.48 574.099 1867.28 644.036C1881.85 708.556 1822.25 767.173 1762.68 812.419Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1597.02 252.158C1614.34 288.458 1636.92 333.293 1612.32 358.953C1586.56 385.82 1543.23 366.317 1510.68 342.214C1483.23 321.895 1466.42 288.067 1471.09 254.385C1475.94 219.44 1499.22 190.687 1531.78 190.111C1561.82 189.58 1582.39 221.49 1597.02 252.158Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M916.022 731.159C933.336 767.459 955.92 812.293 931.316 837.954C905.557 864.82 862.228 845.317 829.676 821.215C802.233 800.895 785.416 767.067 790.09 733.386C794.94 698.44 818.225 669.687 850.784 669.111C880.823 668.58 901.394 700.49 916.022 731.159Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1290.41 343.559C1299.16 361.917 1310.59 384.592 1298.14 397.57C1285.12 411.158 1263.2 401.294 1246.74 389.104C1232.86 378.828 1224.35 361.719 1226.72 344.685C1229.17 327.011 1240.95 312.469 1257.41 312.178C1272.61 311.91 1283.01 328.048 1290.41 343.559Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M329.784 541.116C312.286 551.485 290.728 564.896 276.685 553.668C261.983 541.913 269.839 519.203 280.502 501.712C289.491 486.966 305.767 476.959 322.945 477.785C340.767 478.641 356.308 489.064 358.076 505.438C359.708 520.545 344.568 532.356 329.784 541.116Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1395.41 813.559C1404.16 831.918 1415.59 854.592 1403.14 867.57C1390.12 881.158 1368.2 871.294 1351.74 859.105C1337.86 848.828 1329.35 831.719 1331.72 814.685C1334.17 797.012 1345.95 782.47 1362.41 782.178C1377.61 781.91 1388.01 798.049 1395.41 813.559Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1544.49 477.753C1542.87 498.028 1541.39 523.374 1524.12 528.367C1506.03 533.595 1492.01 514.077 1483.88 495.278C1477.02 479.429 1478.23 460.361 1488.82 446.806C1499.8 432.742 1517.28 426.06 1531.67 434.062C1544.95 441.444 1545.87 460.624 1544.49 477.753Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M397.408 312.559C406.165 330.917 417.586 353.592 405.143 366.57C392.115 380.158 370.202 370.294 353.739 358.104C339.86 347.828 331.354 330.719 333.718 313.685C336.171 296.011 347.948 281.469 364.414 281.178C379.606 280.91 390.01 297.048 397.408 312.559Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1794.52 71.7451C1754.46 68.1883 1704.37 64.8283 1694.8 30.5929C1684.77 -5.25044 1723.6 -32.6356 1760.9 -48.4029C1792.36 -61.6954 1830.04 -58.9685 1856.66 -37.8126C1884.28 -15.8625 1897.19 18.8106 1881.13 47.1366C1866.31 73.2691 1828.37 74.75 1794.52 71.7451Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M38.8138 801.069C6.73642 776.81 -33.9476 747.4 -23.9143 713.295C-13.4097 677.588 34.0328 674.961 74.0253 681.378C107.741 686.788 138.24 709.081 149.591 741.134C161.369 774.391 153.929 810.635 125.288 826.131C98.8644 840.427 65.9145 821.564 38.8138 801.069Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M579.85 485.579C547.773 461.32 507.089 431.91 517.122 397.805C527.626 362.098 575.069 359.471 615.061 365.888C648.777 371.298 679.276 393.591 690.627 425.644C702.405 458.901 694.965 495.145 666.324 510.641C639.901 524.937 606.951 506.074 579.85 485.579Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M658.243 796.377C625.325 773.159 582.875 745.938 614.687 684.846C647.994 620.885 716.55 592.095 769.887 581.977C814.852 573.446 846.549 594.255 846.086 640.948C845.606 689.393 816.364 752.653 767.798 792.839C722.992 829.913 686.054 815.992 658.243 796.377Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M128.47 447.937C109.379 450.429 85.6222 454.053 77.5953 439.047C69.1914 423.336 84.4984 406.511 100.299 395.273C113.621 385.799 131.512 383.166 146.148 390.293C161.332 397.687 170.962 412.552 166.392 427.456C162.175 441.206 144.598 445.832 128.47 447.937Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M511.224 858.057C499.01 872.939 484.168 891.84 468.045 886.392C451.165 880.688 450.811 857.945 454.641 838.937C457.87 822.913 469.114 808.748 484.662 803.928C500.794 798.927 517.989 803.175 524.856 817.17C531.192 830.081 521.543 845.483 511.224 858.057Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1838.86 357.941C1819.65 359.233 1795.71 361.361 1788.64 345.882C1781.24 329.675 1797.57 313.842 1814.04 303.616C1827.93 294.995 1845.95 293.488 1860.11 301.517C1874.81 309.847 1883.49 325.287 1877.99 339.875C1872.92 353.334 1855.09 356.85 1838.86 357.941Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1582.95 132.313C1564.41 127.112 1541.14 121.12 1539.65 104.167C1538.08 86.4182 1558.77 76.9517 1577.71 72.8179C1593.68 69.3329 1611.17 73.934 1621.83 86.233C1632.9 98.9936 1635.92 116.446 1625.87 128.36C1616.59 139.351 1598.61 136.707 1582.95 132.313Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1389.33 562.167C1381.68 562.681 1372.15 563.529 1369.33 557.364C1366.38 550.911 1372.89 544.606 1379.45 540.534C1384.98 537.101 1392.15 536.501 1397.79 539.698C1403.64 543.015 1407.1 549.163 1404.91 554.972C1402.89 560.332 1395.79 561.732 1389.33 562.167Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1343.64 139.025C1339.85 145.69 1335.3 154.109 1328.61 153.059C1321.6 151.96 1319.92 143.059 1320.14 135.341C1320.33 128.834 1323.79 122.516 1329.56 119.575C1335.56 116.524 1342.59 117.03 1346.23 122.059C1349.59 126.698 1346.84 133.394 1343.64 139.025Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1102.64 34.0249C1098.85 40.6899 1094.3 49.1094 1087.61 48.0596C1080.6 46.9606 1078.92 38.0589 1079.14 30.3409C1079.33 23.8342 1082.79 17.5164 1088.56 14.5753C1094.56 11.5238 1101.59 12.0302 1105.23 17.0589C1108.59 21.6982 1105.84 28.3941 1102.64 34.0249Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M361.64 668.025C357.851 674.69 353.302 683.109 346.607 682.06C339.598 680.961 337.923 672.059 338.143 664.341C338.329 657.834 341.785 651.516 347.562 648.575C353.555 645.524 360.59 646.03 364.23 651.059C367.588 655.698 364.841 662.394 361.64 668.025Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M1176.64 846.025C1172.85 852.69 1168.3 861.109 1161.61 860.06C1154.6 858.961 1152.92 850.059 1153.14 842.341C1153.33 835.834 1156.79 829.516 1162.56 826.575C1168.56 823.524 1175.59 824.03 1179.23 829.059C1182.59 833.698 1179.84 840.394 1176.64 846.025Z"
                              stroke="#FEFEFE"/>
                        <path fill="#00A4BD" fillRule="evenodd" clipRule="evenodd"
                              d="M935.429 -34.7628C995.811 28.498 1072.89 105.799 1038.76 176.081C1003.03 249.663 899.642 239.284 815.648 211.888C744.838 188.792 687.145 130.313 674.359 57.227C661.092 -18.6022 690.405 -94.3817 757.905 -118.166C820.177 -140.108 884.415 -88.209 935.429 -34.7628Z"
                              stroke="#FEFEFE"/>
                    </g>
                );
            case "glass":
                return (
                    <g fill="none">
                        <path
                            d="M7.66659 14.0002C11.1644 14.0002 13.9999 11.1646 13.9999 7.66683C13.9999 4.16903 11.1644 1.3335 7.66659 1.3335C4.16878 1.3335 1.33325 4.16903 1.33325 7.66683C1.33325 11.1646 4.16878 14.0002 7.66659 14.0002Z"
                            stroke="#52007E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.6666 14.6668L13.3333 13.3335" stroke="#52007E" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "line":
                return (
                    <g fill="none">
                        <line x1="0.5" x2="0.5" y2="42" stroke="#919495" strokeOpacity="0.3"/>
                    </g>
                );
            case "edit":
                return (
                    <g fill="none">
                        <path
                            d="M13.26 3.60022L5.04997 12.2902C4.73997 12.6202 4.43997 13.2702 4.37997 13.7202L4.00997 16.9602C3.87997 18.1302 4.71997 18.9302 5.87997 18.7302L9.09997 18.1802C9.54997 18.1002 10.18 17.7702 10.49 17.4302L18.7 8.74022C20.12 7.24022 20.76 5.53022 18.55 3.44022C16.35 1.37022 14.68 2.10022 13.26 3.60022Z"
                            stroke="#B5B5AC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M11.89 5.0498C12.32 7.8098 14.56 9.9198 17.34 10.1998" stroke="#B5B5AC"
                              strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 22H21" stroke="#B5B5AC" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "editHovered":
                return (
                    <g fill="none">
                        <path
                            d="M13.26 3.60022L5.04997 12.2902C4.73997 12.6202 4.43997 13.2702 4.37997 13.7202L4.00997 16.9602C3.87997 18.1302 4.71997 18.9302 5.87997 18.7302L9.09997 18.1802C9.54997 18.1002 10.18 17.7702 10.49 17.4302L18.7 8.74022C20.12 7.24022 20.76 5.53022 18.55 3.44022C16.35 1.37022 14.68 2.10022 13.26 3.60022Z"
                            stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M11.89 5.0498C12.32 7.8098 14.56 9.9198 17.34 10.1998" stroke="#06135A"
                              strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 22H21" stroke="#06135A" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "calendar":
                return (
                    <g fill="none">
                        <g clipPath="url(#clip0_832_2278)">
                            <path d="M5.09082 1.22705V3.40887" stroke="#52007E" strokeWidth="1.2" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.9092 1.22705V3.40887" stroke="#52007E" strokeWidth="1.2" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.81836 6.38379H14.182" stroke="#52007E" strokeWidth="1.2" strokeMiterlimit="10"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M14.5455 5.95472V12.1365C14.5455 14.3184 13.4546 15.7729 10.9091 15.7729H5.09095C2.5455 15.7729 1.45459 14.3184 1.45459 12.1365V5.95472C1.45459 3.7729 2.5455 2.31836 5.09095 2.31836H10.9091C13.4546 2.31836 14.5455 3.7729 14.5455 5.95472Z"
                                stroke="#52007E" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M10.6873 9.73619H10.6938" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M10.6873 11.9183H10.6938" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M7.99685 9.73619H8.00338" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M7.99685 11.9183H8.00338" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M5.30495 9.73619H5.31149" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M5.30495 11.9183H5.31149" stroke="#52007E" strokeWidth="1.4" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_832_2278">
                                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                        </defs>
                    </g>
                );
            case "gloveBox":
                return (
                    <g fill="none">
                        <path
                            d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="#17597C" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 22.08V12" stroke="#17597C" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "power":
                return (
                    <g fill="none">
                        <path
                            d="M18.3601 6.63965C19.6185 7.89844 20.4754 9.50209 20.8224 11.2478C21.1694 12.9936 20.991 14.803 20.3098 16.4474C19.6285 18.0918 18.4749 19.4972 16.9949 20.486C15.515 21.4748 13.775 22.0026 11.9951 22.0026C10.2152 22.0026 8.47527 21.4748 6.99529 20.486C5.51532 19.4972 4.36176 18.0918 3.68049 16.4474C2.99921 14.803 2.82081 12.9936 3.16784 11.2478C3.51487 9.50209 4.37174 7.89844 5.63012 6.63965"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 2V12" stroke="#17597C" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );
            case "activityLevel":
                return (
                    <g fill="none">
                        <path d="M21.5 10H17.5L14.5 19L8.5 1L5.5 10H1.5" stroke="#17597C" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "toogleRight":
                return (
                    <g clipPath="url(#clip0_625_931)" fill="none">
                        <path
                            d="M16 1H8C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15H16C19.866 15 23 11.866 23 8C23 4.13401 19.866 1 16 1Z"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11Z"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "sitePlace":
                return (
                    <g fill="none">
                        <path
                            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                            stroke="#17597C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "email":
                return (
                    <g fill="none">
                        <path
                            d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 6L12.2571 13L21 6" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                );

            case "back":
                return (
                    <g fill="none">
                        <path d="M26 20L22 24L26 28" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#CEDDF8"/>
                    </g>
                );
            case "update":
                return (
                    <g fill="none">
                        <path d="M10 16H5V21" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M19.4176 14.9971C18.8569 16.3848 17.9182 17.5874 16.7081 18.4682C15.498 19.3491 14.065 19.8727 12.5721 19.9797C11.0792 20.0868 9.58624 19.7728 8.26287 19.0736C6.9395 18.3744 5.83882 17.318 5.08594 16.0244"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 8H19V3" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M4.58203 9.00315C5.14272 7.61541 6.08146 6.41278 7.29157 5.53197C8.50169 4.65116 9.93462 4.12749 11.4275 4.02048C12.9204 3.91346 14.4134 4.2274 15.7368 4.92661C17.0601 5.62582 18.1608 6.68226 18.9137 7.97584"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                );
            case "organizations":
                return (
                    <g clipPath="url(#clip0_1959_1637)">
                        <path d="M2 20H22" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M7 10H11" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M20 20V20.75C20.4142 20.75 20.75 20.4142 20.75 20H20ZM14 20H13.25C13.25 20.4142 13.5858 20.75 14 20.75V20ZM15 9.75H19V8.25H15V9.75ZM19.25 10V20H20.75V10H19.25ZM20 19.25H14V20.75H20V19.25ZM14.75 20V10H13.25V20H14.75ZM19 9.75C19.1381 9.75 19.25 9.86193 19.25 10H20.75C20.75 9.0335 19.9665 8.25 19 8.25V9.75ZM15 8.25C14.0335 8.25 13.25 9.0335 13.25 10H14.75C14.75 9.86193 14.8619 9.75 15 9.75V8.25Z"
                            fill="#0957DE"/>
                        <path d="M7 7L11 7" stroke="#0957DE" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M14 20V20.75C14.4142 20.75 14.75 20.4142 14.75 20H14ZM4 20H3.25C3.25 20.4142 3.58579 20.75 4 20.75V20ZM5 3.75H13V2.25H5V3.75ZM13.25 4V20H14.75V4H13.25ZM14 19.25H4V20.75H14V19.25ZM4.75 20V4H3.25V20H4.75ZM13 3.75C13.1381 3.75 13.25 3.86193 13.25 4H14.75C14.75 3.0335 13.9665 2.25 13 2.25V3.75ZM5 2.25C4.0335 2.25 3.25 3.0335 3.25 4H4.75C4.75 3.86193 4.86193 3.75 5 3.75V2.25Z"
                            fill="#0957DE"/>
                    </g>
                );
            case "clinicians":
                return (
                    <g fill="none">
                        <path d="M4 21C4 18.2386 7.58172 16 12 16C16.4183 16 20 18.2386 20 21" stroke="#1D355C"
                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "devices":
                return (
                    <g fill="none">
                        <path
                            d="M6.81201 4C6.35686 4 5.95913 4.30736 5.84434 4.7478L3.03232 15.5375C3.01086 15.6198 2.99714 15.705 3.00877 15.7893C3.05036 16.0906 3.23745 16.3598 3.51962 16.5141L11.4804 20.8681C11.8019 21.044 12.1981 21.044 12.5196 20.8681L20.4804 16.5141C20.7626 16.3598 20.9496 16.0906 20.9912 15.7893C21.0029 15.705 20.9891 15.6198 20.9677 15.5375L18.1557 4.7478C18.0409 4.30736 17.6431 4 17.188 4L6.81201 4Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "fail":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="15.5" stroke="#FFDADF"/>
                        <path d="M12.5 19.5L19.5 12.5" stroke="#FF8393" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M19.5 19.5L12.5 12.5" stroke="#FF8393" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "play":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#E5F9F3"/>
                        <path
                            d="M12 11.7788C12 11.0022 12.848 10.5232 13.5131 10.9241L20.5172 15.1453C21.1609 15.5333 21.1609 16.4668 20.5172 16.8548L13.5131 21.0759C12.848 21.4768 12 20.9978 12 20.2212V11.7788Z"
                            fill="#1DC198" stroke="#1DC198" strokeWidth="1.33063" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>

                );
            case "gantt":
                return (
                    <g fill={'none'}>
                        <path
                            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 2V6" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M8 2V6" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M3 10H21" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "sites":
                return (
                    <g fill={'none'}>
                        <path
                            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "chambers":
                return (
                    <g fill={'none'}>
                        <path
                            d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12" stroke={this.props.fill} strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 5V19C3 20.66 7 22 12 22C17 22 21 20.66 21 19V5" stroke={this.props.fill}
                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "orders":
                return (
                    <g fill={'none'}>
                        <path d="M22 12H16L14 15H10L8 12H2" stroke={this.props.fill} strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "logout":
                return (
                    <g fill={'none'}>
                        <path
                            d="M10 12L19 12M19 12L17 10M19 12L17 14M15 16L15 19C15 20.1046 14.1046 21 13 21L7 21C5.89543 21 5 20.1046 5 19L5 5C5 3.89543 5.89543 3 7 3L13 3C14.1046 3 15 3.89543 15 5L15 8"
                            stroke="#999996" strokeWidth="1.5" strokeLinecap="round"/>
                    </g>

                );
            case "users":
                return (
                    <g fill={'none'}>
                        <path
                            d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799"
                            stroke={this.props.fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "configurations":
                return (
                    <g fill={'none'}>
                        <path d="M4 21V14" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M4 10V3" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M12 21V12" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M12 8V3" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M20 21V16" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M20 12V3" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M1 14H7" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M9 8H15" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M17 16H23" stroke={this.props.fill} strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "arrowBlackDown":
                return (
                    <g fill="none">
                        <path d="M11 10L15 14L19 10" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );
            case "arrowBlackUp":
                return (
                    <g fill="none">
                        <path d="M11 14L15 10L19 14" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>

                );

            case "blackEye":
                return (
                    <g fill="none">
                        <path
                            d="M2.58631 8.77881C4.36575 10.5478 7.46904 12.9999 10.9997 12.9999C14.5303 12.9999 17.6331 10.5478 19.4125 8.77881C19.8818 8.31226 20.1172 8.07819 20.2667 7.62012C20.3733 7.29328 20.3733 6.70674 20.2667 6.3799C20.1172 5.92181 19.8818 5.6877 19.4125 5.22111C17.633 3.45208 14.5303 1 10.9997 1C7.46904 1 4.36575 3.45208 2.58631 5.22111C2.11665 5.68802 1.8818 5.92165 1.73231 6.3799C1.62569 6.70673 1.62569 7.29328 1.73231 7.62012C1.8818 8.07837 2.11665 8.31189 2.58631 8.77881Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M8.99951 7C8.99951 8.10457 9.89494 9 10.9995 9C12.1041 9 12.9995 8.10457 12.9995 7C12.9995 5.89543 12.1041 5 10.9995 5C9.89494 5 8.99951 5.89543 8.99951 7Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "logOut":
                return (
                    <g fill="none">
                        <path d="M12 15L15 12M15 12L12 9M15 12L4 12" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M9 7V5C9 4.44772 9.44772 4 10 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H10C9.44771 20 9 19.5523 9 19V17"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "note":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#FFF4CC"/>
                        <path
                            d="M20.667 10.667H11.3337C10.9655 10.667 10.667 10.9655 10.667 11.3337V20.667C10.667 21.0352 10.9655 21.3337 11.3337 21.3337H17.0575C17.2343 21.3337 17.4039 21.2634 17.5289 21.1384L21.1384 17.5289C21.2634 17.4039 21.3337 17.2343 21.3337 17.0575V11.3337C21.3337 10.9655 21.0352 10.667 20.667 10.667Z"
                            stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21.3337 16.667H17.3337C16.9655 16.667 16.667 16.9655 16.667 17.3337V21.3337"
                              stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "trash":
                return (
                    <g fill="none">
                        <path d="M13.6665 10.334L13.6665 16.1673" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.3335 10.334L10.3335 16.1673" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M17 7H7V18.6667C7 19.1269 7.3731 19.5 7.83333 19.5H16.1667C16.6269 19.5 17 19.1269 17 18.6667V7Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M5.3335 7H18.6668" stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path
                            d="M14.4998 4.5H9.49984C9.0396 4.5 8.6665 4.8731 8.6665 5.33333V7H15.3332V5.33333C15.3332 4.8731 14.9601 4.5 14.4998 4.5Z"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "eye":
                return (
                    <g fill="none">
                        <path
                            d="M1.21956 8.82269C0.926816 8.31282 0.926814 7.6861 1.21955 7.17623C2.75984 4.49343 5.22354 2.75586 8.00001 2.75586C10.7765 2.75586 13.2402 4.4934 14.7804 7.17617C15.0732 7.68604 15.0732 8.31276 14.7804 8.82264C13.2402 11.5054 10.7765 13.243 7.99999 13.243C5.22353 13.243 2.75985 11.5055 1.21956 8.82269Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>
                        <path
                            d="M9.96628 7.99942C9.96628 9.08539 9.08592 9.96575 7.99994 9.96575C6.91397 9.96575 6.03361 9.08539 6.03361 7.99942C6.03361 6.91344 6.91397 6.03308 7.99994 6.03308C9.08592 6.03308 9.96628 6.91344 9.96628 7.99942Z"
                            stroke="#8996AA" strokeWidth="1.47475"/>
                    </g>

                );
            case "link":
                return (
                    <g fill="none">
                        <path d="M9.17188 14.8287L14.8287 9.17188" stroke="#1D355C" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M7.05014 11.293L5.63593 12.7072C4.07383 14.2693 4.07383 16.8019 5.63593 18.364C7.19803 19.9261 9.73069 19.9261 11.2928 18.364L12.707 16.9498"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M11.293 7.05063L12.7072 5.63642C14.2693 4.07432 16.8019 4.07432 18.364 5.63642C19.9261 7.19851 19.9261 9.73117 18.364 11.2933L16.9498 12.7075"
                            stroke="#1D355C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "files":
                return (
                    <g fill="none">
                        <path
                            d="M43.3333 70L13.3333 70C11.4924 70 10 68.5076 10 66.6667L10 23.3333C10 21.4924 11.4924 20 13.3333 20L31.9526 20C32.8367 20 33.6845 20.3512 34.3097 20.9763L45.6904 32.357C46.3155 32.9821 46.6667 33.83 46.6667 34.714V66.6667C46.6667 68.5076 45.1743 70 43.3333 70Z"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M33.333 20L33.333 13.3333C33.333 11.4924 34.8254 10 36.6663 10L55.2856 10C56.1697 10 57.0175 10.3512 57.6427 10.9763L69.0234 22.357C69.6485 22.9821 69.9997 23.83 69.9997 24.714V56.6667C69.9997 58.5076 68.5073 60 66.6663 60L46.6663 60"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M46.6667 36.6667L33.3333 36.6667C31.4924 36.6667 30 35.1743 30 33.3333L30 20"
                              stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M69.9997 26.6667L56.6663 26.6667C54.8254 26.6667 53.333 25.1743 53.333 23.3333L53.333 10"
                            stroke="#1D355C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>

                );
            case "pause":
                return (
                    <g fill="none">
                        <circle cx="16" cy="16" r="16" fill="#E5F9F3"/>
                        <path
                            d="M0 16C0 19.1645 0.938383 22.2579 2.69649 24.8891C4.45459 27.5203 6.95345 29.5711 9.87707 30.7821C12.8007 31.9931 16.0177 32.3099 19.1214 31.6926C22.2251 31.0752 25.0761 29.5513 27.3137 27.3137C29.5513 25.0761 31.0752 22.2251 31.6926 19.1214C32.3099 16.0177 31.9931 12.8007 30.7821 9.87706C29.5711 6.95345 27.5203 4.45459 24.8891 2.69649C22.2579 0.938384 19.1645 -3.77363e-08 16 0V2.02191C18.7646 2.02191 21.4671 2.84171 23.7658 4.37764C26.0645 5.91357 27.8561 8.09665 28.9141 10.6508C29.972 13.205 30.2489 16.0155 29.7095 18.727C29.1702 21.4385 27.8389 23.9291 25.884 25.884C23.9291 27.8389 21.4385 29.1702 18.727 29.7095C16.0155 30.2489 13.205 29.972 10.6508 28.9141C8.09665 27.8561 5.91357 26.0645 4.37764 23.7658C2.84171 21.4671 2.02191 18.7646 2.02191 16H0Z"
                            fill="#18A280"/>
                        <path
                            d="M18 11.667C18 11.1147 18.4477 10.667 19 10.667H21C21.5523 10.667 22 11.1147 22 11.667V20.3337C22 20.8859 21.5523 21.3337 21 21.3337H19C18.4477 21.3337 18 20.8859 18 20.3337V11.667Z"
                            fill="#1DC198"/>
                        <path
                            d="M10 11.667C10 11.1147 10.4477 10.667 11 10.667H13C13.5523 10.667 14 11.1147 14 11.667V20.3337C14 20.8859 13.5523 21.3337 13 21.3337H11C10.4477 21.3337 10 20.8859 10 20.3337V11.667Z"
                            fill="#1DC198"/>
                    </g>

                );

            case "alert":
                return (
                    <g>
                        <g clipPath="url(#clip0_214_4342)" fill={'none'}>
                            <path
                                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                                stroke="#FF3932" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 5.33334V8.00001" stroke="#FF3932" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 10.6667H8.00667" stroke="#FF3932" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_214_4342">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </g>
                );

            default:
                return ""
        }
    }

    render() {
        let {
            name = null,
            svgname = "",
            style = {},
            onClick = null,
            fill = "rgb(0,0,0)",
            viewBox = "",
            width = "100%",
            className = "mhd-icons",
            subClass = "",
            size = '',
            height = "100%",
            onMouseEnter,
            onMouseLeave,
            onMouseDown,
            onMouseUp
        } = this.props
        return <svg
            name={name ? name : 'oppa'}
            width={width}
            style={style}
            height={height}
            onMouseEnter={e => !!onMouseEnter ? onMouseEnter(e) : {}}
            onMouseLeave={e => !!onMouseLeave ? onMouseLeave(e) : {}}
            onMouseDown={e => !!onMouseDown ? onMouseDown(e) : {}}
            onMouseUp={e => !!onMouseUp ? onMouseUp(e) : {}}
            onClick={onClick}
            type={"image/svg+xml"}
            className={`${className} ${size} ${subClass}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || this.getViewBox(svgname)}
            fill={fill === "" ? "rgb(0,0,0)" : fill}
        >
            {this.getPath(svgname, this.props)}
        </svg>
    }
}

export default SVGraphics;
