import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Table} from "../../components/table/table";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import utils from "../../utils/utils";
import {TableRowOptions} from "../../components/table/TableRowOptions";
import SVGraphics from "../../assets/SVGraphics";


const AdminManagementComp = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([{}]);
    const [filtersObj, setFiltersObj] = useState({});
    const [filterInputs, setFilterInputs] = useState([
        {
            key: "Role",
            name: "Role",
            placeholder: "Role",
            type: "Select",
            data: [
                {value: "Admin", label: "Admin"},
                {value: "Distributor", label: "Distributor"},
                {value: "Call Center", label: "Call Center"}]
        },
        {key: "Site", name: "Site", placeholder: "Site", type: "Select", data: []}
    ])

    useEffect(() => {
        fetchData({}).then(p => {
            let sitesOption = []
            getAllSites({}).then(sites => {
                sites.data.map(item => {
                    sitesOption.push({value: item['Site_index'], label: item['Site_name']})
                })
                filterInputs[1]['data'] = sitesOption
                setFilterInputs([...filterInputs])
                setData(p)
            })

        })

    }, [])


    const setData = (p) => {

        let users = p.data
        for (const user of users) {
            let selectedSites = []
            let selectedCompanies = []
            let userSites = user['userSites']?user['userSites']:[]
            let userCompanies = user['userCompanies']?user['userCompanies']:[]
            for (const userSite of userSites) {
                selectedSites.push(userSite['site'].Site_name)
            }
            for (const userCompany of userCompanies) {
                selectedCompanies.push(userCompany['company'].Company_name)
            }
            user['selectedCompanies'] = selectedCompanies
            user['selectedSites'] = selectedSites
            user['Companies_text'] = selectedCompanies.join(',')
            user['Sites_text'] = selectedSites.join(',')
        }
        setUsers([...users])
        setFilteredData([...users])
        setIsLoading(false)
    }

    const fetchData = async (filters) => {
        return await props.restApi.getAllUsers(filters)
    }

    const getAllSites = async () => {
        return await props.restApi.getAllSites()
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'First_name',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`)}>
                    {cell.row.original?.First_name}
                </div>)
            }
        },
        {
            Header: 'Last Name',
            accessor: 'Last_name',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`)}>
                    {cell.row.original?.Last_name}
                </div>)
            }
        },
        {
            Header: 'Email',
            accessor: 'Email',
            headerClassName: 'w-[20.25rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`)}>
                    {cell.row.original?.Email}
                </div>)
            }
        },
        {
            Header: 'Company',
            accessor: 'Companies_text',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (
                    <TableRowOptions
                        onButtonClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`, {state: cell.row.original})}
                        options={cell.row.original.selectedCompanies}/>
                )
            }
        },

        {
            Header: 'Site',
            accessor: 'Sites_text',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (
                    <TableRowOptions
                        onButtonClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`, {state: cell.row.original})}
                        options={cell.row.original.selectedSites}/>
                )
            }
        },
        {
            Header: 'Role',
            accessor: 'Role',
            headerClassName: 'w-[8.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`)}>
                    {cell.row.original?.Role}
                </div>)
            }
        },
    {
            Header: 'Lock',
            accessor: 'Lock',
        headerClassName: 'w-[5.25rem] ',
        Cell: (cell) => {
                return (
                    <SVGraphics
                        fill={'none'}
                        className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                        svgname={cell.row.original['Enabled']?'':'keyLock'}/>)

            }
        },    {
            Header: 'Status',
            accessor: 'Status',
            Cell: (cell) => {
                let isEnabled = cell.row.original['Enabled']
                let textStyle= isEnabled?'cursor-pointer':'cursor-pointer text-[#999996]'
                return (<div className={textStyle}
                             onClick={() => navigate(`/${props.rolePath}/AdminManagement/editAdmin/${cell.row.original['UserID']}`)}>
                    {isEnabled? 'Active':'Disable'}
                </div>)
            }
        },


    ]
    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = users.filter(user => {
                let sites = user['selectedSites']
                for (const siteElement of sites) {
                    if (String(siteElement).toLowerCase().indexOf(filterValue) > -1) {
                        return true
                    }
                }
                let companies = user['selectedCompanies']
                for (const companyElement of companies) {
                    if (String(companyElement).toLowerCase().indexOf(filterValue) > -1) {
                        return true
                    }
                }

               return String(user['First_name']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Last_name']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Email']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Role']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Company_name']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Site_name']).toLowerCase().indexOf(filterValue) > -1
            }
        )
        setFilteredData(filteredData)
    }

    const onFilterChange = async (name, value) => {
        let filters = {...filtersObj}
        filters[name] = value
        if(((name ==="Site" || name ==="Role") && value.indexOf("All")>=0 )||value.length===0){
            delete filters[name]
        }
        setFiltersObj({...filters})
        fetchData(filters).then(p => {
            setData(p)
        })
    }
    const clearFilters = async () => {
        let filters = {}
        setFiltersObj({...filters})
        fetchData(filters).then(p => {
            setData(p)
        })
    }

    const onDownloadClick = () => {
        let headers = ["Date","First Name", "Last Name", "Email", "Company", "Site", "Role","Lock","Status"]
        let dataToAdd = filteredData.map(item => {
            let Lock =!!item['Enabled']
            let status = item['Enabled']?"Active":"Disable"
            return [
                new Date(item['createdAt']),
                item.First_name,
                item.Last_name,
                item.Email,
                item['selectedCompanies'].join(","),
                item['selectedSites'].join(","),
                item.Role,
                Lock,
                status
            ]
        })
        utils.generateReport("ohhmed_users_table", headers, dataToAdd)

    }


    return (
        <div className="w-full px-[3.75rem] py-[3rem]">

            <BodyHeader title={"Admin Management"} includeButton={true} buttonText={" New Admin"}
                        includePlus={true} readOnly={props.readOnly}
                        OnButtonClick={() => navigate(`/${props.rolePath}/AdminManagement/AddAdmin`)}/>
            <div
                className="  h-fit  font-Poppins rounded-lg ">

                <BodySearchFilter
                                  onSearchChange={(searchText) => onSearchChange(searchText)}
                                  onFilterChange={(name, value) => onFilterChange(name, value)}
                                  filtersObj={filtersObj}
                                  onDownloadClick={() => onDownloadClick()}
                                  hasSearch={true}
                                  hasFilter={true}
                                  hasDownload={true}
                                  clearFilters={() => clearFilters()}
                                  hasClearInRow={true}
                                  filterInputs={filterInputs}
                />

                <div className={"mt-[1.5rem]"}>
                    <Table data={filteredData} columns={columns} isLoading={isLoading}/>
                </div>


            </div>

        </div>
    )
}

export default AdminManagementComp;
