import React, {useEffect, useState} from "react";
import BodyHeader from "../../../components/customComp/BodyHeader";
import {useNavigate, useParams} from "react-router-dom";
import DeviceProfile from "./DeviceProfile";
import TreatmentHistoryNum from "./TreatmentHistoryNum";
import DeviceGeneralSettings from "./DeviceGeneralSettings";
import DeviceSelfTestHistory from "./DeviceSelfTestHistory";
import DeviceTreatmentHistory from "./DeviceTreatmentHistory";
import LastTreatmentRing from "./LastTreatmentRing";
import ringPhoto from "../../../assets/ring.svg"
import ringPadPhoto from "../../../assets/ringPad.svg"
import padPhoto from "../../../assets/pad.svg"
import BodySearchFilter from "../../../components/customComp/BodySearchFilter";
import utils from "../../../utils/utils";
import AlertConfirm from "../../../components/modals/Confirm";

const DeviceTreatmentsMain = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [currentDevice, setCurrentDevice] = useState({patient:{patientSetting:{}},site:{}})
    const [patientPhone, setPatientPhone] = useState("")
    const [lastWeekTreatments, setLastWeekTreatments] = useState(0)
    const [lastMonthTreatments, setLastMonthTreatments] = useState(0)
    const [currentSelfTests, setCurrentSelfTests] = useState([])
    const [patientTreatments, setPatientTreatments] = useState([])
    const [currentRingTreatments, setCurrentRingTreatments] = useState([])
    const [currentRingPadTreatments, setCurrentRingPadTreatments] = useState([])
    const [devicesHistory, setDevicesHistory] = useState([])
    const [lastRingTreatment, setLastRingTreatment] = useState({patientTreatment:{}})
    const [lastRingPadTreatment, setLastRingPadTreatment] = useState({patientTreatment:{}})
    const [editPhone, setEditPhone] = useState(false)
    useEffect(() => {

        fetchData().then(p => {
            let lastRing={patientTreatment:{}}
            let lastRingPad={patientTreatment:{}}
            if(p.data){
                let deviceData = p.data['device']
                let treatmentRings = p.data['treatmentRings']
                let treatmentRingPads = p.data['treatmentRingPads']

                let devicesHistoryArr = p.data['devicesHistory']?p.data['devicesHistory']:[]
                if(deviceData.length>0){
                    let currentDeviceObj={}
                    let deviceSelfTests=[]
                    // let treatmentRingPads=[]
                    // let treatmentRings=[]
                    let patientTreatments=[]
                    deviceData.map(item=>{
                        if(item['patient']){
                            currentDeviceObj= {...item}
                        }
                        if(item['deviceSelfTests'] && item['deviceSelfTests'].length>0){
                            item['deviceSelfTests'].map(entry=> {
                                entry['Internal_Serial_Number'] = item['Internal_Serial_Number']
                                entry['External_Serial_Number'] = item['External_Serial_Number']
                                deviceSelfTests.push(entry)
                                }
                            )
                        }
                        if(item['patientTreatments'] && item['patientTreatments'].length>0){
                            item['patientTreatments'].map(entry=> {
                                patientTreatments.push(entry)
                                })
                        }

                    })


                    if(treatmentRings.length>0 ){
                        treatmentRings.map(item=>{
                            item['TreatmentDate']=utils.getFormattedDate(item['createdAt'])
                            item['TreatmentTime']=utils.getTimeFromDate(item['createdAt'])
                            item['TreatmentType']=item['isCumulative']?"Cumulative":"Treatment"
                        })
                        lastRing = treatmentRings[0]
                    }
                    if(treatmentRingPads.length>0 ){
                        treatmentRingPads.map(item=>{
                            item['TreatmentDate']=utils.getFormattedDate(item['createdAt'])
                            item['TreatmentTime']=utils.getTimeFromDate(item['createdAt'])
                            item['TreatmentType']=item['isCumulative']?"Cumulative":"Treatment"
                        })
                        lastRingPad = treatmentRingPads[0]
                    }



                    let lastWeekTreatments= getCountTreatments(7,patientTreatments)
                    let lastMonthTreatments= getCountTreatments(30,patientTreatments)

                    setPatientTreatments([...patientTreatments])
                    setCurrentDevice({...currentDeviceObj})
                    setPatientPhone(currentDeviceObj['patient']['phoneNumber'])

                    setLastRingTreatment(lastRing)
                    if(lastRingPad['createdAt'] >= lastRing['createdAt']){
                        setLastRingPadTreatment(lastRingPad)
                        if(lastRingPad['createdAt'] > lastRing['createdAt']){
                            setLastRingTreatment({patientTreatment:{}})
                        }
                    }

                    setLastWeekTreatments(lastWeekTreatments)
                    setLastMonthTreatments(lastMonthTreatments)
                    setCurrentRingTreatments(treatmentRings)
                    setCurrentRingPadTreatments(treatmentRingPads)
                    setCurrentSelfTests(deviceSelfTests)
                    setDevicesHistory(devicesHistoryArr)

                }


            }

        })

    }, [])

    const getCountTreatments = (number,treatments)=>{
        let dateToCheck  = new Date()
        let countTreatments=0
        dateToCheck.setDate(dateToCheck.getDate()-number)

        treatments.map(item=> {
            if(new Date(item['createdAt']) >=dateToCheck && item['sessionTimerSeconds'] && item['sessionTimerSeconds']>0){
                countTreatments=countTreatments+1
            }
        })
        return countTreatments
    }

    const fetchData = async () => {
        let index = params.Device_index
        return await props.restApi.getDevicesTrackByIndex(index)
    }

    const onDownloadClick = async () => {
       let questionnaires = await props.restApi.getDeviceQuestionnaires(currentDevice['Device_index'])
        questionnaires = questionnaires && questionnaires.data?questionnaires.data:[]
        utils.downloadDeviceDashboardData(currentDevice,patientTreatments,currentRingTreatments,currentRingPadTreatments,currentSelfTests,devicesHistory,questionnaires)

    }
    const saveEditPhone = async () => {
        let response = await props.restApi.setPatientPhone(currentDevice['Device_index'], {phoneNumber:patientPhone})
        if(response && response.data){
            currentDevice['patient']['phoneNumber']=patientPhone
            setCurrentDevice({...currentDevice})
            setEditPhone(false)
        }else{
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Could not change phone number", `Error?`)

        }

    }

    const handleEditPhone = (value) => {
        setEditPhone(value)
    }

    return (


        <div className="w-full px-[3.75rem] py-[3rem] h-[899px] overflow-y-auto">
          <BodyHeader title={"Device Dashboard"}
                      includeButton={false}
                      hasDownload={false}
                      />


            <BodySearchFilter
                onDownloadClick={() => onDownloadClick()}
                hasBackButton={true}
                onBackButtonClick={() => navigate(`/${props.rolePath}/DeviceManagement`)}
                hasSearch={false}
                hasFilter={false}
                hasDownload={true}
            />

            <DeviceProfile currentDevice={currentDevice} devicesHistory={devicesHistory}
                           onPhoneChange={(value)=>setPatientPhone(value)}
                           handleEditPhone={(value)=>handleEditPhone(value)}
                           editPhone={editPhone}
                           patientPhone={patientPhone}
                           saveEditPhone={()=>saveEditPhone()}
            />

            <div className={`mt-[1.5rem] mb-[1.5rem] w-full h-[0.0625rem] rounded-lg bg-[#E0E0D7]`} id={"line"}/>


            <div className={"flex"}>
                <TreatmentHistoryNum
                    number={lastWeekTreatments}
                    text={"Last week's treatments"}
                    svgName={"treatmentCalendarWeek"}
                    marginLeft={""}/>
                <TreatmentHistoryNum number={lastMonthTreatments} text={"Last month's treatments"} svgName={"treatmentCalendarMonth"} marginLeft={"ml-[4.25rem]"}/>

                <DeviceGeneralSettings currentDevice={currentDevice} />

            </div>


            <div className={`mt-[1.5rem] mb-[1.5rem] w-full h-[0.0625rem] rounded-lg bg-[#E0E0D7]`} id={"line"}/>


            <div className={'text-[#52007E] font-Poppins text-2xl font-normal leading-9'}> Last Treatment </div>

            <div className={'flex mt-[1.5rem] gap-x-[3rem]'}>
                <LastTreatmentRing text={"RING"} photo={ringPhoto}
                                   time={utils.secondsToMMSS(lastRingTreatment["sessionRingTimerSeconds"])}
                                   energyLevel={(((1*lastRingTreatment['timeAtLevel1_R'])+
                                       (2*lastRingTreatment['timeAtLevel2_R'])+
                                       (3*lastRingTreatment['timeAtLevel3_R'])+
                                       (4*lastRingTreatment['timeAtLevel4_R'])+
                                       (5*lastRingTreatment['timeAtLevel5_R'])+
                                       (6*lastRingTreatment['timeAtLevel6_R']))/(lastRingTreatment['timeAtLevel1_R']+
                                       lastRingTreatment['timeAtLevel2_R']+
                                       lastRingTreatment['timeAtLevel3_R']+
                                       lastRingTreatment['timeAtLevel4_R']+
                                       lastRingTreatment['timeAtLevel5_R']+
                                       lastRingTreatment['timeAtLevel6_R'])).toFixed(2)}
                                   quality={lastRingTreatment["QualityFactor_R"]}
                                   power={lastRingTreatment["calcBatteryPrecent_R"]}
                                   totalTimeRF1={lastRingTreatment["totalTimeRF1_R"]}
                                   totalTimeRF2={lastRingTreatment["totalTimeRF2_R"]}
                                   totalTimeRF3={lastRingTreatment["totalTimeRF3_R"]}
                                   avgImpedanceRF1={lastRingTreatment["avgImpedanceRF1_R"]}
                                   avgImpedanceRF2={lastRingTreatment["avgImpedanceRF2_R"]}
                                   avgImpedanceRF3={lastRingTreatment["avgImpedanceRF3_R"]}
                                   avgTempRF1={lastRingTreatment["avgTempRF1_R"]}
                                   avgTempRF2={lastRingTreatment["avgTempRF2_R"]}
                                   avgTempRF3={lastRingTreatment["avgTempRF3_R"]}
                                   hasAdditionalPhoto={false}
                                   entry={currentRingTreatments.length>0?currentRingTreatments[0]:{}}/>
                <LastTreatmentRing text={"RING+PAD"} photo={ringPadPhoto}
                                   time={utils.secondsToMMSS(lastRingPadTreatment["sessionRingPadTimerSeconds"])}
                                   quality={lastRingPadTreatment['QualityFactor_RP']}
                                   energyLevel={(((1*lastRingPadTreatment['timeAtLevel1_RP'])+
                                       (2*lastRingPadTreatment['timeAtLevel2_RP'])+
                                       (3*lastRingPadTreatment['timeAtLevel3_RP'])+
                                       (4*lastRingPadTreatment['timeAtLevel4_RP'])+
                                       (5*lastRingPadTreatment['timeAtLevel5_RP'])+
                                       (6*lastRingPadTreatment['timeAtLevel6_RP']))/
                                       (lastRingPadTreatment['timeAtLevel1_RP']+
                                           lastRingPadTreatment['timeAtLevel2_RP']+
                                           lastRingPadTreatment['timeAtLevel3_RP']+
                                           lastRingPadTreatment['timeAtLevel4_RP']+
                                           lastRingPadTreatment['timeAtLevel5_RP']+
                                           lastRingPadTreatment['timeAtLevel6_RP'])).toFixed(2)}
                                   power={lastRingPadTreatment["calcBatteryPrecent_RP"]}
                                   totalTimeRF1={lastRingPadTreatment["totalTimeRF1_RP"]}
                                   totalTimeRF2={lastRingPadTreatment["totalTimeRF2_RP"]}
                                   totalTimeRF3={lastRingPadTreatment["totalTimeRF3_RP"]}
                                   avgImpedanceRF1={lastRingPadTreatment["avgImpedanceRF1_RP"]}
                                   avgImpedanceRF2={lastRingPadTreatment["avgImpedanceRF2_RP"]}
                                   avgImpedanceRF3={lastRingPadTreatment["avgImpedanceRF3_RP"]}
                                   avgTempRF1={lastRingPadTreatment["avgTempRF1_RP"]}
                                   avgTempRF2={lastRingPadTreatment["avgTempRF2_RP"]}
                                   avgTempRF3={lastRingPadTreatment["avgTempRF3_RP"]}
                                   entry={currentRingPadTreatments.length>0?currentRingPadTreatments[0]:{}}
                                    hasAdditionalPhoto={true} additionalPhoto={padPhoto}/>

            </div>



            <div className={`mt-[1.5rem] mb-[1.5rem] w-full h-[0.0625rem] rounded-lg bg-[#E0E0D7]`} id={"line"}/>

            {/*TODO SelfTestHistory*/}
            <DeviceSelfTestHistory entry={currentSelfTests} />

            <div className={`mt-[1.5rem] mb-[1.5rem] w-full h-[0.0625rem] rounded-lg bg-[#E0E0D7]`} id={"line"}/>


            {/*TODO DeviceTreatmentHistory*/}
            <DeviceTreatmentHistory currentRingTreatments={currentRingTreatments} currentRingPadTreatments={currentRingPadTreatments}/>


      </div>
    )

}

export default DeviceTreatmentsMain



